import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { shipmentMethodCreationApi } from "../../services/apiService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const shipmentMethodCreationAsync = createAsyncThunk ('shipmentMethod/CreateShipment', async(payload)=>{
    try{
        const response = await shipmentMethodCreationApi(payload)
        console.log(response)
        toast.success(` ${response.message}`); 
        return response
        
    }
    catch(error){
        console.log("Error in Creating Shipment Method")
    }
})


const initialState = {
    loading:false,
    createShipmentData: [],
    error:null,


}

const createShipmentSlice = createSlice({
    name: 'createshipment',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(shipmentMethodCreationAsync.pending,(state)=>{
            state.loading="true"
        })
        .addCase(shipmentMethodCreationAsync.fulfilled, (state,action)=>{
            state.loading="false"
            state.createShipmentData = action.payload;
            state.error = null

        })
        .addCase(shipmentMethodCreationAsync.rejected,(state,action)=>{
            state.loading = "false";
            state.error = action.error.message;
        })
    }

})

export default createShipmentSlice.reducer

