import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { pincodebulkUploadFunc } from '../../services/apiService';

export const bulkPincodeAsync = createAsyncThunk('pincode/bulkpincode', async ({ files }) => {
  try {
    const response = await pincodebulkUploadFunc(files);
    console.log('API Response:', response);
    return response; // Assuming the API response already has the structure you want
  } catch (error) {
    console.log('Error in uploading pincode', error);
    throw error;
  }
});

const pincodesBulkFormSlice = createSlice({
  name: 'bulkpincodes',
  initialState: {
    pincodelisting: null,
    pincodedata: {
      columns: [],
      rows: [],
    },
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bulkPincodeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkPincodeAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pincodelisting = action.payload;
        state.pincodedata.columns = action.payload.columns || [];
        state.pincodedata.rows = action.payload.rows || [];
        console.log('Entire Payload:', action.payload);
        console.log('Pincodes from Payload:', state.pincodedata);
      })
      .addCase(bulkPincodeAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default pincodesBulkFormSlice.reducer;
