import React, { useEffect,useRef, useState } from "react";
import "./WarehouseManagement.css";
import { Link, useNavigate } from "react-router-dom";
import { warehouseDetailsAsync } from "../../app/store/warehouseDetailsSlice";
import { FaEdit, FaPlus } from "react-icons/fa";
import { serviceabilityWarehouseListAsync } from "../../app/store/serviceabilityWarehouseListing";
import { addbulkPincodeAsync } from "../../app/store/addbulkPincodesSlice";

import { addpincodeFormApiAsync } from "../../app/store/addPincodeSlice";
import BackIcon from "../../../src/images/backicon.png";
import { bulkPincodeAsync } from "../../app/store/pincodesBulkUploadSlice";
import { addpriorityApiAsync } from "../../app/store/addPrioritySlice";
import { pincodesListingAsync } from "../../app/store/pincodesListingSlice";
import { warehouseinputsearchSuggestionListAsync } from "../../app/store/serviceabilitywarehouseSearchInputSuggestionSlice";
import Select from "react-select";
import { exportPincodesApiAsync } from "../../app/store/exportbulkpincodeSlice";

import { FaTrash,FaCheck} from "react-icons/fa";
import { deletepincodeFormApiAsync } from "../../app/store/deletePincodefromBulkSlice";
import { deleteBulkPincodesApiAsync } from "../../app/store/deletebulkUploadSlice";
// import { Button, Modal } from "react-bootstrap";
import { downloadTemplateApiAsync } from "../../app/store/downloadTemplateSlice";
import Button from "../../components/UIComponents/Button/Button";
import Popup from "../../components/UIComponents/Popup/Popup";
import { serviceabilityPincodesListingAsync } from "../../app/store/serviceabilityPincodesListingSlice";
import {serviceabilityListPincodeWarehouseAsync} from "../../app/store/serviceabilityPincodeWarehouseListSlice"
import { useDispatch, useSelector } from "react-redux";
import { warehouselistAsync } from "../../app/store/warehoustlistSlice";

import {warehouseDetailsUpdateAsync} from '../../app/store/warehouseDetailsUpdateSlice'
import ConfigurationTab from "../../components/UIComponents/ConfigurationTab/ConfigurationTab";
import './WarehouseManagement.css'
const WarehouseManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputenable, setInputEnable] = useState(false);
  const [bulkdeletOption, setBulkDeleteOption] = useState(false);
  const [show, setShow] = useState(false);
  const [exportButton, setExportButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const[makeInputFieldEnable,setMakeInputFieldEnable] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const fileInputRef = useRef(null);
  const [showPincodesListing, setPincodesListing] = useState(true);
  const[showpincodesExcel,setShowPincodesExcel]= useState(true)
  const [addbulkTable, setAddBulkTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [exportingPincodes, setExportPincodes] = useState([]);
  const [deleteOption, setDeleteOption] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedWarehouseName, setSelectedWarehouseName] = useState("");
  const [warehouseData, setWarehouseData] = useState({


  });

  const inputonChange = (fieldName, newValue) => {
    console.log("Editing field:", fieldName);
    console.log("New value:", newValue);
    // Update warehouseData state with the new value for the specified key
    setWarehouseData((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
  };
  const getWarehouseOptions = () => {
    return suggestionList.map((value) => ({ value, label: value }));
  };
  const suggestionList = useSelector(
    (state) => state.warehouseinputsearch.suggestionList.warehouses
  );
  const backFunc = () => {
    navigate("/configurations");
  };
  useEffect(() => {
    const getIdFromServiceabilityPage = localStorage.getItem("id");
    console.log(getIdFromServiceabilityPage);
    dispatch(warehouselistAsync());
    dispatch(
      warehouseDetailsAsync({
        query: {
          page_no: 1,
          per_page: 50,
          id: getIdFromServiceabilityPage,
        },
      })
    );
  }, []);

  const rows = useSelector((state) => state.pincodesListing.rows);
  const apiWarehouseData = useSelector(
    (state) => state.warehousedetails.warehousedetailsdata.warehouse
  );
  console.log(apiWarehouseData.name)
  const pincodesData = useSelector(
    (state) => state.warehousedetails.warehousedetailsdata.pincodes
  );
  const { status, pincodedata, error } = useSelector(
    (state) => state.addbulkpincodes
  );
  useEffect(() => {
    console.log("Warehouse Data:", apiWarehouseData);
  }, [apiWarehouseData]);
  const [editableRows, setEditableRows] = useState(() =>
    pincodedata.rows.map((row) => [...row])
  );
  const editWarehouseDetails = () => {
    setInputEnable(true);
    setMakeInputFieldEnable(false)
  };
  const updateWarehouseDetails = () => {
    // Create a new object to store the updated warehouse data
    const updatedWarehouseData = {};
  
    // Iterate over warehouseData and compare with apiWarehouseData
    for (const [key, value] of Object.entries(warehouseData)) {
        // Check if the value has been updated and exclude 'status' and 'id' keys
        if (key !== 'status' && key !== 'id' && key !== 'tags' && key !== 'shipment_id'  && key !== 'shipment') {
            if (apiWarehouseData[key] === value) {
                // If not updated, use the value from apiWarehouseData
                updatedWarehouseData[key] = apiWarehouseData[key];
            } else {
                // If updated, use the updated value from warehouseData
                updatedWarehouseData[key] = value;
            }
        }
    }
  
    // Construct the payload by combining the updated values and unchanged values from apiWarehouseData
    const warehousePayload = {
        ...apiWarehouseData, // Include all original values
        ...updatedWarehouseData, // Override with updated values
        channel_location_id: {} // Override channel_location_id with an empty object
    };
  
    // Remove 'id' and 'status' keys from warehousePayload
    delete warehousePayload.id;
    delete warehousePayload.status;
    delete warehousePayload.tags;
    delete warehousePayload.shipment_id;
    delete warehousePayload.shipment;
  
    // Dispatch the update action
    dispatch(warehouseDetailsUpdateAsync({
        service: {
            pincodes: [
                ["pincode", "city", "state", "country"],
                []
            ]
        },
        warehouse: warehousePayload,
        shipment_id: apiWarehouseData.shipment_id,
        id: apiWarehouseData.id
    }));
};

  

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };
  const addNewRow = () => {
    setShowPincodesExcel(false)
    setShowTable(true);
    setAddBulkTable(false);
    const newRow = {
      Pincode: "",
      City: "",
      State: "",
      Country: "",
      Warehouse_id: "",
      warehouse_priority: "",
      storehouse_priority: "",
      darkhouse_priority: "",
    };
    setTableData([...tableData, newRow]);
  };
  const handleBulkDelete = () => {
    // Trigger the file input's click event to open the file explorer dialog
    fileInputRef.current.click();
  };
  const exportPincodes = () => {
    console.log(rows);

    dispatch(
      exportPincodesApiAsync({
        pincodes: checkedRows,
      })
    );
  };
  // Dispatch your action with the payload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the selected file (e.g., validate file type, size, etc.)
      // Dispatch the action with the file data
      dispatch(deleteBulkPincodesApiAsync(file));
    }
    dispatch(addbulkPincodeAsync({ files: selectedFiles }));
  };
  const handleAddBulkClick = () => {
    setPincodesListing(false);
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });
      dispatch(addbulkPincodeAsync({ files: selectedFiles }));
    } else {
      console.log("Please select a file.");
      // Handle the case when no file is selected
    }
    setShowModal(true);
    setShow(false)
    // setShowTable(false);
  };
  const downloadTemplate = () => {
    dispatch(downloadTemplateApiAsync());
  };
  const checkBoxChecked = (row, isChecked, isForDeletion) => {
    const pincode = row[0]; // Keep the pincode for existing functionality

    if (!isForDeletion) {
      // Existing functionality for exporting pin codes
      setExportPincodes((prevPincodes) => {
        if (isChecked) {
          return [...prevPincodes, String(pincode)];
        } else {
          return prevPincodes.filter((code) => code !== String(pincode));
        }
      });
    } else {
      // New functionality for handling deletion
      const updatedCheckedRows = isChecked
        ? [...checkedRows, pincode]
        : checkedRows.filter((code) => code !== pincode);

      setCheckedRows(updatedCheckedRows);

      // Set delete option
      const anyCheckboxChecked = updatedCheckedRows.length > 0;
      setDeleteOption(anyCheckboxChecked);
      setExportButton(anyCheckboxChecked);
      setBulkDeleteOption(anyCheckboxChecked);

      console.log(row); // Log the selected row data
    }
  };
  const handleWarehouseChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const selectedValues = selectedOptions.map((option) => option.value);
      setSelectedWarehouse(selectedValues);
    } else {
      setSelectedWarehouse([]);
      // Handle case when no option is selected
    }
  };
  const handleInputChange = (selectedOptions, value, rowIndex, cellIndex) => {
    const updatedRows = editableRows.map((row) => [...row]);
    updatedRows[rowIndex][cellIndex] = value;
    setEditableRows(updatedRows);
    const selectedWarehouse = selectedOptions.map((option) => option.value);
    setSelectedWarehouseName(selectedWarehouse);
    console.log(
      `Input value changed at row ${rowIndex}, column ${cellIndex}:`,
      value
    );
  };
  const handleSwapPriority = (rowIndex, index1, index2) => {
    // Ensure rowIndex is within bounds
    if (rowIndex < 0 || rowIndex >= editableRows.length) return;

    // Ensure index1 and index2 are within bounds of the Fulfillment Center Priority array
    const fcPriorityIndex = pincodedata.columns.indexOf("Fulfillment Center Priority");
    const fcPriority = editableRows[rowIndex][fcPriorityIndex];
    if (
      index1 < 0 ||
      index1 >= fcPriority.length ||
      index2 < 0 ||
      index2 >= fcPriority.length
    )
      return;

    // Create a new array with swapped elements
    const newFcPriority = [...fcPriority];
    const temp = newFcPriority[index1];
    newFcPriority[index1] = newFcPriority[index2];
    newFcPriority[index2] = temp;

    // Update the state with the new array
    const updatedRows = [...editableRows];
    updatedRows[rowIndex][fcPriorityIndex] = newFcPriority;
    setEditableRows(updatedRows);
    console.log(updatedRows[rowIndex]);

    console.log("pincode", updatedRows[rowIndex].pincode);
    console.log("ware", selectedWarehouse);

    dispatch(
      addpriorityApiAsync({
        pincode: updatedRows[rowIndex][0],
        city: updatedRows[rowIndex][1],
        state: updatedRows[rowIndex][2],
        country: updatedRows[rowIndex][3],
        warehouse_ids: "", // Update with the actual value
        // warehouse_names: selectedWarehouse, 
        warehouse_names:apiWarehouseData.name,
        priority_1: newFcPriority[0],
        priority_2: newFcPriority[1],
        priority_3: newFcPriority[2],
      })
    );
  };
  const handleDeleteRow = (rowIndex, pincode) => {
    console.log("Pincode:", pincode);
    console.log("Warehouse Name:", selectedWarehouseName);
    const pincodee = editableRows[rowIndex][0];
    dispatch(
      deletepincodeFormApiAsync({
        pincode: pincodee,
        warehouse_ids: "", // Assuming you don't need the warehouse ID for deletion
        warehouse_names:apiWarehouseData.name,
        
      })
    );

    const updatedRows = editableRows.filter((_, index) => index !== rowIndex);
    setEditableRows(updatedRows);
  };
  useEffect(() => {
    dispatch(
      pincodesListingAsync({
        query: {
          page_no: 1,
          per_page: 10,
        },
      })
    );
    dispatch(serviceabilityWarehouseListAsync());
    dispatch(
      warehouseinputsearchSuggestionListAsync({ query: { search: searchTerm } })
    );
    // dispatch(
    //   warehouseDetailsAsync({
    //     query: {
    //       page_no: 1,
    //       per_page: 50,
    //       id: "6f53d682-6517-4316-a068-d9d5fe8f70b0",
    //     },
    //   })
    // );
    setEditableRows(pincodedata.rows.map((row) => [...row]));
  }, [pincodedata, searchTerm]);
  useEffect(() => {
    dispatch(
      serviceabilityPincodesListingAsync({
        query: {
          page_no: 1,
          per_page: 100,
        },
      })
    );
  }, []);
  return (
    <div>
      <div className="warehouse--managemnt first--row" >
        {/* <ConfigurationTab/> */}
        <span className="back--para">
          <img className="back-icon" src={BackIcon} alt="" onClick={backFunc} />{" "}
          <p className="back-p">Back</p>
        </span>
        <p className="add-fulfillment-para">Warehouse Details</p>
        <div>
          <div className="details-container  row">
            <div className="col-md-9"></div>
            <div className="col-md-1">

            </div>
            {!inputenable&&<div className="col-md-1">

</div>}
            {inputenable&&<div className="col-md-1">
            <button className="update--warehouse--details" onClick={updateWarehouseDetails}> Update</button>
            </div>}
            <div className="col-md-1">
              {" "}
              <button
                className="edit--warehouse--details"
                onClick={editWarehouseDetails}
              >
                Edit <FaEdit style={{position:'absolute'}}/>
                
              </button>
            </div>
           
            {
              console.log("Ravi",warehouseData)
              
            }
          {Object.entries(apiWarehouseData).map(([key, value]) => {
  if (key === "id" || key === "shipment_id") {
    return null; // Skip rendering for these keys
  }
  return (
    <div key={key} className="detail-item col-md-3">
      <p className="warehouse--headers">{key}</p>
      <input
        type="text"
        value={warehouseData[key] || value} // Corrected to reference key instead of value
        className="warehouse--input--value"
        onChange={(e) => inputonChange(key, e.target.value)} // Corrected to pass key
        disabled={makeInputFieldEnable}
      />
    </div>
  );
})}



            {/* <div className="col-md-10"></div>

            <div className="col-md-2">
              {" "}
             
            </div> */}
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-md-7"></div>
            <div className="col-md-5">
      <div className="row">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <div className="col-md-3">
                  {showPincodesListing ? (
                    <div></div>
                  ) : (
                    <></>
                    // <button
                    //   className="btn btn-success mt-2"
                    //   onClick={addNewRow}
                    // >
                    //   Add Row
                    // </button>
                  )}
                </div>
                <div className="col-md-3">
                  {bulkdeletOption && (
                    <button
                      className="btn btn-success mt-2 lb"
                      onClick={handleBulkDelete}
                    >
                      Bulk Delete
                    </button>
                  )}
                </div>
                <div className="col-md-3">
                  {exportButton && (
                    <button
                      className="btn btn-success mt-2 lb"
                      onClick={exportPincodes}
                    >
                      Export
                    </button>
                  )}
                </div>
                
                <div className="col-md-3">
                  <Button
                    onClick={() => setShow(true)}
                    children="Bulk Upload"
                    className="serviceabilty-bulk--upload "
                  />
                  {/* <Popup
                  show={show}
                  handleClose={() => setShow(false)}
                  handleShow={() => setShow(true)}
                  modalTitle="Modal Demo"
                  onChange={handleFileChange}
                  type="file"
                  id="fileInput"
                  htmlFor="fileInput"
                  onClick={()=>setIsOpen(true)}
                  handleFileChange={handleFileChange}
                  selectedFiles={selectedFiles}
                  handleAddBulkClick={handleAddBulkClick}
                /> */}
                  <Popup
                    show={show}
                    handleClose={() => setShow(false)}
                    handleShow={() => setShow(true)}
                    modalTitle="Modal Demo"
                    onChange={handleFileChange}
                    type="file"
                    id="fileInput"
                    htmlFor="fileInput"
                    onClick={() => setIsOpen(true)}
                    handleFileChange={handleFileChange}
                    selectedFiles={selectedFiles}
                    handleAddBulkClick={handleAddBulkClick}
                    downloadTemplate={downloadTemplate}
                  />
                </div>
              </div>
              </div>
              </div>

              {showPincodesListing ? (<div className="" style={{ marginTop: "20px" }}>
        <table>
          <thead>
            <tr>
              <th>Pincode</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
            </tr>
          </thead>
          <tbody>
            {pincodesData.map((row) => (
              <tr>
                {row.map((rowItem) => (
                  <td>{rowItem}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>): (
            <div style={{ maxHeight: "350px", overflow: "auto" }}>
              <table
                className=""
                cellspacing="0"
                cellpadding="0"
                border="0"
                width="25"
                style={{ position: "sticky", top: "0", width: "100%" }}
              >
                <thead>
                {!showPincodesListing && (<tr>
                    <th
                      className="warehouselisting--td serviceability--master-tableheader"
                      style={{
                        width: "3.5%",
                        // visibility: "hidden"
                      }}
                    ></th>

{pincodedata.columns.map((header, index) => (
  // Check if the header is not "Warehouse", then render the <th>
 
    <th
    className={`warehouselisting--td serviceability--master-tableheader ${header === "Warehouse" ? "warehouse-header" : ""}`}
    key={index}
    style={{ width: header === "Warehouse" ? "0%" : "auto" }}
  >
      {header}
    </th>
  
))}

                    {
  <th
    className="warehouselisting--td serviceability--master-tableheader"
    style={{ width: "6.5%"}}
  >
    Action
  </th>
}


                  </tr>)}
                </thead>
                <tbody>
                  {editableRows.map((row, rowIndex) => {
                    const fcPriorityIndex =
                      pincodedata.columns.indexOf("Fulfillment Center Priority");

                    const fcPriorityRow = row[fcPriorityIndex];

                    const hasFcPriorityValues =
                      Array.isArray(fcPriorityRow) && fcPriorityRow.length > 0;

                    return (
                      <tr className="warehouselisting--td" key={rowIndex}>
                        <td>
                          <input
                            type="checkbox"
                            onClick={() =>
                              checkBoxChecked(
                                row, // Pass the entire row object
                                !checkedRows.includes(row[0]),
                                true
                              )
                            }
                          />
                        </td>

                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>
                            {cellIndex ===
                            pincodedata.columns.indexOf("Warehouse") ? (
                              <></>
                              // <Select
                              //   isMulti
                              //   options={getWarehouseOptions()}
                              //   onChange={(selectedOptions) => {
                              //     handleWarehouseChange(selectedOptions);
                              //     handleInputChange(
                              //       selectedOptions,
                              //       rowIndex,
                              //       cellIndex
                              //     );
                              //   }}
                              //   className="serviceability--masterinput"
                              //   style={{ width: "160px !important" }}
                              // />
                            ):cellIndex ===
                              pincodedata.columns.indexOf("Storehouse") ? (
                              <Select
                                className="serviceability--masterinput"
                                isMulti
                                // options={getStorehouseOptions()} // This line is commented out due to undefined function
                                onChange={(selectedOptions) =>
                                  handleInputChange(
                                    selectedOptions.map(
                                      (option) => option.value
                                    ),
                                    rowIndex,
                                    cellIndex
                                  )
                                }
                                style={{ width: "160px !important" }}
                              />
                            ) : cellIndex ===
                                pincodedata.columns.indexOf("Fulfillment Center Priority") &&
                              hasFcPriorityValues ? (
                              <div style={{ display: "flex" }}>
                                {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "13px",
                                  }}
                                >
                                  <div>Storehouse</div>
                                  <div>Darkhouse</div>
                                </div> */}
                                {fcPriorityRow.map((value, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "12px",
                                      marginRight: "3px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {index ===
                                      Math.floor(fcPriorityRow.length / 2) && ( // Display arrows for the middle element only
                                      <>
                                        <button
                                          className="priority-btn"
                                          onClick={() =>
                                            handleSwapPriority(
                                              rowIndex,
                                              index,
                                              index - 1
                                            )
                                          }
                                        >
                                          &laquo;
                                        </button>
                                        <div>{value}</div>
                                        <button
                                          className="priority-btn"
                                          onClick={() =>
                                            handleSwapPriority(
                                              rowIndex,
                                              index,
                                              index + 1
                                            )
                                          }
                                        >
                                          &raquo;
                                        </button>
                                      </>
                                    )}
                                    {index !==
                                      Math.floor(fcPriorityRow.length / 2) && (
                                      <div>{value}</div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <input
                                type="text"
                                value={cell}
                                className="input-class serviceability--masterinput"
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value,
                                    rowIndex,
                                    cellIndex
                                  )
                                }
                              />
                            )}
                          </td>
                        ))}
                        <td>
                          {checkedRows.includes(row[0]) && (
                            <button
                              onClick={() =>
                                handleDeleteRow(
                                  rowIndex,
                                  row.pincode,
                                  row.warehouse_name
                                )
                              }
                              style={{ background: "none", border: "none" }}
                            >
                              <FaTrash />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}




      {/* {pincodedata &&} */}
      {/* <div className="table-hover" style={{ marginTop: "20px" }}>
        <table>
          <thead>
            <tr>
              {column_names.map((column, columnIndex) => (
                <th key={columnIndex} style={{ width: "10px !important" }} className={column_names[columnIndex] ==="Status In Text" || column_names[columnIndex]==="Channel Location IDs" ? "email-cell":""}>
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className={column_names[cellIndex] === "Email" ? "email-cell" : ""}>
                    {typeof cell === "object" ? JSON.stringify(cell) : cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>  */}
    </div>
  );
};

export default WarehouseManagement;
