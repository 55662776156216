

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {createOrders} from '../../services/apiService'
export const createOrderAsync = createAsyncThunk('createOrder/createOrder', async (orderData) => {
    
  try {
    const response = await createOrders(orderData); // Modify this based on your API
    console.log(response)
    return response
   
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
  
});

const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState: {
    status: 'idle',
    error: null,
    dbSchema: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrderAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;  // Update the data property with the API response
        
        state.dbSchema=action.payload.db_schema
        console.log( state.dbSchema)
      })
      .addCase(createOrderAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const selectDbSchema = (state) => state.createOrder.dbSchema;

export default createOrderSlice.reducer;
