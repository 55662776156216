import {React} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";


import {
  FaShoppingCart,
  FaUsers,
  FaMoneyBillWaveAlt,
  FaShoppingBasket,
  FaListAlt,
  FaBan,
} from "react-icons/fa";

const Dashboard = () => {
  const cardStyleone = {
    backgroundColor: "#d1fae5",
    borderBottom: "4px solid #059669",
    borderRadius: "10px 10px 10px 10px", 
    marginBottom:'25px',
    minHeight: '110px',
    width: '100%',
  };
  const cardStyletwo = {
    backgroundColor: "#FCD6EB",
    borderBottom: "4px solid #DB2777",
    borderRadius: "10px 10px 10px 10px",
    marginBottom:'25px',
    minHeight: '110px',
    width: '100%',
  };
  const cardStylethree = {
    backgroundColor: "#FEEFB2",
    borderBottom: "4px solid #D97706",
    borderRadius: "10px 10px 10px 10px", 
    marginBottom:'25px',
    minHeight: '110px',
    width: '100%',
  };

  const cardStylefour = {
    borderBottom: '4px solid rgb(59, 130, 246)',
    borderRadius: '10px 10px 10px 10px',
    marginBottom: '25px',
    background: 'linear-gradient(65deg, rgb(198, 223, 254), #fff)',
    minHeight: '110px',
    width: '100%',
  };

  const cardStylefive = {
    backgroundColor: "#FECECE",
    borderBottom: "4px solid #EF4444",
    borderRadius: "10px 10px 10px 10px", 
    marginBottom:'25px',
    minHeight: '110px',
    width: '100%',
  };
  const cardStylesix = {
    background: 'linear-gradient(20deg, #C9D4FE, #fff)',
    borderBottom: "4px solid #6366F1",
    borderRadius: "10px 10px 10px 10px", 
    marginBottom:'25px',
    minHeight: '110px',
    width: '100%',
  };
  return (
    <div className="" style={{marginTop:'50px'}} >
      
      <div className="row">
        <div className="col-md-3">
          <Card style={cardStyleone}>
            <Card.Body>
              <Card.Title>
                <FaShoppingCart className="icon--pos icon--bg--one"/> Total Orders
              </Card.Title>
              <Card.Text>1500</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card style={cardStyletwo} >
            <Card.Body>
              <Card.Title>
                <FaUsers className="icon--pos icon--bg--two"/> Total Customers
              </Card.Title>
              <Card.Text>4000</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card style={cardStylethree}>
            <Card.Body>
              <Card.Title>
                <FaMoneyBillWaveAlt className="icon--pos icon--bg--three"/> Total Sales
              </Card.Title>
              <Card.Text>1200</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card style={cardStylefour}>
            <Card.Body>
              <Card.Title>
                <FaShoppingBasket className="icon--pos icon--bg--four"/> Carts
              </Card.Title>
              <Card.Text>10</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card style={cardStylefive}>
            <Card.Body>
              <Card.Title>
                <FaListAlt className="icon--pos icon--bg--five"/> Orders In Process
              </Card.Title>
              <Card.Text>100</Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-3">
          <Card style={cardStylesix}>
            <Card.Body>
              <Card.Title>
                <FaBan className="icon--pos icon--bg--six"/> Cancelled Orders
              </Card.Title>
              <Card.Text>134</Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="chart-div">
        <div>
          <h4>Sales Analytics</h4>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
