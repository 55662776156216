import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { shipmentConfigDetailsUpdateApi } from '../../services/apiService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const shipmentConfigDetailsUpdateAsync = createAsyncThunk(
  "shipmentConfigDetails/shipmentConfigDetailsUpdate",
  async (payload) => {
    try {
      const response = await shipmentConfigDetailsUpdateApi(payload);
      toast.success(response.success); // Display success message using toast
      return response;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Bad request. Please check your input.';
          toast.error(errorMessage);

      } else {
       // Handle other errors
       toast.error('An error occurred while creating the Shipment Configuration.');
      }
      throw error;
    }
  }
);

const shipmentConfigUpdateDetailsSlice = createSlice({
  name: "shipmentconfigdetailsupdate",
  initialState: {
    shipmentconfigdetailsupdatedata: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(shipmentConfigDetailsUpdateAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(shipmentConfigDetailsUpdateAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.shipmentconfigdetailsupdatedata = action.payload;
      })
      .addCase(shipmentConfigDetailsUpdateAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default shipmentConfigUpdateDetailsSlice.reducer;
