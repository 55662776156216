import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { serviceabilityWarehouseListFunc } from "../../services/apiService";

export const serviceabilityWarehouseListAsync = createAsyncThunk('serviceabilitywarehouse/serviceabilitywarehouselisting',async ()=>{
    try{
        const response = await serviceabilityWarehouseListFunc(); // Modify this based on your API
    console.log(response)
    // alert(response)
    return response
    }
    catch (error) {
        console.error('Error fetching order details:', error);
        throw error;
      }
})

const serviceablitywarehouselistSlice = createSlice({
    name: 'serviceabilitywarehouselist',
    initialState: {
     serviceabilitywarehouselisting: null,
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(serviceabilityWarehouseListAsync.pending, (state) => {
          state.status = 'loading';
        })
        
        .addCase(serviceabilityWarehouseListAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.serviceabilitywarehouselisting = action.payload;
            console.log('Fulfilled with payload:', action.payload);
          })
        
          
        .addCase(serviceabilityWarehouseListAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });

  export default serviceablitywarehouselistSlice.reducer

