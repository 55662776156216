import React from "react";
import { Tabs } from "antd";
import Fulfillment from "../../../pages/Fulfillment/Fulfillment";// Import the Orders component
import "./ConfigurationTab.css"; // Import the CSS file for styling
import Serviceability from "../../../pages/Serviceability/Serviceability";
import ShipmentCharges from "../../../pages/ShipmentCharges/ShipmentCharges";
import ShipmentMethods from "../../../pages/ShipmentMethods/ShipmentMethods";
import ShipmentConfigurationList from "../../../pages/ShipmentConfigurationList/ShipmentConfigurationList";

const { TabPane } = Tabs;

const ConfigurationTab = () => {
  return (
    <>
    
         
          <Tabs defaultActiveKey="1" className="custom-tabs">
            <TabPane tab="Fulfillment Centres" key="1">
              <Fulfillment />
            </TabPane>
            <TabPane tab="Serviceability" key="2">
                <Serviceability/>
            </TabPane>
            <TabPane tab="Shipment Methods" key="3">
              <ShipmentMethods />
            </TabPane>
            <TabPane tab="Shipping Configurations" key="4">
              {/* <ShipmentCharges /> */}
              <ShipmentConfigurationList/> 
            </TabPane>
          </Tabs>
       
    </>
  );
};

export default ConfigurationTab;
