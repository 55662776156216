import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { downloadTemplateApi } from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Action creator for downloading template asynchronously
export const downloadTemplateApiAsync = createAsyncThunk(
  'template/download',
  async () => {
    try {
      const response = await downloadTemplateApi();
      // Handle success: show toast and return response data
      toast.success(response.data);
      return response.data;
    } catch (error) {
      // Handle error: show toast and throw error
      toast.error("Error downloading template");
      throw error;
    }
  }
);

// Initial state for the template slice
const initialState = {
  loading: false,
  error: null,
  data: null,
};

// Template slice with reducers and extra reducers
const templateSlice = createSlice({
  name: 'downloadTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadTemplateApiAsync.pending, (state) => {
        // Set loading state while download is in progress
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadTemplateApiAsync.fulfilled, (state, action) => {
        // Update state with downloaded data and reset loading/error states
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(downloadTemplateApiAsync.rejected, (state, action) => {
        // Set error state if download fails and reset loading state
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default templateSlice.reducer;
