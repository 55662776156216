import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackIcon from "../../../src/images/backicon.png";
import "./ShipmentCharges.css";
import { shipmentMethodListAsync } from "../../app/store/shipmentMethodSlice";
import { FaEye, FaPlus, FaSearch, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { createWarehouseAsync } from "../../app/store/createWarehouseSlice";
import Trash from "../../images/trash.png";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ShipmentCharges = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () =>{
    setShowModal(true);
navigate('/configurations')
  } 

  const backFunc = () => {
    navigate("/configurations");
  };
  const [formData, setFormData] = useState({
    flatrateoperator: "",
    flatrateamount: "",
    currencyunit: "",
    email: "",
    pincode: "",
    status_in_text: "",
    lat_long: "",
    city: "",
    state: "",
    country: "",
    line1: "",
    line2: "",
    mobileNo: "",
    shipmenttags: [],
    shipment_method_charge: "",
    shipmentName: "",
    shipment_method_id: "",
  });
  const warehouseCreationInput = (e) => {
    const { name, value } = e.target;
    if (name === "shipmenttags") {
      // Split input value by commas and trim each tag
      const tagsArray = value.split(",").map((tag) => tag.trim());
      setFormData({ ...formData, [name]: tagsArray });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  /***For Configurable Tables Show  ***/
  const [showTables, setShowTables] = useState({
    orderPriceDiscounts: false,
    shipmentMethod: false,
    weightRange: false,
    dimensionRange: false,
  });

  const handleCheckboxChange = (table) => {
    setShowTables((prev) => ({ ...prev, [table]: !prev[table] }));
  };
  const [activeTab, setActiveTab] = useState(1);

  const [shipmentTable, setShipmentTable] = useState(false);
  const [orderTable, setOrderTable] = useState(false);
  const [orderWeight, setOrderWeight] = useState(false);
  const [orderDistance, setOrderDistance] = useState(false);
  const [orderPackage, setOrderPackage] = useState(false);
  const [weightInput, setweightInput] = useState();
  const [distanceInput, setdistanceInput] = useState();
  const [currencyInput, setCurrencyInput] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const[getdropdownValue,setgetdropdownValue]=useState()
  const[getDimensionInputValue,setGetDimensionInputValue]=useState()
  const[getDistanceInputValue,setGetDistanceInputValue]=useState()

  const handleRadioButtonClick = (index, id) => {
    setSelectedRow(index === selectedRow ? null : index);
    setFormData({ ...formData, shipment_method_id: id });
  };

  const weightInputOnChange = (event) => {
    const selectedWeight = event.target.value;
    setweightInput(selectedWeight);
  };

  const distanceinputOnChange = (event) => {
    const newValue = event.target.value;
    setRows4({ ...rows4, unit: newValue });
  };

  const currencyOnChange = (event) => {
    const selectedCurrency = event.target.value;
    setCurrencyInput(selectedCurrency);
  };

  const [rows1, setRows1] = useState([
    {
      shipmentMethod: "",
      shipmentCharges: "",
      primeCharges: "",
      minEta: "",
      maxEta: "",
    },
  ]);
  const [rows2, setRows2] = useState([
    {
      minRange: "",
      maxRange: "",
      regulardiscount: "",
      primediscount: "",
    },
  ]);
  const [rows3, setRows3] = useState([
    {
      minRange: "",
      maxRange: "",
      primecharges: "",
      unit: "",
    },
  ]);
  const [rows4, setRows4] = useState([
    {
      minRange: "",
      maxRange: "",
      unit: "",
      field2: "",
    },
  ]);
  const [rows5, setRows5] = useState([
    {
      packagingType: "",
      charges: "",
      primeCharges: "",
    },
  ]);

  const toggleClick = () => {
    setShipmentTable((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      shipment: !prevState.shipment,
    }));
  };

  const toggleOrderTable = () => {
    setOrderTable((prevState) => !prevState);
  };

  const toggleOrderWeight = () => {
    setOrderWeight((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      weight: !prevState.weight,
    }));
  };
  const toggleOrderDistance = () => {
    setOrderDistance((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      distance: !prevState.distance,
    }));
  };
  const toggleOrderPackage = () => {
    setOrderPackage((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      packaging: !prevState.packaging,
    }));
  };

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  // Adding row functionality
  const addRow = () => {
    console.log("add row");
    setRows1([
      ...rows1,
      {
        shipmentMethod: "",
        shipmentCharges: "",
        primeCharges: "",
        minEta: "",
        maxEta: "",
      },
    ]);
  };

  const addRow2 = () => {
    setRows2([...rows2,
      //  { ...rows2[0] }
      { minRange: "", maxRange: "", regulardiscount: "", primediscount: "" }
      ]);
  };
  const addRow3 = () => {
    setRows3([...rows3, 
      
      // { ...rows3[0] },
      {
        minRange: "",
        maxRange: "",
        primecharges: "",
        unit: "",
      },
    
    ]);
  };
  const addRow4 = () => {
    setRows4([...rows4,
      //  { ...rows4[0] },
      {
        minRange: "",
        maxRange: "",
        unit: "",
        field2: "",
      },
      ]);
  };

  const addRow5 = () => {
    setRows5([...rows5, 
      // { ...rows5[0] },
      {
        packagingType: "",
        charges: "",
        primeCharges: "",
      }
    ]);
  };

  const handleInputChange = (index, fieldName, value) => {
    const newrows1 = [...rows1];
    newrows1[index][fieldName] = value;
    setRows1(newrows1);
  };
  //Deleting row functionality
  const deleteRow = (index) => {
    const newrows1 = [...rows1];
    newrows1.splice(index, 1);
    setRows1(newrows1);
  };

  const handleInputChange2 = (index, fieldName, value,setRows2) => {
    const newRows = [...rows2];
    newRows[index][fieldName] = value;
    setRows2(newRows);
  };

  const deleteRow2 = (index) => {
    const newRows = [...rows2];
    newRows.splice(index, 1);
    setRows2(newRows);
  };

  const handleInputChange3 = (index, fieldName, value,setRows2) => {
    const newRows = [...rows3];
    newRows[index][fieldName] = value;
    setRows3(newRows);
   
  };
  const InputChange3 = (index, fieldName, value) => {
    const newRows = [...rows3];
    newRows[index][fieldName] = value;
    setRows3(newRows);
    setgetdropdownValue(value)
  };

  
  const handleInputDimension = (index, fieldName, value) => {
    const newRows = [...configRow4];
    newRows[index][fieldName] = value;
    setConfigRow4(newRows);
    setGetDimensionInputValue(value)
  };

  const deleteRow3 = (index) => {
    const newRows = [...rows3];
    newRows.splice(index, 1);
    setRows3(newRows);
  };
  const handleInputChange4 = (index, fieldName, value,setRows4) => {
    const newRows = [...rows4];
    newRows[index][fieldName] = value;
    setRows4(newRows);
  };
  const InputChange4 = (index, fieldName, value) => {
    const newRows = [...rows4];
    newRows[index][fieldName] = value;
    setRows4(newRows);
    setGetDistanceInputValue(value)
  };

  const deleteRow4 = (index) => {
    const newRows = [...rows4];
    newRows.splice(index, 1);
    setRows4(newRows);
  };

  const handleInputChange5 = (index, fieldName, value,setRows5) => {
    const newRows = [...rows5];
    newRows[index][fieldName] = value;
    setRows5(newRows);
  };

  const deleteRow5 = (index) => {
    const newRows = [...rows5];
    newRows.splice(index, 1);
    setRows5(newRows);
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    shipment: false,
    weight: false,
    distance: false,
    packaging: false,
  });

  /***FOr configurable Section State Management */
  const [configRow1, setconfigRow1] = useState([
    {
      minWeight: "",
      maxWeight: "",
      discount: "",
    },
  ]);

  const addRowConfig = (table) => {
    if (table === "table1") {
      setconfigRow1([...configRow1, { priceRange: "", discount: "" }]);
    }
  };
  const removeRowConfig = (index, table) => {
    if (table === "table1") {
      const updatedRows = [...configRow1];
      updatedRows.splice(index, 1);
      setconfigRow1(updatedRows);
    } else if (table === "table2") {
      // Implement similar logic for the second table (if needed)
      // const updatedRows = [...configRow2];
      // updatedRows.splice(index, 1);
      // setconfigRow2(updatedRows);
    }
    // Add more conditions for other tables if needed
  };
  const [configRow2, setConfigRow2] = useState([
    {
      shipmentMethod: "",
      rateMultiplier: "",
      minEta: "",
      maxEta: "",
    },
  ]);

  const addRowConfig2 = () => {
    setConfigRow2([
      ...configRow2,
      { shipmentMethod: "", rateMultiplier: "", minEta: "", maxEta: "" },
    ]);
  };

  const removeRowConfig2 = (index) => {
    const updatedRows = [...configRow2];
    updatedRows.splice(index, 1);
    setConfigRow2(updatedRows);
  };

  const handleRowChangeConfig2 = (index, field, e) => {
    const updatedRows = [...configRow2];
    updatedRows[index][field] = e.target.value;
    setConfigRow2(updatedRows);
  };
  /**** Config Weight range table  ****/
  const [configRow3, setConfigRow3] = useState([
    {
      minWeight: "",
      maxWeight: "",
      discount: "",
    },
  ]);

  const addRowConfig3 = () => {
    setConfigRow3([
      ...configRow3,
      { minWeight: "", maxWeight: "", discount: "" },
    ]);
  };

  const removeRowConfig3 = (index) => {
    const updatedRows = [...configRow3];
    updatedRows.splice(index, 1);
    setConfigRow3(updatedRows);
  };

  const handleRowChangeConfig3 = (index, field, e) => {
    const updatedRows = [...configRow3];
    updatedRows[index][field] = e.target.value;
    setConfigRow3(updatedRows);
  };

  /**** Config Dimension range table  ****/
  const [configRow4, setConfigRow4] = useState([
    {
      min_length: "",
      max_length: "",
      min_height: "",
      max_height: "",
      min_breadth: "",
      max_breadth: "",
      cost: "",
      dimension_unit: "",
    },
  ]);

  const addRowConfig4 = () => {
    setConfigRow4([...configRow4, { minVolume: "", maxVolume: "", cost: "" }]);
  };

  const removeRowConfig4 = (index) => {
    const updatedRows = [...configRow4];
    updatedRows.splice(index, 1);
    setConfigRow4(updatedRows);
  };

  const handleRowChangeConfig4 = (index, field, e, setConfigRow4) => {
    const updatedRows = [...configRow4];
    updatedRows[index][field] = e.target.value;
    setConfigRow4(updatedRows);
  };

  /**Config Distance Table */
  const [configRow5, setConfigRow5] = useState([
    { minEta: "", maxEta: "", shippingCost: "" },
  ]);

  const addRowConfig5 = () => {
    setConfigRow5((prev) => [
      ...prev,
      { minEta: "", maxEta: "", shippingCost: "" },
    ]);
  };

  const removeRowConfig5 = (index) => {
    setConfigRow5((prev) => prev.filter((_, i) => i !== index));
  };
  // For Distance Range Based Cost
  const handleRowChangeConfig5 = (index, key, event) => {
    const updatedRows = [...configRow5];
    updatedRows[index][key] = event.target.value;
    setConfigRow5(updatedRows);
  };
  /***Config Package Table */

  const [configRow6, setConfigRow6] = useState([
    { packagingType: "", packagingCost: "" },
  ]);

  const addRowConfig6 = () => {
    setConfigRow6((prev) => [
      ...prev,
      { packagingType: "", packagingCost: "" },
    ]);
  };

  const removeRowConfig6 = (index) => {
    setConfigRow6((prev) => prev.filter((_, i) => i !== index));
  };

  // For Packaging Type Based Cost
  const handleRowChangeConfig6 = (index, key, event) => {
    const updatedRows = [...configRow6];
    updatedRows[index][key] = event.target.value;
    setConfigRow6(updatedRows);
  };
  const [configFormData, setConfigFormData] = useState({
    currencyUnit: "",
    flatRate: "",
    primeMemberDiscount: "",
    // ... other form data fields for config ...
  });

  // ... other state variables for other form data ...

  const handleConfigInputChange = (field, value) => {
    setConfigFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [weightunitInput, setweightunitInput] = useState();

  const weightRangeInputOnChange = (event) => {
    setweightunitInput(event.target.value);
    console.log(event.target.value);
  };

  const [volumeunitInput, setvolumeunitInput] = useState();

  const volumeRangeInputOnChange = (event) => {
    setvolumeunitInput(event.target.value);
    console.log(event.target.value);
  };

  const [distanceunitInput, setdistanceunitInput] = useState();

  const distanceRangeInputOnChange = (event) => {
    setdistanceunitInput(event.target.value);
    console.log(event.target.value);
  };

  //Dispatching data here...

  const handleSubmit = (e) => {
console.log(getdropdownValue)
    setShowModal(false);

    e.preventDefault();

    let warehouseData;

    warehouseData = {
      shipment_template: {
        name: formData.shipmentName,

        tags: formData.shipmenttags,
        flat_rate: parseInt(formData.flatrateamount, 10),
        // min_order_price: 40.0,
        // max_order_price: 30.0,
        shipment_method_charge: parseFloat(formData.shipment_method_charge),
        shipment_method_id: parseInt(formData.shipment_method_id, 10),
        flat_rate_operator: formData.flatrateoperator,
        currency_unit: formData.currencyunit,
      },
      

      config_shipment: {
        order_discount: rows2.map((row) => ({
          min_range: parseInt(row.minRange, 10)  || 0,
          max_range: parseInt(row.maxRange, 10)  || 0,
          discount: parseInt(row.regulardiscount, 10)  || 0,

          prime_discount: parseFloat(row.primediscount, 10) || 0,
        })),
        weight_charge: rows3.map((row) => ({
          min_range: parseInt(row.minRange, 10) || 0,
          max_range: parseInt(row.maxRange, 10)  || 0,
          charge: parseInt(row.primecharges, 10)  || 0,
          unit: getdropdownValue || "",
        })),
        distance_charge: rows4.map((row) => ({
          min_range: parseInt(row.minRange, 10)  || 0,
          max_range: parseInt(row.maxRange, 10)  || 0,
          unit: getDistanceInputValue || "",

          charge: parseInt(row.field2, 10)  || 0,
        })),
        packaging_type: rows5.map((row) => ({
          package_type: row.packagingType || "",
          charge: parseInt(row.charges, 10)  || 0,
          prime_charge: parseInt(row.primeCharges, 10)  || 0,
        })),
        dimension_based_cost: configRow4.map((row) => ({
          min_length: parseInt(row.min_length)  || 0,
          max_length: parseInt(row.max_length) || 0,
          min_height: parseInt(row.min_height) || 0,
          max_height: parseInt(row.max_height) || 0,
          min_breadth: parseInt(row.min_breadth) || 0,
          max_breadth: parseInt(row.max_breadth) || 0,
          cost: parseInt(row.cost) || 0,
          dimension_unit: getDimensionInputValue || "",
        })),
      },
    };

    console.log("Warehouse Data:", warehouseData);
    dispatch(createWarehouseAsync(warehouseData));
  };

  // Select the shipmentMethodList state from the store
  const shipmentMethodList = useSelector(
    (state) => state.shipmentlist.shipmentMethodList
  );
  const shipmentMethodData = shipmentMethodList.data;

  // Dispatch the async thunk action creator to fetch shipment method list
  useEffect(() => {
    dispatch(
      shipmentMethodListAsync({
        query: {
          page_no: 1,
          per_page: 100,
        },
      })
    );
  }, [dispatch]);
  return (
    // onSubmit={handleSubmit}
    <div className="shippingcharges--page">
      <form onSubmit={handleSubmit}>
        <div className="">
          <span className="back--para">
            <img
              className="back-icon"
              src={BackIcon}
              alt=""
              onClick={backFunc}
            />{" "}
            <p className="back-p">Back</p>
          </span>
          <p className="add-fulfillment-para">Shipping Configuration</p>
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="type" className="shipping--chargelabels">
                Flat Rate
              </label>{" "}
              <br />
              <select
                name="flatrateoperator"
                value={formData.flatrateoperator}
                onChange={warehouseCreationInput}
                className="type--class shipping--charges--dropdown"
              >
                <option value="">Select Flat Rate</option>
                <option value="Multiply">Multiply</option>
                <option value="Add">Add</option>
              </select>
            </div>

            <div className="col-md-3">
              <label
                htmlFor="flaterateamount"
                className="shipping--chargelabels"
              >
                Amount
              </label>
              <input
                type="text"
                name="flatrateamount"
                value={formData.flatrateamount}
                onChange={warehouseCreationInput}
                className="shippingcharge--inp"
                placeholder="Enter Amount"
              />
            </div>

            <div className="col-md-3">
              <label htmlFor="line2" className="shipping--chargelabels">
                Currency
              </label>
              <select
                name="currencyunit"
                value={formData.currencyunit}
                onChange={warehouseCreationInput}
                className="type--class shipping--charges--dropdown"
              >
                <option value="">Select Currency</option>
                <option value="INR">INR</option>
                <option value="$">$</option>
              </select>
            </div>
          </div>
        </div>
        <div className=" ">
          <p className="add-fulfillment-para">Shipment Method</p>

          <div className="card-body">
            {shipmentMethodData &&
              shipmentMethodData.rows &&
              shipmentMethodData.columns && (
                <table className=" shipmethod">
                  <thead>
                    <tr>
                      <th style={{ width: "4%" }}></th>

                      <th>Shipment Method Name</th>
                      <th>ETA</th>
                      <th>Charges</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shipmentMethodData.rows.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="radio"
                            name={`row-${index}-radio`}
                            onClick={() =>
                              handleRadioButtonClick(index, row[0])
                            }
                            checked={selectedRow === index}
                          />
                        </td>
                        <td>{row[1]}</td>
                        <td>
                          {row[2]} - {row[3]} {row[4]}
                        </td>{" "}
                        {/* Assuming row[2] and row[3] represent minimum and maximum ETA respectively, and row[4] represents units */}
                        <td>
                          {index === selectedRow && (
                            <input
                              type="text"
                              className="form-control shippingcharge--inp shadow-none"
                              placeholder="Enter Charges"
                              name="shipment_method_charge"
                              value={formData.shipment_method_charge}
                              onChange={warehouseCreationInput}
                              style={{ fontSize: "14px" }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          </div>
        </div>
        <div className="shipping--charges ">
          <p className="add-fulfillment-para">Shipment ETA & Shipment Cost</p>

          <div className="card-body">
            <div className="">
              <div>
                {shipmentTable && (
                  <>
                    <div>
                      <button type="button" className="add-row-btn" onClick={addRow}>
                        Add Row
                        <FaPlus />
                      </button>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Shipment Method</th>
                            <th>Shipment Charges</th>
                            <th>Prime Charges</th>
                            <th>ETA</th>
                            <th className="th--action">Action</th>
                          </tr>
                        </thead>
                        {rows1.map((row, index) => (
                          <tbody>
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  value={row.shipmentMethod}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "shipmentMethod",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.shipmentCharges}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "shipmentCharges",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.primeCharges}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "primeCharges",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <div className="inp-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Eta"
                                    value={row.minEta}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "minEta",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="prepend">-</p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Eta"
                                    value={row.maxEta}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "maxEta",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td className="th--action">
                                <img
                                  src={Trash}
                                  alt=""
                                  onClick={() => deleteRow(index)}
                                  className="trassh--icon"
                                />
                                {/* <button
                                  onClick={() => deleteRow(index)}
                                  className="delete-btn"
                                >
                                  <FaTrash />
                                </button> */}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </>
                )}

                {/* Shipment Method */}

                {/* Order Price */}
                <div>
                  <div className="row">
                    <div className="col">
                      <div className="one--div">
                        <input type="checkbox" onClick={toggleOrderTable} />
                        <h6
                          className="cl-hd"
                          style={{ position: "relative", top: "2px" }}
                        >
                          Order Cart Based Discount
                        </h6>
                      </div>
                    </div>
                    <div className="col">
                      <div className="one--div">
                        <input type="checkbox" onClick={toggleOrderWeight} />
                        <h6
                          className="cl-hd"
                          style={{ position: "relative", top: "2px" }}
                        >
                          Weight Based Charges
                        </h6>
                      </div>
                    </div>
                    <div className="col">
                      <div style={{ display: "flex" }}>
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onChange={() =>
                            handleCheckboxChange("dimensionRange")
                          }
                        />
                        <h6 className="hd hedr--pos cl-hd ">
                          Dimension Range Based Cost
                        </h6>
                      </div>
                    </div>
                    <div className="col">
                      <div style={{ display: "inline-flex" }}>
                        <input type="checkbox" onClick={toggleOrderPackage} />
                        <h6 className="packaging--type cl-hd">
                          Packaging Type
                        </h6>
                      </div>
                    </div>
                    <div className="col">
                      <div className="one--div">
                        <input type="checkbox" onClick={toggleOrderDistance} />
                        <h6
                          className="cl-hd"
                          style={{ position: "relative", top: "2px" }}
                        >
                          Distance
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3"> */}

                  {orderTable && (
                    <>
                      <div className="addrow-table--div">
                        <button type="button" className="add-row-btn" onClick={addRow2}>
                          Add Row
                          <FaPlus />
                        </button>
                        <table className="table ordb">
                          <thead>
                            <th className="orderbased--discount">
                              Order cart Based Discount
                            </th>
                            <th className="orderbased--discount"></th>
                            <th className="orderbased--discount"></th>
                            <th className="orderbased--discount"></th>
                            <th className="orderbased--discount lst"></th>
                          </thead>

                          <tr className="sg-charges">
                            <td className="shippingcharges--label">
                              Min Price
                            </td>
                            <td className="shippingcharges--label">
                              Max Price
                            </td>
                            <td className="shippingcharges--label">
                              Regular Discount
                            </td>
                            <td className="shippingcharges--label">
                              Prime Discount
                            </td>
                            <td></td>
                          </tr>
                          {rows2.map((row2, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control shadow-none addrow-inp"
                                    value={row2.minRange}
                                    onChange={(e) =>
                                      handleInputChange2(
                                        index,
                                        "minRange",
                                        e.target.value,
                                        setRows2
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control shadow-none addrow-inp"
                                    value={row2.maxRange}
                                    onChange={(e) =>
                                      handleInputChange2(
                                        index,
                                        "maxRange",
                                        e.target.value,
                                        setRows2
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control shadow-none addrow-inp"
                                    value={row2.regulardiscount}
                                    onChange={(e) =>
                                      handleInputChange2(
                                        index,
                                        "regulardiscount",
                                        e.target.value,
                                        setRows2
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control shadow-none addrow-inp"
                                    value={row2.primediscount}
                                    onChange={(e) =>
                                      handleInputChange2(
                                        index,
                                        "primediscount",
                                        e.target.value,
                                        setRows2
                                      )
                                    }
                                  />
                                </td>
                                <td className="th--action">
                                  <img
                                    src={Trash}
                                    alt=""
                                    onClick={() => deleteRow2(index, setRows2)}
                                    className="trassh--icon"
                                  />
                                  {/* <button
                                    onClick={() => deleteRow2(index, setRows2)}
                                  >
                                    <FaTrash />
                                  </button> */}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
                {/* Order Price */}

                {/* Weight */}
                <div className="addrow-table--div">
                  <div className="row"></div>
                  {orderWeight && (
                    <>
                      <div className="row">
                        <div className="col-md-8"></div>
                      
                        {/* {rows3.map((row, index) => (
                          <div key={index} className="col-md-2">
                            <select
                              name="unit"
                              className="type--class unitss"
                              value={row.unit} // Use row.unit instead of rows3.unit
                              onChange={(e) =>
                                handleInputChange3(
                                  index,
                                  "unit",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Units">Units</option>
                              <option value="KG">KG</option>
                              <option value="10Kg">10Kg</option>
                            </select>
                          </div>
                        ))} */}
                        <div className="row">
    <div className="col-md-8"></div>

    {/* Render the dropdown only if there are rows */}
    {rows3.length > 0 && (
        <div className="col-md-2">
            <select
                name="unit"
                className="type--class unitss"

                value={rows3[0].unit} // Since all rows share the same unit value
                onChange={(e) =>
                   InputChange3(
                        0, // Pass 0 since it's the same unit for all rows
                        "unit",
                        e.target.value
                    )
                }
                style={{position: 'relative',
                  marginBottom: '12px !important',
                  fontSize: "14px",
                  left: "29px",
                  height:" 40px"}}
            >
                <option value="Select Units">Select Units</option>
                <option value="KG">KG</option>
                <option value="10Kg">10Kg</option>
            </select>
        </div>
    )}

    {/* Render the "Add Row" button */}
    <div className="col-md-2">
        <button
            type="button"
            className="add-row-btn"
            onClick={addRow3}
            style={{ width: "100%", height: "40px",position:'relative',left:'23px' }}
        >
            Add Row
            <FaPlus />
        </button>
    </div>
</div>


                      
                      </div>

                      <div>
                        <table className="table ordb">
                          <thead>
                            <tr>
                              <th className="orderbased--discount">
                                Weight Based Charges
                              </th>
                              <th className="orderbased--discount"></th>
                              <th className="orderbased--discount"></th>

                              <th className="orderbased--discount lst"></th>
                            </tr>
                          </thead>
                          <tr className="sg-charges">
                            <td className="shippingcharges--label">
                              Min Weight
                            </td>
                            <td className="shippingcharges--label">
                              Max Weight
                            </td>
                            <td className="shippingcharges--label">
                              Prime Charges
                            </td>
                            <td></td>
                          </tr>
                          {rows3.map((row3, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control shadow-none addrow-inp"
                                    value={row3.minRange}
                                    onChange={(e) =>
                                      handleInputChange3(
                                        index,
                                        "minRange",
                                        e.target.value,
                                        setRows3
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row3.maxRange}
                                    className="form-control shadow-none addrow-inp"
                                    onChange={(e) =>
                                      handleInputChange3(
                                        index,
                                        "maxRange",
                                        e.target.value,
                                        setRows3
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row3.primecharges}
                                    className="form-control shadow-none addrow-inp"
                                    onChange={(e) =>
                                      handleInputChange3(
                                        index,
                                        "primecharges",
                                        e.target.value,
                                        setRows3
                                      )
                                    }
                                  />
                                </td>

                                <td className="th--action">
                                  <img
                                    src={Trash}
                                    alt=""
                                    onClick={() => deleteRow3(index, setRows3)}
                                    className="trassh--icon"
                                  />
                                  {/* <button
                                    onClick={() => deleteRow3(index, setRows3)}
                                  >
                                    <FaTrash />
                                  </button> */}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
                {/* Weight */}

                {/**Dimension Range Table ****/}
                <div className="addrow-table--div ">
                  <div className="col-md-12 mb-3">
                    <div className="row"></div>

                    {showTables.dimensionRange && (
                      <div>
                        <div className="row">
                          <div className="row">
                            <div className="col-md-8"></div>

                            {/* {configRow4.map((row, index) => (
                              <div key={index} className="col-md-2">
                                <select
                                  name="dimension_unit"
                                  className="type--class unitss"
                                  value={row.dimension_unit}
                                  onChange={(e) =>
                                    handleInputDimension(
                                      index,
                                      "dimension_unit",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="CM">CM</option>
                                  <option value="Inches">Inches</option>
                                </select>
                              </div>
                            ))} */}
                            {configRow4.length > 0 &&(
                              <div  className="col-md-2">
                                <select
                                  name="dimension_unit"
                                  className="type--class unitss"
                                  value={configRow4[0].dimension_unit}
                                  onChange={(e) =>
                                    handleInputDimension(
                                     0,
                                      "dimension_unit",
                                      e.target.value
                                    )
                                  }
                                  style={{position: 'relative',
                  marginBottom: '12px !important',
                  fontSize: "14px",
                  left: "29px",
                  height:" 40px"}}
                                >
                                  <option value="Select Units">Select Units</option>
                                  <option value="CM">CM</option>
                                  <option value="Inches">Inches</option>
                                </select>
                              </div>
                            )}
                            <div className="col-md-2">
                              <button
                                className="add-row-btn"
                                type="button"
                                onClick={addRowConfig4}
                               style={{ width: "100%", height: "40px",position:'relative',left:'23px' }}
                              >
                                Add Row
                                <FaPlus />
                              </button>
                            </div>
                          </div>
                        </div>
                        <p className="dimesionbased">
                          {" "}
                          Dimension Based Charges
                        </p>
                        <table className="table ordb">
                          <thead>
                            <tr>
                              <th className="orderbased--discount kll">Min</th>

                              <th className="orderbased--discount kll">Max</th>
                              <th className="orderbased--discount kll"></th>

                              <th className="orderbased--discount lst  lkl"></th>
                            </tr>
                          </thead>

                          <tbody>
                            {configRow4.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="inp-group inp">
                                    <label
                                      htmlFor=""
                                      className="shippingcharges--label"
                                    >
                                      Width
                                      <input
                                        type="text"
                                        value={row.min_breadth}
                                        className="form-control inp-width shadow-none addrow-inp"
                                        onChange={(e) =>
                                          handleRowChangeConfig4(
                                            index,
                                            "min_breadth",
                                            e,setConfigRow4
                                          )
                                        }
                                      />
                                    </label>
                                    <p className="prepend">-</p>
                                    <label
                                      htmlFor=""
                                      className="shippingcharges--label"
                                    >
                                      Length
                                      <input
                                        type="text"
                                        value={row.min_length}
                                        className="form-control  inp-width shadow-none addrow-inp"
                                        onChange={(e) =>
                                          handleRowChangeConfig4(
                                            index,
                                            "min_length",
                                            e,setConfigRow4
                                          )
                                        }
                                      />
                                    </label>
                                    <p className="prepend">-</p>
                                    <label
                                      htmlFor=""
                                      className="shippingcharges--label"
                                    >
                                      Height
                                      <input
                                        type="text"
                                        value={row.min_height}
                                        className="form-control inp-width shadow-none addrow-inp"
                                        onChange={(e) =>
                                          handleRowChangeConfig4(
                                            index,
                                            "min_height",
                                            e,setConfigRow4
                                          )
                                        }
                                      />
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div className="inp-group inp">
                                    <label
                                      htmlFor=""
                                      className="shippingcharges--label"
                                    >
                                      Width
                                      <input
                                        type="text"
                                        value={row.max_breadth}
                                        className="form-control inp-width shadow-none addrow-inp"
                                        onChange={(e) =>
                                          handleRowChangeConfig4(
                                            index,
                                            "max_breadth",
                                            e,setConfigRow4
                                          )
                                        }
                                      />
                                    </label>
                                    <p className="prepend">-</p>
                                    <label
                                      htmlFor=""
                                      className="shippingcharges--label"
                                    >
                                      Length
                                      <input
                                        type="text"
                                        value={row.max_length}
                                        className="form-control  inp-width shadow-none addrow-inp"
                                        onChange={(e) =>
                                          handleRowChangeConfig4(
                                            index,
                                            "max_length",
                                            e,setConfigRow4
                                          )
                                        }
                                      />
                                    </label>
                                    <p className="prepend">-</p>
                                    <label
                                      htmlFor=""
                                      className="shippingcharges--label"
                                    >
                                      Height
                                      <input
                                        type="text"
                                        value={row.max_height}
                                        className="form-control inp-width shadow-none addrow-inp"
                                        onChange={(e) =>
                                          handleRowChangeConfig4(
                                            index,
                                            "max_height",
                                            e,setConfigRow4
                                          )
                                        }
                                      />
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <label
                                    htmlFor=""
                                    className="shippingcharges--label"
                                  >
                                    Charges
                                    <input
                                      type="text"
                                      value={row.cost}
                                      className="form-control shadow-none addrow-inp"
                                      style={{
                                        marginTop: "6px",
                                        width: "140%",
                                      }}
                                      onChange={(e) =>
                                        handleRowChangeConfig4(index, "cost", e,setConfigRow4)
                                      }
                                    />
                                  </label>
                                </td>
                                <td
                                // className="delete-btn"
                                // onClick={() => removeRowConfig4(index)}
                                >
                                  <img
                                    src={Trash}
                                    alt=""
                                    onClick={() => removeRowConfig4(index)}
                                    className="trassh--icon trsh"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  {orderPackage && (
                    <div className="addrow-table--div">
                      <button className="add-row-btn" type="button" onClick={addRow5} style={{width: '15.2%',
    height: '40px'}}>
                        Add Row
                        <FaPlus />
                      </button>
                      <table className="table ordb">
                        <thead>
                          <th className="orderbased--discount">
                            Packaging Type Discount
                          </th>
                          <th className="orderbased--discount"></th>
                          <th className="orderbased--discount"></th>

                          <th className="orderbased--discount lst"></th>
                        </thead>
                        <tr className="sg-charges">
                          <td className="shippingcharges--label">
                            Packaging type
                          </td>
                          <td className="shippingcharges--label">
                            Packaging Charges
                          </td>
                          <td className="shippingcharges--label">
                            Prime Charges
                          </td>

                          <td></td>
                        </tr>

                        {rows5.map((row, index) => (
                          <tbody>
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  className="form-control shadow-none addrow-inp"
                                  value={row.packagingType}
                                  onChange={(e) =>
                                    handleInputChange5(
                                      index,
                                      "packagingType",
                                      e.target.value,setRows5
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.charges}
                                  className="form-control shadow-none addrow-inp"
                                  onChange={(e) =>
                                    handleInputChange5(
                                      index,
                                      "charges",
                                      e.target.value,setRows5
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.primeCharges}
                                  className="form-control shadow-none addrow-inp"
                                  onChange={(e) =>
                                    handleInputChange5(
                                      index,
                                      "primeCharges",
                                      e.target.value,setRows5
                                    )
                                  }
                                />
                              </td>

                              <td className="th--action ">
                                <img
                                  src={Trash}
                                  alt=""
                                  onClick={() => deleteRow5(index)}
                                  className="trassh--icon"
                                  style={{ position: "relative" }}
                                />
                                {/* <button
                                  onClick={() => deleteRow5(index)}
                                  className="delete-btn"
                                >
                                  <FaTrash />
                                </button> */}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                </div>

                {/* Distance */}
                <div className="addrow-table--div">
                  {orderDistance && (
                    <>
                      <div className="row">
                        <div className="col-md-8"></div>
                        {rows4.length>0 &&(
                          <div className="col-md-2">
                            <select
                              name="dimension_unit"
                              className="type--class unitss"
                              value={rows4[0].unit}
                              onChange={(e) =>
                                InputChange4(
                                 0,
                                  "unit",
                                  e.target.value
                                )
                              }
                              style={{height:'40px'}}
                            >
                              <option value="Units">Select Units</option>
                              <option value="KM">KM</option>
                              <option value="M">M</option>
                            </select>
                          </div>
                        )}
                        <div className="col-md-2">
                          <button
                            className="add-row-btn"
                            onClick={addRow4}
                            type="button"
                            style={{ width: "100%", height: "40px" }}
                          >
                            Add Row
                            <FaPlus style={{ fontSize: "10px" }} />
                          </button>
                        </div>
                      </div>

                      <div>
                        <table className="table ordb">
                          <thead>
                            <tr>
                              <th className="orderbased--discount">
                                Distance Based Charges
                              </th>
                              <th className="orderbased--discount"></th>
                              <th className="orderbased--discount"></th>

                              <th
                                className="th--action orderbased--discount lst"
                                style={{ width: "6%" }}
                              ></th>
                            </tr>
                            <tr className="sg-charges">
                              <td className="shippingcharges--label">
                                Min Range
                              </td>
                              <td className="shippingcharges--label">
                                Max Range
                              </td>
                              <td className="shippingcharges--label">
                                Charges
                              </td>

                              <td></td>
                            </tr>
                          </thead>
                          {rows4.map((row4, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control shadow-none addrow-inp"
                                    value={row4.minRange}
                                    onChange={(e) =>
                                      handleInputChange4(
                                        index,
                                        "minRange",
                                        e.target.value,
                                        setRows4
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row4.maxRange}
                                    className="form-control shadow-none addrow-inp"
                                    onChange={(e) =>
                                      handleInputChange4(
                                        index,
                                        "maxRange",
                                        e.target.value,
                                        setRows4
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row4.field2}
                                    className="form-control shadow-none addrow-inp"
                                    onChange={(e) =>
                                      handleInputChange4(
                                        index,
                                        "field2",
                                        e.target.value,
                                        setRows4
                                      )
                                    }
                                  />
                                </td>

                                <td className="th--action">
                                  <img
                                    src={Trash}
                                    alt=""
                                    onClick={() => deleteRow4(index, setRows3)}
                                    className="trassh--icon"
                                  />
                                  {/* <button
                                    onClick={() => deleteRow4(index, setRows3)}
                                  >
                                    <FaTrash />
                                  </button> */}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
                {/* Distance */}

                {/* Distance */}
              </div>
            </div>
          </div>
        </div>
      </form>
     <button
        variant="primary"
        onClick={handleShow}
        className="create--shipment--btn"

      >
        Create Shipment
      </button>
     

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="shipment--creation--headerpopup">Shipment Creation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="" className="shipment--creation--inplabel">Shipment Name</label>
              <input
                type="text"
                name="shipmentName"
                value={formData.shipmentName}
                className="form-control shipment--creation--inp"
               
                onChange={warehouseCreationInput}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="" className="shipment--creation--inplabel">Shipment Tags</label>
              <input
                type="text"
                name="shipmenttags"
                value={formData.shipmenttags.join(", ")} // Join array to display comma-separated tags in the input
                className="form-control shipment--creation--inp"
               
                onChange={warehouseCreationInput}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="shipmentcharges--footer">
          <Button variant="secondary" onClick={handleClose} style={{visibility:'hidden'}}>
            Close
          </Button>
          <Button  onClick={handleSubmit} className="shipmentcharges--submit">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShipmentCharges;
