import React,{useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../components/UIComponents/Popup/Popup";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import { addbulkInventoryAsync } from "../../app/store/addbulkInventorySlice";
import { downloadTemplateApiAsync } from "../../app/store/downloadTemplateSlice";
import Button from "../../components/UIComponents/Button/Button";
const localCatlogueData = {
  column_names: [
    "Product ID",
    "Variant ID",
    "Inventory ID",
    "SKU/UPC",
    "Product Name",
  ],
  rows: [
    [
      "8311327752502",
      "45366460121398",
      "47417402130742",
      "Z-194917831717",
      "Soletide Mid Sneaker",
    ],
    [
      "8092962980150",
      "44343294492982",
      "46392419975478",
      "194917831717",
      "Splash Sandal",
    ],
  ],
};
const Catalogue = () => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [showPincodesListing, setPincodesListing] = useState(true);
 
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { inventoryList, loading, error } = useSelector((state) => state.inventoryListing);
  const handleAddBulkClick = () => {
    setPincodesListing(false);
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });
      dispatch( addbulkInventoryAsync({ file: selectedFiles }));
    } else {
      console.log("Please select a file.");
      // Handle the case when no file is selected
    }
    setShowModal(true);
    setShow(false)
    // setShowTable(false);
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };
  const downloadTemplate = () => {
    dispatch(downloadTemplateApiAsync());
  };
  return (
    <div>
       <div className="row inventory--table--div" style={{ marginTop: "3%" }} >
        <p className="add-fulfillment-para">Catalogue</p>
        <div className="row" style={{padding:'0px'}}>
        <div className="col-md-4">
        <Inputsearch placeholder="Search by Product ID or Variant ID.." style={{display:'flex'}}/>
         
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5"></div>
        
        <div className="col-md-1">
        <Button className="fulfillment--bulkupload" onClick={() => setShow(true)}>
       Bulk Upload
      </Button>
        </div>
      </div>
      <Popup
                    show={show}
                    handleClose={() => setShow(false)}
                    handleShow={() => setShow(true)}
                    modalTitle="Modal Demo"
                    onChange={handleFileChange}
                    type="file"
                    id="fileInput"
                    htmlFor="fileInput"
                    onClick={() => setIsOpen(true)}
                    handleFileChange={handleFileChange}
                    selectedFiles={selectedFiles}
                    handleAddBulkClick={handleAddBulkClick}
                    downloadTemplate={downloadTemplate}
                  />
      <div className="row" style={{margin: '0px',
    padding: '0px'}}>
     
        <table className="catalogue--table">
          <thead>
            <tr>
              {localCatlogueData.column_names.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {localCatlogueData.rows.map((row, rowIndex) => (
              <tr>
                {row.map((cell, cellIndex) => (
                 <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
              
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default Catalogue;
