import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {addBulkPincodesFunc } from '../../services/apiService';

export const addbulkPincodeAsync = createAsyncThunk('pincode/addbulkpincode', async ({ files }) => {
  try {
    const response = await addBulkPincodesFunc (files);
    console.log('API Response:', response);
    return response; // Assuming the API response already has the structure you want
  } catch (error) {
    console.log('Error in uploading pincode', error);
    throw error;
  }
});

const addBulkPincodesSlice = createSlice({
  name: 'addbulkpincodes',
  initialState: {
    pincodelisting: null,
    pincodedata: {
      columns: [],
      rows: [],
    },
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addbulkPincodeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addbulkPincodeAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pincodelisting = action.payload;
        state.pincodedata.columns = action.payload.columns || [];
        state.pincodedata.rows = action.payload.rows || [];
        console.log('Entire Payload:', action.payload);
        console.log('Pincodes from Payload:', state.pincodedata);
      })
      .addCase(addbulkPincodeAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default addBulkPincodesSlice.reducer;
