import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderNotesApi } from '../../services/apiService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const orderNotesAsync = createAsyncThunk("notes/orderNotes", async ({ order_id, note_type, note_id, note_comments }) => {
    try {
        const response = await orderNotesApi({ order_id, note_type, note_id, note_comments });
        console.log(response);
        toast.success(` ${response.message}`); 
        return response;
    } catch (error) {
        console.error('Error in updating notes:', error);
        throw error;
    }
});

const orderNotesSlice = createSlice({
    name: 'notes',
    initialState: {
        ordernotes: null,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(orderNotesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(orderNotesAsync.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.ordernotes = action.payload;
            })
            .addCase(orderNotesAsync.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    }
});

export default orderNotesSlice.reducer;
