import React, { useState } from "react";
import "./Notes.css";
import notesIcon from "../../images/edit.png";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { orderNotesAsync } from "../../app/store/notesSlice";
import { orderDetailAsync } from "../../app/store/orderDetailsSlice";
import { useLocation} from "react-router-dom";


const Notes = (props) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [notesGroup, setNotesGroup] = useState([]);
  const [inputdisable, setInputDisable] = useState(true);
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const editNotes = () => {
    setNotes(true);
    setInputDisable(false);
  };
  const orderid = props.orderIdForNotes;
  const notesSubmit = () => {
    console.log(noteText);
    setNotes(false);
    setNoteText("");
    setNotesGroup([...notesGroup, noteText]);

    // Dispatch the action asynchronously

    dispatch(
      orderNotesAsync({
        order_id: orderid,
        note_type: "systemaudit",
        note_id: 3,
        note_comments: noteText, // Assuming noteText is the value you want to send
      })
    );
   dispatch(orderDetailAsync(orderId));
  };

  const notesInputHandle = (e) => {
    setNoteText(e.target.value);
    setInputDisable(false);
  };

  const closeNote = () => {
    setNotes(false);
    setNoteText("");
    setInputDisable(true);
  };
  // const formattedNotes = props.notesList.replace(/\n/g, '<br />');
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card notes--card">
            <div className="card-body notes-body">
              <div style={{ display: "flex" }}>
                <p className="notes--heading">Notes:</p>
                {notes ? (
                  <div className="notes--icons">
                    <FaTimes className="cancel--icon" onClick={closeNote} />
                    <FaCheck className=" tick--icon" onClick={notesSubmit} />
                  </div>
                ) : (
                  <img
                    src={notesIcon}
                    alt=""
                    className="notes-icon"
                    onClick={editNotes}
                  />
                )}
              </div>

              <textarea
                type="text"
                placeholder="Enter notes here..."
                className="notes--input"
                value={noteText}
                onChange={notesInputHandle}
                disabled={inputdisable}
              />
              <div>
                <div className="ul--notes">
                {props.notesList && props.notesList.split('\n').map((note, index) => (
    <p className="notes--list" key={index}>{note}</p>
  ))}
                </div>
                {/* <ul className="ul--notes">
                  {notesGroup.map((note,index) => (
                    <li key={index} className="notes--list">
                      {console.log(props.notesList)}
                     
                    </li>
                  ))}
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
