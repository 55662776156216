import React, { useEffect,useState } from "react";
import "./Inventory.css";
import { inventoryListAsync } from "../../app/store/inventoryListSlice";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../components/UIComponents/Button/Button";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import { addbulkInventoryAsync } from "../../app/store/addbulkInventorySlice";
import { downloadTemplateApiAsync } from "../../app/store/downloadTemplateSlice";
import Popup from "../../components/UIComponents/Popup/Popup";
const Inventory = () => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [showPincodesListing, setPincodesListing] = useState(true);
 
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { inventoryList, loading, error } = useSelector((state) => state.inventoryListing);

  useEffect(() => {
    dispatch(
      inventoryListAsync({
        query: {
          page_no: 1,
          per_page: 100,
        },
      })
    );
  }, [dispatch,show]);
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };
  const handleAddBulkClick = () => {
    setPincodesListing(false);
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });
      dispatch( addbulkInventoryAsync({ file: selectedFiles }));
    } else {
      console.log("Please select a file.");
      // Handle the case when no file is selected
    }
    setShowModal(true);
    setShow(false)
    // setShowTable(false);
  };
  const columns = useSelector((state) => state.pincodesListing.columnNames);
  console.log(columns);
  const rows = useSelector((state) => state.pincodesListing.rows);
  console.log(rows);

  const pincodeColumns = useSelector(
    (state) => state.serviceabilityPincodesListing.columnNames
  );
  console.log(pincodeColumns);
  const pincodeRows = useSelector(
    (state) => state.serviceabilityPincodesListing.rows
  );
  console.log(pincodeRows);
  const downloadTemplate = () => {
    dispatch(downloadTemplateApiAsync());
  };
  function getColumnClassName(columnName) {
    switch(columnName) {
        case 'Item_id':
            return 'th-width-80';
        case 'Sku_id':
            return 'th-width-80';
        case 'Barcode':
            return 'th-width-80';
        case 'Quantity_available':
            return 'th-width-123';
        case 'Qauntity_allocated':
            return 'th-width-132';
        case 'Unit':
            return 'th-width-60';
        case 'Warehouse_Name':
            return 'th-width-135';
        case 'Price':
            return 'th-width-60';
        case 'ETA':
            return 'th-width-80'
        default:
            return '';
    }
}
  return (
    <div>
      <div className="row inventory--table--div" style={{ marginTop: "3%" }} >
        <p className="add-fulfillment-para">Inventory</p>
        <div className="row" style={{padding:'0px'}}>
        <div className="col-md-4">
        <Inputsearch placeholder="Search by Item Id or SKU Id.." style={{display:'flex'}}/>
         
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-5"></div>
        
        <div className="col-md-1">
        <Button className="fulfillment--bulkupload" onClick={() => setShow(true)}>
       Bulk Upload
      </Button>
        </div>
      </div>
      <Popup
                    show={show}
                    handleClose={() => setShow(false)}
                    handleShow={() => setShow(true)}
                    modalTitle="Modal Demo"
                    onChange={handleFileChange}
                    type="file"
                    id="fileInput"
                    htmlFor="fileInput"
                    onClick={() => setIsOpen(true)}
                    handleFileChange={handleFileChange}
                    selectedFiles={selectedFiles}
                    handleAddBulkClick={handleAddBulkClick}
                    downloadTemplate={downloadTemplate}
                  />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <table className="inventory--table">
            <thead>
              <tr>
                {/* {inventoryList.columns && inventoryList.columns.map((columnName, index) => (
                  <th key={index}>{columnName.replace(/_/g, " ")}</th>
                ))} */}
                {inventoryList.columns && inventoryList.columns.map((columnName, index) => (
    <th key={index} className={getColumnClassName(columnName)}>
        {columnName.replace(/_/g, " ")}
    </th>
))}
              </tr>
            </thead>
            <tbody>
              {inventoryList.rows && inventoryList.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cellData, cellIndex) => (
                    <td key={cellIndex}>{cellData}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {/* {showPincodesListing && (
            <div className="serviceable--pincodes--listing">
              <table className="inventory--table">
                <thead>
                  <tr>
                    {pincodeColumns.map((columnName, columnIndex) => (
                      <th key={columnIndex} className="the-pincodehdr">
                        {columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pincodeRows.map((rowItem, rowIndex) => (
                    <tr key={rowIndex}>
                      {rowItem.map((data, index) => (
                        <td>{data}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
)} */}
      </div>
    </div>
  );
};

export default Inventory;
