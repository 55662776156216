import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { inventoryListUrlApi } from "../../services/apiService";

export const inventoryListAsync = createAsyncThunk(
  'inventory/inventoryList',
  async (payload) => {
    try {
      const response = await inventoryListUrlApi(payload);
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  inventoryList: [],
  error: null
};

const inventoryListSlice = createSlice({
  name: 'inventoryListing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inventoryListAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(inventoryListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.inventoryList = action.payload;
        state.error = null;
      })
      .addCase(inventoryListAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default inventoryListSlice.reducer;
