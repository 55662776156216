import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAsync } from "../../app/store/ordersSlice";
import { preProcessedOrdersAsync } from "../../app/store/preProcessedOrderSlice";
import { orderStatusUpdateAsync } from "../../app/store/updateOrderSlice";
import {
  createOrderAsync,
  selectDbSchema,
} from "../../app/store/createOrderSlice";
import { orderCreateFormAsync } from "../../app/store/createOrderSubmitSlice";
import { oredrstatusFunc } from "../../services/apiService";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Download from "../../images/download.png";
import detailImage from "../../images/File Preview.png";
import SortLeft from "../../images/Sort Left.png";
import SortRight from "../../images/Sort Right.png";
import SearchIcon from "../../images/search.png";
import dropdownIcon from "../../images/dropdownanchor.png";
import { DatePicker } from "antd";
import Tabspane from "../../components/UIComponents/Tabs/CustomTabs";
import Tabs from "../../components/UIComponents/Tabs/CustomTabs";

import { FaPrint, FaShoppingCart, FaEye } from "react-icons/fa";
import { Table } from "react-bootstrap";
import "./Orders.css";
import CustomTabs from "../../components/UIComponents/Tabs/CustomTabs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedValuee, setSelectedValuee] = useState('');
  const [selectedOption, setSelectedOption] = useState('orders');

  const [poppedValue, setPoppedValue] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // const { columnNames, rows, statusData, status, error } = useSelector(
  //   (state) => state.orders
  // );
  // const { preProcessedcolumnNames, preProcessedrows, preProcessedstatusData } = useSelector(
  //   (state) => state.preeProccessedOrders
  // );
  const { columnNames, rows, statusData, status, error } = useSelector((state) => {
    if (selectedOption === 'orders') {
      return {
        columnNames: state.orders.columnNames,
        rows: state.orders.rows,
        statusData: state.orders.statusData,
        status: state.orders.status,
        error: state.orders.error,
      };
    } else if (selectedOption === 'preProcessedOrders') {
      return {
        columnNames: state.preeProccessedOrders.preProcessedcolumnNames,
        rows: state.preeProccessedOrders.preProcessedrows,
        statusData: state.preeProccessedOrders.preProcessedstatusData,
        status: state.preeProccessedOrders.status,
        error: state.preeProccessedOrders.error,
      };
    }
    return { columnNames: [], rows: [], statusData: [], status: 'idle', error: null };
  });
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const pageCountChange = (e) => {
    const selectedvalue = parseInt(e.target.value);
    setCurrentPage(1);
    setRecordsPerPage(selectedvalue);
  };
  const getColor = (value) => {
    switch (value) {
      case "InProcess":
        return "#0AC9D8";
      case "OrderPlaced":
        return "#3D4358";
      case "OrderFulfillment":
        return "#07932E";
      case "OutForDelivery":
        return "red";
      default:
        return "black";
    }
  };
  // Records to be displayed on the current page
  const currentRecords = rows.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(rows.length / recordsPerPage);

  const page_no = 1; // Set the desired page number
  const per_page = 4; // Set the desired number of items per page
  const handlePageClick = (pageNumber) => {
    // Handle the click of a specific page number
    setCurrentPage(pageNumber);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
   
  };

  const handleNextPage = () => {
    if (currentPage < nPages) {
      // Check if currentPage is less than the total number of pages
      setCurrentPage(currentPage + 1); // Increment the currentPage
    }
   
  };



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ordersListChange = (e) => {
    setSelectedValuee(e.target.value); // Update state with selected value
    console.log('Selected value:', selectedValuee); // Log selected value to console
  };
  const renderPageNumbers = () => {
    const pageNumbers = Array.from({ length: nPages }, (_, index) => index + 1);

    return (
      <ul className="pagination pag-div">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="pagination--btn"
        >
          <img className="leftIcon" src={SortLeft} alt="" />
        </button>
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={pageNumber === currentPage ? "active" : "normal"}
            onClick={() => handlePageClick(pageNumber)}
            style={{
              borderRadius: "5px",
              border: "none",
              width: "22px",
              margin: "5px 6px 10px 11px",
              padding: "0px",
              textAlign: "center",
              height: "22px",
              background: "#0AC9D8",
              color: "#fff",
            }}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={handleNextPage}
          disabled={currentPage === nPages}
          className="pagination--btn"
          style={{ position: "relative" }}
        >
          <img className="rightIcon" src={SortRight} alt="" />
        </button>
      </ul>
    );
  };
  /***pagination code ends */
  const dispatch = useDispatch();
  const schemaData = useSelector(selectDbSchema);
 
  // const { columnNames, rows, statusData, status, error } = useSelector(
  //   (state) => state.orders
  // );
  

  useEffect(() => {
    dispatch(fetchOrdersAsync({ page_no, per_page }));
  }, [dispatch, page_no, per_page]);

  useEffect(()=>{
    dispatch(preProcessedOrdersAsync({ page_no, per_page }))
  },[dispatch, page_no, per_page])
  const {
    status: createOrderStatus,
    error: createOrderError,
    data: createOrderData,
  } = useSelector((state) => state.createOrder);

  /*****status dropdown code */
  const [selectedStatuses, setSelectedStatuses] = useState({}); // Keep track of selected statuses for each row
  const handleStatusChange = (e, rowIndex) => {
    setSelectedStatus(e.target.value);
    setOpenModal(true); // Display the confirmation modal
  };
  const handleCloseModal = async (confirm) => {
    setOpenModal(false); // Close the modal
    dispatch(fetchOrdersAsync());
    if (confirm) {
      // const selectedRow = rows[selectedIndex];
      // const orderId = selectedRow[0];
      // try {
      //   // Call the API with the selected status
      //   const response = await oredrstatusFunc(selectedStatus,orderId);
      //   // Handle the response here if needed
      // } catch (error) {
      //   // Handle errors
      //   console.error('Error updating order status:', error);
      // }
    }
  };

  const transformColumnName = (columnName) => {
    return columnName
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleEyeIconClick = (row) => {
    // Implement the desired action when the eye icon is clicked
    
  };
  const renderForm = (form) => {
    if (!form) return null;
    /******Generating Form Dynamically */

    // Function to generate random dummy data based on field type
    const generateDummyData = (fieldType) => {
      switch (fieldType) {
        case "text":
          return "Dummy Text";
        case "datetime-local":
          return "2023-10-17T12:00"; // Change this to a valid datetime
        case "float":
          return "10.5"; // Change this to a valid float value
        case "textarea":
          return "Dummy Textarea";
        case "email":
          return "dummy@example.com";
        case "numbertext":
          return "12345";
        default:
          return "";
      }
    };

    // Function to automatically fill the form with dummy data
    const fillFormWithDummyData = () => {
      const form = document.querySelector("form");

      if (form) {
        form.querySelectorAll("input, textarea").forEach((input) => {
          const fieldName = input.getAttribute("name");
          if (fieldName && form.order_form && form.order_form[fieldName]) {
            const fieldType = form.order_form[fieldName].type;
            const dummyValue = generateDummyData(fieldType);
            input.value = dummyValue;
          }
        });
      }
    };

    // Call this function to fill the form with dummy data
    fillFormWithDummyData();

    const lineItemFields = form.line_items;
   
    const handleSubmit = async () => {
      const formData = new FormData(document.querySelector("form")); // Get form data
      const formValues = {
        line_items: [], // Initialize line_items as an array
      };

      // Iterate through form data and process line_items separately
      formData.forEach((value, key) => {
        if (key.startsWith("line_items[")) {
          const itemKey = key.match(/line_items\[(.*?)\]/)[1]; // Extract the key inside []
          const currentItem =
            formValues.line_items[formValues.line_items.length - 1];

          if (!currentItem || currentItem.hasOwnProperty(itemKey)) {
            // If no current item or item key already exists, create a new item
            formValues.line_items.push({ [itemKey]: value });
          } else {
            // Update the current item with the new property
            currentItem[itemKey] = value;
          }
        } else {
          formValues[key] = value;
        }
      });

      const payload = {
        data: formValues, // Wrap formValues in an array
        db_schema: schemaData,
      };

     

      try {
        const response = await axios.post(
          "http://192.168.0.66:8000/api/v1/create/orders",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
       
        alert(JSON.stringify(response.data.message, null, 2));
      } catch (error) {
       
      }
      dispatch(orderCreateFormAsync());
    };

    return (
      <form className=" row">
        {Object.keys(form.order_form).map((fieldName) => {
          const field = form.order_form[fieldName];

          return (
            <div key={fieldName} className="form-group col-md-6">
              <label htmlFor={fieldName}>
                {transformColumnName(fieldName)}
              </label>
              {field.type === "text" && (
                <input
                  type="text"
                  id={fieldName}
                  name={fieldName}
                  maxLength={field.maxlength}
                />
              )}
              {field.type === "datetime-local" && (
                <input type="datetime-local" id={fieldName} name={fieldName} />
              )}
              {field.type === "float" && (
                <input
                  type="number"
                  id={fieldName}
                  name={fieldName}
                  min={field.min || 0}
                  max={field.max || 1000}
                  step={field.step || 0.01}
                />
              )}
              {field.type === "textarea" && (
                <textarea
                  id={fieldName}
                  name={fieldName}
                  maxLength={field.maxlength}
                />
              )}
              {field.type === "email" && (
                <input type="email" id={fieldName} name={fieldName} />
              )}
              {field.type === "numbertext" && (
                <input
                  type="text"
                  id={fieldName}
                  name={fieldName}
                  maxLength={field.maxlength}
                  pattern="[0-9]*"
                />
              )}
            </div>
          );
        })}

        {/* Table for line_items */}

        <table>
          <thead>
            <tr>
              {Object.keys(form.order_form.line_items).map((key) => (
                <th key={key}>{transformColumnName(key)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.keys(form.order_form.line_items).map((key) => (
                <td key={key}>
                  {form.order_form.line_items[key].type === "number" && (
                    <input
                      type="number"
                      name={`line_items[${key}]`} // Add name attribute
                    />
                  )}
                  {form.order_form.line_items[key].type === "text" && (
                    <input
                      type="text"
                      name={`line_items[${key}]`} // Add name attribute
                    />
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>

        <button type="button" onClick={handleSubmit} className="sbmit--btn">
          Submit
        </button>
      </form>
    );
  };

  
  return (
    <div>
      <div
        className="row orders--row filters--row"
        style={{ marginTop: "3%", display: "none" }}
      >
        <div className="col-md-2">
          <h2>Orders</h2>
        </div>
        <div className="col-md-7"></div>
        <div className="col-md-1">
          <Button
            variant="contained"
            className="print-button"
            startIcon={<FaPrint />}
          >
            PRINT
          </Button>
        </div>
        <div className="col-md-2">
          <Button
            variant="contained"
            className="create-order-button"
            startIcon={<FaShoppingCart />}
            onClick={() => {
              handleClickOpen();
              dispatch(createOrderAsync());
            }}
          >
            CREATE ORDER
          </Button>

          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <h5>Orders/Create Order</h5>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette[500],
              }}
            ></IconButton>
            <DialogContent dividers>
              {renderForm(createOrderData)}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Save changes
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>

      {status === "succeeded" && rows.length > 0 && (
        // <div className="table-container">
        //   <Table striped hover>
        //     <thead>
        //       <tr>
        //         {columnNames.map((header) => (
        //           <th key={header}>{transformColumnName(header)}</th>
        //         ))}

        //         <th  className="eye-icon-center">Action</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {rows.map((row, index) => (
        //         <tr key={index}>
        //           {row.map((cell, cellIndex) => (
        //             <td key={cellIndex}>
        //               {Array.isArray(cell) ? cell.join(", ") : cell}
        //             </td>
        //           ))}

        //           <td className="eye-icon-center">
        //             <FaEye
        //               onClick={() => handleEyeIconClick(row)}
        //               className="eye--icon"
        //             />
        //           </td>
        //         </tr>
        //       ))}
        //     </tbody>
        //   </Table>
        // </div>

        <>
          <div className="row top--spacing">
            <div className="col-md-3">
              <div className="search--container">
                <input
                  type="text"
                  className="input--search"
                  placeholder="Search by Order No. , Email , Name"
                />
                <img src={SearchIcon} alt="" className="search--icon" />
              </div>
            </div>
            <div className="col-md-3">
              <div>
                <select name="" id="" className="brand--dropdown">
                  <option value="Brand">Brand</option>
                </select>
                <img src={dropdownIcon} alt="" className="dropdownIcon" />
                <div className="dropdownIcon"></div>
              </div>
            </div>
            <div className="col-md-2">
              <div>
                <select name="" id="" className="brand--dropdown">
                  <option value="Order In Process">Order In Process</option>
                  <option value="Order Closed">Order Closed</option>
                  <option value="Shipped">Shipped</option>
                </select>
                <img src={dropdownIcon} alt="" className="dropdownIcon" />
              </div>
            </div>
            <div className="col-md-2">
              <DatePicker
                className="datepicker--btn"
                placeholder={["Start Date"]}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                className="datepicker--btn"
                placeholder={["End Data"]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2">
            <select value={selectedOption} onChange={handleOptionChange} className="slt">
      <option value="orders">Orders</option>
      <option value="preProcessedOrders">Pre-Processed Orders</option>
    </select>
            </div>
          </div>
          <div className="table-container orderslisting--table">
            <div className="table--wrapper-scroll">
              <table className="" striped hover>
                <thead>
                  {/* <tr>
                {columnNames.map((header) => (
                  <th key={header}>
                    {transformColumnName(header)}
                    {console.log(header)}
                  </th>
                ))}
                <th className="eye-icon-center">Action</th>
              </tr> */}
                  <tr style={{ background: "#ddd" }}>
                    {columnNames.map((header, index) => {
                      if (index !== 0 && header !== "id") {
                        // Skip the first header and "id" header
                        return (
                          <th key={header}>{transformColumnName(header)}</th>
                        );
                      }
                      return null;
                    })}
                    <th className="eye-icon-center action--icon--td">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {currentRecords.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      // style={{ display: rowIndex === 0 ? "none" : "" }}
                    >
                      {row.slice(0, -1).map((cell, cellIndex) => {
                        if (columnNames[cellIndex] !== "id") {
                          return (
                            <td
                              key={cellIndex}
                              style={{ display: cellIndex === 0 ? "none" : "" }}
                            >
                              {Array.isArray(cell) ? cell.join(", ") : cell}
                            </td>
                          );
                        }
                        return null; // Hide the "id" cell
                      })}
                      <td className="eye-icon-center">
                     
                        <div className="option">
                          
                          <span
                            className="circlee"
                            style={{
                              backgroundColor: getColor( selectedStatuses[rowIndex] || row[row.length - 1]),
                            }}
                          >
                            
                            <span style={{ visibility: "hidden" }}>p</span>
                          </span>
                          <div className="circle-dropdown">
                            <select
                              value={
                                selectedStatuses[rowIndex] ||
                                row[row.length - 1]
                              }
                              onChange={async (e) => {
                                const selectedStatus = e.target.value; // Extract selectedStatus
                                const orderId = row[0]; // Extract orderId
                                handleStatusChange(e, rowIndex);
                             
                                // const poppedValue=selectedStatuses[rowIndex] || row[row.length - 1]
                                setPoppedValue(
                                  selectedStatuses[rowIndex] ||
                                    row[row.length - 1]
                                );
                                // Now, you can use `selectedStatus` and `orderId` in the API call
                                try {
                                  // Call the API with the selected status and orderId
                                  const response = await oredrstatusFunc(
                                    selectedStatus,
                                    orderId
                                  );
                                  // Handle the response here if needed
                                } catch (error) {
                                  // Handle errors
                                  console.error(
                                    "Error updating order status:",
                                    error
                                  );
                                }
                              }}
                            >
                              {statusData.map((statusValue) => (
                                <option key={statusValue} value={statusValue}>
                                  {statusValue}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* {statusData.map((statusValue) => (
                            
                            <option key={statusValue} value={statusValue}>
                              {console.log(statusValue)}
                              {statusValue}
                            </option>
                          ))} */}
                        {/* </div> */}

                        <BootstrapDialog
                          onClose={handleCloseModal}
                          open={openModal}
                          aria-labelledby="customized-dialog-title"
                        >
                          <DialogTitle
                            sx={{ m: 0, p: 2 }}
                            id="customized-dialog-title"
                          >
                            <h5 style={{fontSize: "14px",fontWeight: "900",fontFamily: 'AvenirLTStd-Book'}}>Order Status </h5>
                          </DialogTitle>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette[500],
                            }}
                          ></IconButton>
                          <DialogContent dividers>
                            <p>
                             <span style={{fontSize: "14px",fontWeight: "900",fontFamily: 'AvenirLTStd-Book'}}> Do you want to change the status from</span>{" "}
                              <b style={{fontSize:"14px",fontFamily: 'AvenirLTStd-Book'}}>{poppedValue}</b> to <b style={{fontSize:"14px",fontFamily: 'AvenirLTStd-Book'}}>{selectedStatus}</b> ?
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              autoFocus
                              onClick={() => {
                                handleCloseModal(true);
                                // window.location.reload();
                              }}
                              className="yes--bt"
                            >
                              Yes
                            </Button>
                            <Button
                              autoFocus
                              onClick={() => handleCloseModal(false)}
                               className="yes--bt"
                            >
                              No
                            </Button>
                          </DialogActions>
                        </BootstrapDialog>
                      </td>
                      <td className="eye-icon-center">
                        <Link
                          to={`/orderdetails?orderId=${row[0]}`}
                          className="custom-link"
                        >
                          <img
                            src={detailImage}
                            alt=""
                            onClick={() => handleEyeIconClick(row)}
                          />
                          {/* <FaEye
                       
                        className="eye--icon"
                      /> */}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* Pagination controls */}
              </table>
            </div>

            <div className="row tablee--bottom">
              <div className="col-md-5">
                <button className="order--listing--downloadbtn">
                  {" "}
                  <span>
                    <img src={Download} alt="" />
                  </span>{" "}
                  Download
                </button>
              </div>
              <div className="col-md-2">{renderPageNumbers()}</div>
              <div className="col-md-5">
                <select
                  name=""
                  id=""
                  onChange={pageCountChange}
                  className="orders--count--btn"
                  value={recordsPerPage}
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>150</option>
                </select>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    //   )}

    // </div>
  );
};

export default Orders;
