
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteBulkPincodesApi } from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const deleteBulkPincodesApiAsync = createAsyncThunk(
  'deleteBulkPincodes/deleteBulkPincodesApi',
  async (excelFile) => {
    try {
      const response = await deleteBulkPincodesApi(excelFile);
      console.log(response.data.message);
      // toast.success(` ${response.data.message}`); 
      toast.success("Pincode Deleted Successfully.")
      return response;
    } catch (error) {
      throw error;
    }
  }
);


const initialState = {
    loading: false,
    error: null,
    message: null,
  };

const deleteBulkPincodesSlice = createSlice({
  name: 'deleteBulkPincodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteBulkPincodesApiAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBulkPincodesApiAsync.fulfilled, (state,action) => {
        state.loading = false;
      state.message = action.payload.message || action.payload.data;
        state.error = null;
      })
      .addCase(deleteBulkPincodesApiAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer
export default deleteBulkPincodesSlice.reducer;
