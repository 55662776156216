import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaCross, FaEye, FaPen, FaPlus, FaSearch, FaTimes } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { warehouseDetailsAsync } from "../../app/store/warehouseDetailsSlice";
import { serviceabilityWarehouseListAsync } from "../../app/store/serviceabilityWarehouseListing";
import { addbulkPincodeAsync } from "../../app/store/addbulkPincodesSlice";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import "./Serviceability.css";
import { addpincodeFormApiAsync } from "../../app/store/addPincodeSlice";
import BackIcon from "../../../src/images/backicon.png";
import { bulkPincodeAsync } from "../../app/store/pincodesBulkUploadSlice";
import { addpriorityApiAsync } from "../../app/store/addPrioritySlice";
import { pincodesListingAsync } from "../../app/store/pincodesListingSlice";
import { warehouseinputsearchSuggestionListAsync } from "../../app/store/serviceabilitywarehouseSearchInputSuggestionSlice";
import Select from "react-select";
import { exportPincodesApiAsync } from "../../app/store/exportbulkpincodeSlice";
import { FaCheck } from "react-icons/fa";
import { deletepincodeFormApiAsync } from "../../app/store/deletePincodefromBulkSlice";
import { deleteBulkPincodesApiAsync } from "../../app/store/deletebulkUploadSlice";
// import { Button, Modal } from "react-bootstrap";
import { downloadTemplateApiAsync } from "../../app/store/downloadTemplateSlice";
import Button from "../../components/UIComponents/Button/Button";
import Popup from "../../components/UIComponents/Popup/Popup";
import { serviceabilityPincodesListingAsync } from "../../app/store/serviceabilityPincodesListingSlice";
import { serviceabilityListPincodeWarehouseAsync } from "../../app/store/serviceabilityPincodeWarehouseListSlice";
const Serviceability = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backFunc = () => {
    navigate("/configurations");
  };

  const fileInputRef = useRef(null);
  const fileInpRef = useRef(null);
  const [getrow, setGetRow] = useState();
  const [showPincodesListing, setPincodesListing] = useState(true);
  const [deleteOption, setDeleteOption] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [bulkdeletOption, setBulkDeleteOption] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [exportButton, setExportButton] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const[hideRow,setHideRow] =useState(true)
  const [checkedRows, setCheckedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [addbulkTable, setAddBulkTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showpincodesExcel, setShowPincodesExcel] = useState(true);
  const [selectedWarehouseName, setSelectedWarehouseName] = useState("");
  const [selectedWarehousePriority, setSelectedWarehousePriority] =
    useState(null);
  const [selectedStorehousePriority, setSelectedStorehousePriority] =
    useState(null);
  const [selectedDarkhousePriority, setSelectedDarkhousePriority] =
    useState(null);
  const [exportingPincodes, setExportPincodes] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [enableWarehousesfordropdown, setEnableWarehousesfordropdown] =
    useState(false);
  const [getIndividualrow, setGetIndividualRow] = useState();
  const [addpincodeForm, setAddPincodeForm] = useState({
    pincode: "",
    city: "",
    state: "",
    country: "",
    warehouse_id: "",
    warehouse_name: "",
    warehouse_priority: "",
    storehouse_priority: "",
    darkhouse_priority: "",
  });
  const { status, pincodedata, error } = useSelector(
    (state) => state.addbulkpincodes
  );
 
  const suggestionList = useSelector(
    (state) => state.warehouseinputsearch.suggestionList.warehouses
  );
  const warehouseList = useSelector(
    (state) => state.warehouseslistdropdown.listpincodeswarehouses
  );
  console.log(warehouseList);
  // const getPincode = ()=> (rowIndex, pincode) => {
  //   console.log("Pincode:", pincode);
  //   console.log("Warehouse Name:", selectedWarehouseName);
  //   const pincodee = editableRows[rowIndex][0];
  //   console.log("jjs",pincodee)
  // }
  // getPincode()

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };
  const handleFileSelectionChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };
  const serviceabilityWarehouses = useSelector(
    (state) => state.serviceabilitywarehouselist.serviceabilitywarehouselisting
  );
  console.log(serviceabilityWarehouses);

  const getWarehouseOptions = () => {
    return suggestionList.map((value) => ({ value, label: value }));
  };

  const handleWarehouseChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const selectedValues = selectedOptions.map((option) => option.value);
      setSelectedWarehouse(selectedValues);
    } else {
      setSelectedWarehouse([]);
      // Handle case when no option is selected
    }
  };
  const handleSwapPriority = (rowIndex, index1, index2) => {
    // Ensure rowIndex is within bounds
    if (rowIndex < 0 || rowIndex >= editableRows.length) return;

    // Ensure index1 and index2 are within bounds of the Fulfillment Center Priority array
    const fcPriorityIndex = pincodedata.columns.indexOf(
      "Fulfillment Center Priority"
    );
    const fcPriority = editableRows[rowIndex][fcPriorityIndex];
    if (
      index1 < 0 ||
      index1 >= fcPriority.length ||
      index2 < 0 ||
      index2 >= fcPriority.length
    )
      return;

    // Create a new array with swapped elements
    const newFcPriority = [...fcPriority];
    const temp = newFcPriority[index1];
    newFcPriority[index1] = newFcPriority[index2];
    newFcPriority[index2] = temp;

    // Update the state with the new array
    const updatedRows = [...editableRows];
    updatedRows[rowIndex][fcPriorityIndex] = newFcPriority;
    setEditableRows(updatedRows);
    console.log(updatedRows[rowIndex]);
    console.log("teja",updatedRows)
    console.log("pincode", updatedRows[rowIndex].pincode);
    console.log("ware", selectedWarehouse);

    dispatch(
      addpriorityApiAsync({
        pincode: updatedRows[rowIndex][0],
        city: updatedRows[rowIndex][1],
        state: updatedRows[rowIndex][2],
        country: updatedRows[rowIndex][3],
        warehouse_ids: "", // Update with the actual value
        warehouse_names: selectedWarehouse, // Use selectedWarehouse state variable
        priority_1: newFcPriority[0],
        priority_2: newFcPriority[1],
        priority_3: newFcPriority[2],
      })
    );
  };

  const handlePriorityButtonClick = (rowIndex, priorityIndex) => {
    // Logic to handle priority button click
    console.log(
      `Priority button clicked for row ${rowIndex} and priority ${priorityIndex}`
    );

    // Call handleSwapPriority to swap priorities when the button is clicked
    // For example, you can swap the priority at priorityIndex with the adjacent priority
    handleSwapPriority(rowIndex, priorityIndex, priorityIndex + 1);
  };

  /****enabling delete option ****/

  // const checkBoxChecked = (pincode) => {
  //   setExportPincodes((prevPincodes) => [...prevPincodes, String(pincode)]);
  //   // Check if any checkboxes are checked
  //   const anyCheckboxChecked = editableRows.some((row) => row[0]); // Assuming the first cell in each row corresponds to the checkbox
  //   setDeleteOption(anyCheckboxChecked);
  //   setExportButton(anyCheckboxChecked);
  // };

  const checkBoxChecked = (row, isChecked, isForDeletion) => {
    const pincode = row[0]; // Keep the pincode for existing functionality

    const pincodeValue = row[0];

    console.log(getrow);
    const getRowData = row;
    // console.log(row);
    setGetIndividualRow(getRowData);

    if (!isForDeletion) {
      // Existing functionality for exporting pin codes
      setExportPincodes((prevPincodes) => {
        if (isChecked) {
          return [...prevPincodes, String(pincode)];
        } else {
          return prevPincodes.filter((code) => code !== String(pincode));
        }
      });
    } else {
      // New functionality for handling deletion
      const updatedCheckedRows = isChecked
        ? [...checkedRows, pincode]
        : checkedRows.filter((code) => code !== pincode);

      setCheckedRows(updatedCheckedRows);

      // Set delete option
      const anyCheckboxChecked = updatedCheckedRows.length > 0;
      setDeleteOption(anyCheckboxChecked);
      setExportButton(anyCheckboxChecked);
      setBulkDeleteOption(anyCheckboxChecked);
      // Log the selected row data
      // if (getrow !== null) {

      // }
    }
  };

  //   const checkBoxChecked = (pincode) => {
  // setDeleteOption(true);
  // setExportButton(true);
  // setExportPincodes((prevPincodes) => [...prevPincodes, String(pincode)]);
  // };

  const exportPincodes = () => {
    console.log(rows);

    dispatch(
      exportPincodesApiAsync({
        pincodes: checkedRows,
      })
    );
  };

  // const getWarehouseOptions = () => {
  //   const warehouseValues = suggestionList; // Use the suggestionList instead of pincodedata.rows

  //   const filteredOptions = warehouseValues
  //     .filter((value) => typeof value === 'string' && value && value.toLowerCase().includes(searchTerm.toLowerCase()))
  //     .map((value) => ({ value, label: value }));

  //   return filteredOptions;
  // };

  const handleAddBulkClick = () => {
    setPincodesListing(false);
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });
      dispatch(addbulkPincodeAsync({ files: selectedFiles }));
    } else {
      console.log("Please select a file.");
      // Handle the case when no file is selected
    }
    setShowModal(true);
    // setShowTable(false);
    setShow(false)
  };
  const columns = useSelector((state) => state.pincodesListing.columnNames);
  console.log(columns);
  const rows = useSelector((state) => state.pincodesListing.rows);
  console.log(rows);
  // const getWarehouseList = () => {
  //   if (!warehouseList) {
  //     return [];
  //   }
  //   return warehouseList.map((value) => ({ value, label: value }));
  // };
  //Serviceability Pincodes Listing

  const pincodeColumns = useSelector(
    (state) => state.serviceabilityPincodesListing.columnNames
  );
  console.log(pincodeColumns);
  const pincodeRows = useSelector(
    (state) => state.serviceabilityPincodesListing.rows
  );
  console.log(pincodeRows);
  const [editableRows, setEditableRows] = useState(() =>
    pincodeRows.map((row) => [...row])
   );
 
  const clickBulk = () => {
    setShowModal(true);
  };
  useEffect(() => {
    dispatch(
      pincodesListingAsync({
        query: {
          page_no: 1,
          per_page: 100,
        },
      })
    );
    dispatch(serviceabilityWarehouseListAsync());
    dispatch(
      warehouseinputsearchSuggestionListAsync({ query: { search: searchTerm } })
    );
    // dispatch(
    //   warehouseDetailsAsync({
    //     query: {
    //       page_no: 1,
    //       per_page: 50,
    //       id: "6f53d682-6517-4316-a068-d9d5fe8f70b0",
    //     },
    //   })
    // );
    setEditableRows(pincodeRows.map((row) => [...row]));
  }, [pincodedata, searchTerm]);

  useEffect(() => {
    dispatch(
      serviceabilityPincodesListingAsync({
        query: {
          page_no: 1,
          per_page: 100,
        },
      })
    );
  }, []);
  
  const handleInputChange = (selectedOptions, value, rowIndex, cellIndex) => {
    const pincode = rows[rowIndex][0];
    console.log(pincode);
    const updatedRows = editableRows.map((row) => [...row]);
    updatedRows[rowIndex][cellIndex] = value;
    setEditableRows(updatedRows);
    const selectedWarehouse = selectedOptions.map((option) => option.value);
    setSelectedWarehouseName(selectedWarehouse);
    console.log(
      `Input value changed at row ${rowIndex}, column ${cellIndex}:`,
      value
    );
  };

  const renderCell = (cell) => {
    if (typeof cell === "object" && cell !== null) {
      return Object.keys(cell).map((key) => (
        <div key={key}>
          {key}: {cell[key]}
        </div>
      ));
    }
    return cell;
  };

  const handlerowClick = (id) => {
    navigate("/warehouse");
    localStorage.setItem("id", id);

    console.log("Clicked row id:", id);
    // Add any additional logic you want to perform when a row is clicked
  };

  const addNewRow = () => {
    setShowPincodesExcel(false);
    setShowTable(true);
    setAddBulkTable(false);
    const newRow = {
      Pincode: "",
      City: "",
      State: "",
      Country: "",
      Warehouse_id: "",
      warehouse_priority: "",
      storehouse_priority: "",
      darkhouse_priority: "",
    };
    setTableData([...tableData, newRow]);
  };

  // const rowInputChange = (e, rowIndex, columnName) => {

  //   const updatedData = [...tableData];
  //   updatedData[rowIndex][columnName] = e.target.value;
  //   setTableData(updatedData);
  // };

  // const handleDeleteRow = (rowIndex) => {
  //   dispatch(deletepincodeFormApiAsync(
  //     {
  //       pincode:"111161",
  //       warehouse_id:"",
  //       warehouse_name:"mfnbhalnuaynbwnmk"

  //   }))
  //   const updatedRows = editableRows.filter((_, index) => index !== rowIndex);
  //   setEditableRows(updatedRows);
  // };
  const handleDeleteRow = (rowIndex, pincode) => {
    console.log("Pincode:", pincode);
    console.log("Warehouse Name:", selectedWarehouseName);
    const pincodee = editableRows[rowIndex][0];
    dispatch(
      deletepincodeFormApiAsync({
        pincode: pincodee,
        warehouse_ids: "", // Assuming you don't need the warehouse ID for deletion
        warehouse_names: selectedWarehouseName,
      })
    );

    const updatedRows = editableRows.filter((_, index) => index !== rowIndex);
    setEditableRows(updatedRows);
  };

  const rowInputChange = (e) => {
    const { name, value } = e.target;
    setAddPincodeForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDropdownChange = (value, fieldName) => {
    setSelectedPriority(value);
    setAddPincodeForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const handleWarehouseChangeforAddPincode = (selectedValues) => {
    // Assuming selectedOptions is an array of selected values

    setAddPincodeForm({ ...addpincodeForm, warehouse_name: selectedValues });
  };

  const priorityOptions = [
    { value: "w", label: "Warehouse" },
    { value: "s", label: "Storehouse" },
    { value: "d", label: "Darkhouse" },
  ];


  const addPincode = () => {
    // Convert selectedWarehouse to an array if it's not already and it's not empty
    const warehouseNames =
      selectedWarehouse && selectedWarehouse.length > 0
        ? selectedWarehouse.map((option) => option.value)
        : [];

    dispatch(
      addpincodeFormApiAsync({
        pincode: addpincodeForm.pincode,
        city: addpincodeForm.city,
        state: addpincodeForm.state,
        country: addpincodeForm.country,
        warehouse_ids: [],
        warehouse_names: addpincodeForm.warehouse_name,
        priority_1: selectedWarehousePriority
          ? selectedWarehousePriority.value
          : "",
        priority_2: selectedStorehousePriority
          ? selectedStorehousePriority.value
          : "",
        priority_3: selectedDarkhousePriority
          ? selectedDarkhousePriority.value
          : "",
      })
    );
  };
  const hideRowFun = () =>{
    setShowTable(false);
    // console.log('deleted')
  }
  // Dispatch your action with the payload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the selected file (e.g., validate file type, size, etc.)
      // Dispatch the action with the file data
      dispatch(deleteBulkPincodesApiAsync(file));
    }
    dispatch(addbulkPincodeAsync({ files: selectedFiles }));
  };

  const handleBulkDelete = () => {
    // Trigger the file input's click event to open the file explorer dialog
    fileInputRef.current.click();
  };
  const handleClose = () => setShowModal(false);

  const handleFileInputChange = (event) => {
    // const file = event.target.files[0];
    // if (file) {
    //   fileInpRef.current.value = file.name;
    // } else {
    //   // Clear the input field if no file is selected
    //   fileInpRef.current.value = "";
    // }
  };
  const message = useSelector((state) => state.downloadTemplate.message);
  const downloadTemplate = () => {
    dispatch(downloadTemplateApiAsync());
  };

  const enableaddingWarehousesForPincodes = () => {
    setEnableWarehousesfordropdown(true);
    // setEnableWarehousesfordropdown(prevState => !prevState);
  };
  const addingWarehousesForPincodes = () => {
   

    dispatch(
      addpincodeFormApiAsync({
        pincode: getIndividualrow[0],
        city: getIndividualrow[1],
        state: getIndividualrow[2],
        country: getIndividualrow[3],
        warehouse_ids: [],
        warehouse_names: selectedWarehouse,
        priority_1: getIndividualrow[5][0],
        priority_2: getIndividualrow[5][1],
        priority_3: getIndividualrow[5][2],
      })
    );
    
   
    
  };
  return (
    <>
      <div className=" first--row-">
        <span className="back--para">
          <img className="back-icon" src={BackIcon} alt="" onClick={backFunc} />{" "}
          <p className="back-p">Back</p>
        </span>
        <p className="add-fulfillment-para">Serviceability</p>

        <Link to={`/serviceabilitydetails`} className="custom-link">
          {/* <button className="btn btn-info add--serviceability">
            Add Serviceability
          </button> */}
        </Link>
        {/* <form> */}
          <div className="row">
            <div className="col-md-4">
            <Inputsearch placeholder="Search by Pincode,City.." />
            </div>
           <div className="col-md-4">

           </div>

         

            <div className="col-md-2">
              {/* <input
                type="submit"
                value="Search"
                className="submit-search--serviceability shadow-none"
                style={{ width: "59%", float: "right" ,marginTop:'0px'}}
              /> */}
            </div>
            <div className="col-md-2">
            <Button
                    onClick={() => setShow(true)}
                    children="Bulk Upload"
                    className="serviceabilty-bulk--upload ma-ht"
                    
                  />
            </div>
          </div>
        {/* </form> */}
      </div>
      <div className="serviceability-crd">
        <div className="card-body">
          <div className="row">
            <div className="col-md-7"></div>
            <div className="col-md-5">
              {/* <label htmlFor="fileInput" className="btn btn-primary">

                Browse Files
                <input
                  type="file"
                  id="fileInput"
                 
                  multiple
                  style={{ display: "none" }}
                />
              </label> */}
              <div className="row">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <div className="col-md-3">

                </div>
                <div className="col-md-3">
                  {bulkdeletOption && (
                    <button
                      className="btn btn-success mt-2"
                      onClick={handleBulkDelete}
                    >
                      Bulk Delete
                    </button>
                  )}
                </div>
                <div className="col-md-3">
                  {exportButton && (
                    <button
                      className="btn btn-success mt-2"
                      onClick={exportPincodes}
                    >
                      Export
                    </button>
                  )}
                </div>
                <div className="col-md-3">
                  {showPincodesListing ? (
                    <div></div>
                  ) : (
                    <button
                      className="btn btn-success mt-2"
                      onClick={addNewRow}
                    >
                      Add Pincode
                    </button>
                  )}
                </div>
               
                 
                  {/* <Popup
                  show={show}
                  handleClose={() => setShow(false)}
                  handleShow={() => setShow(true)}
                  modalTitle="Modal Demo"
                  onChange={handleFileChange}
                  type="file"
                  id="fileInput"
                  htmlFor="fileInput"
                  onClick={()=>setIsOpen(true)}
                  handleFileChange={handleFileChange}
                  selectedFiles={selectedFiles}
                  handleAddBulkClick={handleAddBulkClick}
                /> */}
                  <Popup
                    show={show}
                    handleClose={() => setShow(false)}
                    handleShow={() => setShow(true)}
                    modalTitle="Modal Demo"
                    onChange={handleFileChange}
                    type="file"
                    id="fileInput"
                    htmlFor="fileInput"
                    onClick={() => setIsOpen(true)}
                    handleFileChange={handleFileChange}
                    selectedFiles={selectedFiles}
                    handleAddBulkClick={handleAddBulkClick}
                    downloadTemplate={downloadTemplate}
                  />
                
              </div>

              {/* {deleteOption && (
                <>
                  <button className="btn btn-success mt-2">Delete</button>
                </>
              )} */}
            </div>
          </div>
          {showTable && (
            <table className="serviceability--master--table">
              <thead>
                <tr>
                  <th>Pincode</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Warehouse</th>
                  <th>Priority 1</th>
                  <th>Priority 2</th>
                  <th>Priority 3</th>
                  <th className="action--mastertable">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      name="pincode"
                      value={addpincodeForm.pincode}
                      className="form-control shadow-none"
                      onChange={(e) => rowInputChange(e, "pincode")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="city"
                      value={addpincodeForm.city}
                      className="form-control shadow-none"
                      onChange={(e) => rowInputChange(e, "city")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="state"
                      value={addpincodeForm.state}
                      className="form-control shadow-none"
                      onChange={(e) => rowInputChange(e, "state")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="country"
                      value={addpincodeForm.country}
                      className="form-control shadow-none"
                      onChange={(e) => rowInputChange(e, "country")}
                    />
                  </td>
                  <td>
                    {/* <input
                      type="text"
                      name="warehouse_id"
                      value={addpincodeForm.warehouse_id}
                      className="form-control"
                      onChange={(e) => rowInputChange(e, "warehouse_id")}
                    /> */}
                    <Select
                      isMulti
                      options={getWarehouseOptions()}
                      onChange={(selectedOptions) => {
                        // Assuming selectedOptions is an array of selected options
                        const selectedValues = selectedOptions.map(
                          (option) => option.value
                        );
                        console.log(selectedValues); // Log the selected values
                        handleWarehouseChangeforAddPincode(selectedValues);
                      }}
                      style={{ width: "160px !important" }}
                    />
                  </td>
                  <td>
                    <Select
                      value={selectedWarehousePriority}
                      options={priorityOptions}
                      onChange={(value) => setSelectedWarehousePriority(value)}
                    />
                  </td>
                  <td>
                    <Select
                      value={selectedStorehousePriority}
                      options={priorityOptions}
                      onChange={(value) => setSelectedStorehousePriority(value)}
                    />
                  </td>
                  <td>
                    <Select
                      value={selectedDarkhousePriority}
                      options={priorityOptions}
                      onChange={(value) => setSelectedDarkhousePriority(value)}
                    />
                  </td>
                  <td>
                    <button onClick={addPincode} className="checkbtn">
                      <FaCheck />
                    </button>
                    <button  className="checkbtn">
                      <FaTimes onClick={hideRowFun}/>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {showPincodesListing ? (
            <div className="serviceable--pincodes--listing">
              <table>
                <thead>
                  <tr>
                    {pincodeColumns.map((columnName, columnIndex) => (
                      <th key={columnIndex} className="the-pincodehdr">
                        {columnName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pincodeRows.map((rowItem, rowIndex) => (
                    <tr key={rowIndex}>
                      {rowItem.map((data, index) => (
                        <td>{data}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ): (
            <div style={{ maxHeight: "350px", overflow: "auto" }}>
              <table
                className=""
                cellspacing="0"
                cellpadding="0"
                border="0"
                width="25"
                style={{ position: "sticky", top: "0", width: "100%" }}
              >
                <thead>
                  {!showPincodesListing && (
                    <tr>
                      <th
                        className="warehouselisting--td serviceability--master-tableheader"
                        style={{
                          width: "3.5%",
                          // visibility: "hidden"
                        }}
                      ></th>

                      {pincodedata.columns.map((header, index) => (
                        <th
                          className="warehouselisting--td serviceability--master-tableheader"
                          key={index}
                        >
                          {header}
                        </th>
                      ))}
                      {
                        <th
                          className="warehouselisting--td serviceability--master-tableheader"
                          style={{ width: "6.5%"}}
                        >
                          Action
                        </th>
                      }
                    </tr>
                  )}
                </thead>
                <tbody>
                  {editableRows.map((row, rowIndex) => {
                    const fcPriorityIndex = pincodedata.columns.indexOf(
                      "Fulfillment Center Priority"
                    );

                    const fcPriorityRow = row[fcPriorityIndex];

                    const hasFcPriorityValues =
                      Array.isArray(fcPriorityRow) && fcPriorityRow.length > 0;

                    return (
                      <tr className="warehouselisting--td" key={rowIndex}>
                        <td>
                          <input
                            type="checkbox"
                            onClick={() =>
                              checkBoxChecked(
                                row, // Pass the entire row object
                                !checkedRows.includes(row[0]),
                                true
                              )
                            }
                          />
                        </td>

                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>
                            {cellIndex ===
                            pincodedata.columns.indexOf("Warehouse") ? (
                              // <Select
                              //   isMulti
                              //   // value={cell.map(option => ({  label: option }))}
                              //   options={cell.map(option => ({ value: option, label: option }))}
                              //   // options={getWarehouseOptions()}
                              //   onChange={(selectedOptions) => {
                              //     handleWarehouseChange(selectedOptions);
                              //     handleInputChange(
                              //       selectedOptions,
                              //       rowIndex,
                              //       cellIndex
                              //     );
                              //   }}
                              //   className="serviceability--masterinput"
                              //   style={{ width: "160px !important" }}
                              //   components={{
                              //     // Hide the multi-value remove cross button
                              //     ClearIndicator: () => null,   // Hide the clear indicator (group cross button)
                              //   }}/>
                              enableWarehousesfordropdown ? (
                                <Select
                                  isMulti
                                  // value={cell.map(option => ({  label: option }))}
                                  // options={cell.map(option => ({ value: option, label: option }))}
                                  options={getWarehouseOptions()}
                                  onChange={(selectedOptions) => {
                                    handleWarehouseChange(selectedOptions);
                                    handleInputChange(
                                      selectedOptions,
                                      rowIndex,
                                      cellIndex
                                    );
                                  }}
                                  className="serviceability--masterinput"
                                  style={{ width: "160px !important" }}
                                  components={{
                                    // Hide the multi-value remove cross button
                                    ClearIndicator: () => null, // Hide the clear indicator (group cross button)
                                  }}
                                />
                              ) : (
                                <Select
                                  isMulti
                                  // value={cell.map(option => ({  label: option }))}
                                  options={cell.map((option) => ({
                                    value: option,
                                    label: option,
                                  }))}
                                  // options={getWarehouseOptions()}
                                  onChange={(selectedOptions) => {
                                    handleWarehouseChange(selectedOptions);
                                    handleInputChange(
                                      selectedOptions,
                                      rowIndex,
                                      cellIndex
                                    );
                                  }}
                                  className="serviceability--masterinput"
                                  style={{ width: "160px !important" }}
                                  components={{
                                    // Hide the multi-value remove cross button
                                    ClearIndicator: () => null, // Hide the clear indicator (group cross button)
                                  }}
                                />
                              )
                            ) : cellIndex ===
                              pincodedata.columns.indexOf("Storehouse") ? (
                              <Select
                                className="serviceability--masterinput"
                                isMulti
                                // options={getStorehouseOptions()} // This line is commented out due to undefined function
                                onChange={(selectedOptions) =>
                                  handleInputChange(
                                    selectedOptions.map(
                                      (option) => option.value
                                    ),
                                    rowIndex,
                                    cellIndex
                                  )
                                }
                                style={{ width: "160px !important" }}
                              />
                            ) : cellIndex ===
                                pincodedata.columns.indexOf(
                                  "Fulfillment Center Priority"
                                ) && hasFcPriorityValues ? (
                              <div style={{ display: "flex" }}>
                                {/* <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "13px",
                                  }}
                                >
                                  <div>Storehouse</div>
                                  <div>Darkhouse</div>
                                </div> */}
                                {fcPriorityRow.map((value, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "12px",
                                      marginRight: "3px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {index ===
                                      Math.floor(fcPriorityRow.length / 2) && ( // Display arrows for the middle element only
                                      <>
                                        <button
                                          className="priority-btn"
                                          onClick={() =>
                                            handleSwapPriority(
                                              rowIndex,
                                              index,
                                              index - 1
                                            )
                                          }
                                        >
                                          &laquo;
                                        </button>
                                        <div>{value}</div>
                                        <button
                                          className="priority-btn"
                                          onClick={() =>
                                            handleSwapPriority(
                                              rowIndex,
                                              index,
                                              index + 1
                                            )
                                          }
                                        >
                                          &raquo;
                                        </button>
                                      </>
                                    )}
                                    {index !==
                                      Math.floor(fcPriorityRow.length / 2) && (
                                      <div>{value}</div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <input
                                type="text"
                                value={cell}
                                className="input-class serviceability--masterinput"
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value,
                                    rowIndex,
                                    cellIndex
                                  )
                                }
                              />
                            )}
                          </td>
                        ))}
                        <td>
                          {checkedRows.includes(row[0]) && (
                            <>
                              <button
                                onClick={() =>
                                  handleDeleteRow(
                                    rowIndex,
                                    row.pincode,
                                    row.warehouse_name
                                  )
                                }
                                style={{ background: "none", border: "none" }}
                              >
                                <FaTrash />
                              </button>
                              <FaPen
                                onClick={enableaddingWarehousesForPincodes}
                              />
                              <FaCheck
                                style={{ marginLeft: "10px" }}
                                onClick={addingWarehousesForPincodes}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        
          

          {/* {status === 'failed' && (
  <p>Error: {error}</p>
)} */}
        </div>
      </div>

      {/* <div className="card first--row" style={{ marginTop: "20px" }}>
        <div style={{ display: "flex" }}>
          <h3>Fulfillment Priorities</h3>
          <button className="add-priority-btn">Add Priority</button>
        </div>
        <div>
          <label htmlFor="">Priority 1 : </label>
          <select name="" id="">
            <option value="">Storehouse</option>
            <option value="">Warehouse</option>
            <option value="">Darkhouse</option>
          </select>
          <label htmlFor="">Priority 2 : </label>
          <select name="" id="">
            <option value="">Storehouse</option>
            <option value="">Warehouse</option>
            <option value="">Darkhouse</option>
          </select>
          <label htmlFor="">Priority 3 : </label>
          <select name="" id="">
            <option value="">Storehouse</option>
            <option value="">Warehouse</option>
            <option value="">Darkhouse</option>
          </select>
          <label htmlFor="">Default : </label>
          <select name="" id="">
            <option value="">Storehouse</option>
            <option value="">Warehouse</option>
            <option value="">Darkhouse</option>
          </select>
        </div>
      </div> */}

      {/* <div className="card first--row">
        <div className="card-body">
          <h3>Pincodes </h3>
          <div className="row" style={{ overflow: "auto", maxHeight: "300px" }}>
            <table className="table">
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

      {/* /****Fulfillment Centre * */}
      {/* <div className="card first--row" style={{ marginTop: "20px" }}>
        <div className="row">
          <div className="col-md-3">
            <h3>Fulfillment Centre</h3>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-3">
            <div className="search-container">
              <input
                type="text"
                className="search-input"
                placeholder="Search..."
              />
            </div>
          </div>
          <div className="col-md-1">
            <Link to={`/warehousecreation`}>
              {" "}
              <button className="add-priority-btn">
                Add Warehouse <FaPlus />{" "}
              </button>
            </Link>
          </div>
        </div>
        <div>
          {serviceabilityWarehouses && (
            <div style={{ overflow: "scroll"}}>
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
                width="25"
                style={{ position: "sticky", top: "0", width: "100%" }}
              >
                <thead>
                  <tr>
                    {serviceabilityWarehouses.columns.map((column, index) => (
                      <th className="warehouselisting--td" key={index}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {serviceabilityWarehouses.rows.map((row, rowIndex) => (
                    <tr key={rowIndex} onClick={() => handlerowClick(row[0])}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className="warehouselisting--td">
                          {renderCell(cell)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div> */}
    </>
  );
};

export default Serviceability;
