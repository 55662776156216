import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
 import {shipmentConfigDetailsApi} from '../../src/services/apiService.js'

 export const shipmentConfigDetailsAsync = createAsyncThunk(
    "shipmentConfig/shipmentConfigDetails",
    async (payload) =>{
        try{
            const response = shipmentConfigDetailsApi(payload)
            return response
        }
        catch (error) {
            console.log("Error while fetching:", error);
            throw error;
          }
    }
 )


 const shipmentConfigDetailsSlice = createSlice({
    name: "shipmentconfigdetails",
    initialState: {
    //   warehouseForm: null,
      shipmentconfigdetailsdata: [],
      status: "idle",
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(shipmentConfigDetailsAsync.pending, (state) => {
          state.status = "loading";
        })
        .addCase(shipmentConfigDetailsAsync.fulfilled, (state, action) => {
          state.status = "succeeded";
        //   state.warehouseForm = action.payload; 
          state.shipmentconfigdetailsdata = action.payload; 
        })
        .addCase(shipmentConfigDetailsAsync.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        });
    },
  });

  export default shipmentConfigDetailsSlice.reducer

 