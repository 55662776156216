import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeCreationAsync } from "../../app/store/storeCreationFormSlice";
import { createStoreAsync } from "../../app/store/createStoreFormSubmitSlice";

import "./CreateStore.css";

const CreateStore = () => {
  const [formDetails, setFormDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storeCreationAsync());
  }, [dispatch]);

  const formData = useSelector((state) => state.storeForm.storeform);

  const handleInput = (event) => {
    const { name, value } = event.target;
    let updatedFormValues = { ...formDetails };
    if (name === 'channel_location_id') {
      updatedFormValues = {
        ...formDetails,
        channel_location_id: {
          Shopify: value
        }
      };
    } else {
      updatedFormValues = {
        ...formDetails,
        [name]: value
      };
    }
    setFormDetails(updatedFormValues);
  };

  const handleCountryChange = (evt) => {
    const { name, value } = evt.target;

    setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createStoreAsync(formDetails));
  };

  return (
    <div>
      <div className="card first--row">
        <h3>Create Store</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            {formData &&
              Object.keys(formData).map((field, fieldIndex) => {
                const fieldVal = formData[field]; // Access the value using field
     
                return (
                  <div className="col-md-3" key={fieldIndex}>
                    <label htmlFor={fieldVal.key}>
                      {field} {/* Display specific property */}
                    </label>

                    {fieldVal.type === "text" && (
                      <input
                        type="text"
                        name={fieldVal.key}
                        className="form-control"
                        onChange={handleInput}
                      />
                    )}
                    {fieldVal.type === "email" && (
                      <input
                        type="email"
                        name={fieldVal.key}
                        className="form-control"
                        onChange={handleInput}
                      />
                    )}
                    {fieldVal.type === "dropdown" && fieldVal.options && (
                      <select
                        className="form-select"
                        id={fieldVal.key}
                        name={fieldVal.key}
                        onChange={handleCountryChange}
                      >
                        <option value="">Select {field}</option>
                        {fieldVal.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                );
              })}
          </div>

          <button type="submit" className="btn btn-outline-primary">
            Create Store
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateStore;
