import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChartLine, FaShoppingCart, FaBook, FaList, FaBox, FaStore } from "react-icons/fa";
import arrowImage from "../../images/Vector.png";
import './Sidebar.css';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isArrowRotated, setIsArrowRotated] = useState(false);

  const handleToggleHeader = () => {
    setIsCollapsed(!isCollapsed);
    setIsArrowRotated(!isArrowRotated);
  };

  return (
    <div>
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <ul className="sidebar--ul">
          <li>
            <Link to="/dashboard" className="custom-link">
              <span className="routes-li " style={{ padding: '10px 15px' }}>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/orders" className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>Orders</span>
            </Link>
          </li>
          <li>
            <Link to="/catalogue" className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>Catalogue</span>
            </Link>
          </li>
          <li>
            <Link to="/inventory" className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>Inventory</span>
            </Link>
          </li>
          <li>
            <Link to='/configurations' className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>Configurations</span>
            </Link>
          </li>
          <li>
            <Link to="/reports" className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>Reports</span>
            </Link>
          </li>
          <li>
            <Link to="/users" className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>Users</span>
            </Link>
          </li>
          <li>
            <Link to="/users" className="custom-link">
              <span className="routes-li" style={{ padding: '10px 15px' }}>TodoList</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className={`toggle--headermenu ${isArrowRotated ? "rotated" : ""}`} onClick={handleToggleHeader}>
        <img src={arrowImage} alt="Toggle Arrow" />
      </div>
    </div>
  );
};

export default Sidebar;
