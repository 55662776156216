import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./ShipmentmethodPopup.css";
import { useDispatch } from "react-redux";
import { shipmentMethodDeletingAsync } from "../../../app/store/shipmentMethodDeleteSlice";

const ShipmentmethodPopup = ({ show, handleClose, selectedShipmentname }) => {
  const dispatch = useDispatch();

  const deleteShipmentMethod = () => {
    dispatch(
      shipmentMethodDeletingAsync({
        name: selectedShipmentname,
      })
    );
    handleClose();
  };
  useEffect(() => {
    shipmentMethodDeletingAsync();
  }, []);
  return (
    <>
      <Modal size="md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="popup--title"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <p className="delete--alert">
                Do you want to delete Shipment Method ?
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md-1">
              <button
                className="delete-shipmentmethod"
                onClick={deleteShipmentMethod}
                onHide={handleClose}
              >
                Yes
              </button>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1">
              <button className="delete-shipmentmethod" onClick={handleClose}>
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShipmentmethodPopup;
