import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addpincodeFormApi} from "../../services/apiService";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const addpincodeFormApiAsync = createAsyncThunk(
  'addPincode/AddPincodeform',
  async (payload) => {
    try {
      const response = await addpincodeFormApi(payload);
      console.log(response);
      toast.success(` ${response.message}`); 
      return response;
    } catch (error) {
      // You can handle errors here or let them propagate
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  addpincode: null,
 
  error: null
};

const addpincodeFormSlice = createSlice({
  name: 'addpincode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addpincodeFormApiAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addpincodeFormApiAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.addpincode = action.payload;
       
        state.error = null;
      })
      .addCase(addpincodeFormApiAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default addpincodeFormSlice.reducer;
