import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { warehouseinputsearchSuggestionListFunc } from "../../services/apiService";

// Async thunk for fetching warehouse input search suggestion list
export const warehouseinputsearchSuggestionListAsync = createAsyncThunk(
  'warehouseinputsearch/SuggestionList',
  async (payload) => {
    try {
      const response = await warehouseinputsearchSuggestionListFunc(payload); // Modify this based on your API
      console.log(response);
      // alert(response)
      return response;
    } catch (error) {
      console.error('Error fetching warehouse input search suggestion list:', error);
      throw error; // Propagate the error for handling in the component
    }
  }
);

// Create a slice for warehouse input search
const warehouseinputsearchSlice = createSlice({
  name: 'warehouseinputsearch',
  initialState: {
    suggestionList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(warehouseinputsearchSuggestionListAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(warehouseinputsearchSuggestionListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestionList = action.payload;
        console.log(state.suggestionList.warehouses)
      })
      .addCase(warehouseinputsearchSuggestionListAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});



// Export the reducer
export default warehouseinputsearchSlice.reducer;
