import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { orderDetailsFunc } from '../../services/apiService';

export const orderDetailAsync = createAsyncThunk('order/orderdetails', async (orderId) => {
  try {
    const response = await orderDetailsFunc(orderId);
    return response.order_details;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
});





const orderDetailsSlice = createSlice({
  name: 'orderdetails',
  initialState: {
    orderDetails: null,
    status: 'idle',
    error: null,
    lineitemStatus: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(orderDetailAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderDetails = action.payload;
      })
      .addCase(orderDetailAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectOrderDetails = (state) => state.orderdetails.orderDetails;
export const selectStatus = (state) => state.orderdetails.status;

export const selectlineItemStatus = (state)=>state.orderdetails.lineitemStatus;
console.log(selectStatus)


export default orderDetailsSlice.reducer;
