// ordersSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { preProcessedOrdersApi } from '../../services/apiService';

export const preProcessedOrdersAsync = createAsyncThunk('preprocessedorders/fetchPreprocessedOrders', async () => {
  try {
    const response = await preProcessedOrdersApi(); // Here Api is calling from apiService.js

    // Extract column names
    const  preProcessedcolumnNames = response.column_names || [];

    // Extract rows
    const  preProcessedrows = response.rows || [];

    // Extract status data
    const statusData = response.status_data || [];

    return { preProcessedcolumnNames, preProcessedrows, statusData };
    
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
});

const preProcessedOrdersSlice = createSlice({
  name: 'preeProccessedOrders',
  initialState: {
    preProcessedcolumnNames: [],
    preProcessedrows: [],
    preProcessedstatusData:[],
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(preProcessedOrdersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(preProcessedOrdersAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.preProcessedcolumnNames = action.payload.preProcessedcolumnNames;
        state.preProcessedrows = action.payload.preProcessedrows;
        state.preProcessedstatusData = action.payload.statusData;
      })
      .addCase(preProcessedOrdersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default preProcessedOrdersSlice.reducer;
