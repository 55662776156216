import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { oredrstatusFunc } from '../../services/apiService';



export const orderStatusUpdateAsync = createAsyncThunk('updatestatus/orderstausupdate',async () => {
    try{
        const response = await oredrstatusFunc(); // Modify this based on your API
    console.log(response)
    alert(response)
    return response
    }
    catch (error) {
        console.error('Error fetching order details:', error);
        throw error;
      }
})
const orderstatusUpdateSlice = createSlice({
    name: 'orderstatusupdate',
    initialState: {
      orderupdateDetails: null,
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(orderStatusUpdateAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(orderStatusUpdateAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.orderupdateDetails = action.payload;
        })
        .addCase(orderStatusUpdateAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });

  export default orderstatusUpdateSlice.reducer;