import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaDownload, FaUpload } from "react-icons/fa";
import "./ShipmentPopup.css";

const Popup = ({
  inputHandleForm,
  handleClose,

  maximum_eta,
  minimum_eta,
  shipmentname,
  units,
  createShipment,

  handleShow,
}) => {
  return (
    <Modal show={handleShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="popup--title">Shipment Creation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="" className="shipmentmethod--label">Shipment Name</label>
            <input
              type="text"
              name="shipmentname"
              value={shipmentname}
              className="form-control shadow-none shipment--inp"
             
              onChange={inputHandleForm}
            />
            </div>
            <div className="col-md-6">
            <label htmlFor="" className="shipmentmethod--label">Minimum ETA</label>
            <input
              type="text"
              name="minimum_eta"
              value={minimum_eta}
              className="form-control shadow-none shipment--inp"
             
              onChange={inputHandleForm}
            />
          </div>
          <div className="col-md-6 fld--mar">
            <label htmlFor="" className="shipmentmethod--label">Maximum ETA</label>
            <input
              type="text"
              name="maximum_eta"
              value={maximum_eta}
              className="form-control shadow-none shipment--inp"
             
              onChange={inputHandleForm}
            />
            </div>
            <div className="col-md-6 fld--mar">
            <label htmlFor="" className="shipmentmethod--label">Units</label>
            <select
              name="units"
              value={units}
              className="form-control shadow-none shipment--inp"
              onChange={inputHandleForm}
            >
               <option value="">Select Value</option>
              <option value="Hours">Hours</option>
              <option value="Days">Days</option>
              <option value="Weeks">Weeks</option>
              <option value="Months">Months</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="shipmentmethod--footer">
        {/* <button variant="secondary" onClick={handleClose}>
          Close
        </button> */}
        <button  onClick={createShipment} className=" mt-4 upload--btn">
          Create Shipment
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
