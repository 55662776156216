import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logingif from "../../images/logingif.gif";
import ricart from "../../images/ricart.png";
import rilogo from "../../images/rilogo.png";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import snowgif from "../../images/snowgif.gif";
import Subtract from "../../images/Subtract.png";
import LoaderImage from "../LoaderImage/LoaderImage";
import Ellipse from "../../images/ellipse.png";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(true);

  const setIconClick = () => {
    setType((prevType) => (prevType === "password" ? "text" : "password"));
    setIcon((prevIcon) => !prevIcon);
  };
  const handleLogin = async () => {
    if (username === "" || password === "") {
      toast(<strong>Please enter Username and Password !</strong>);
    } else {
      try {
        const response = await fetch(
          "https://tri-oms.theretailinsightsdemos.com/oms_login/api/v1/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username,
              password,
            }),
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          console.log("Login Successful");
          navigate("/dashboard");
        } else if (response.status === 401) {
          const errorData = await response.json();
          console.log(errorData);
          toast(<strong>Unauthorized : {errorData.detail}</strong>);
        } else {
          const errorData = await response.json();
          console.error("Login failed:", errorData);
          toast("Login failed: " + errorData.detail);
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  };

  const handleLoginClick = () => {
    handleLogin();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoaderImage />
      ) : (
        <>
          <div
            className="row login-containersec"
            style={{ overflow: "hidden" }}
          >
            <div className="col-md-5">
              <div style={{ position: "relative", overflow: "hidden" }}>
                <img
                  src={Subtract}
                  alt=""
                  className=""
                  style={{ height: "auto", width: "100%" }} // Adjust image size to be responsive
                />
                <img
                  src={snowgif}
                  alt=""
                  className="snowgif-img"
                  style={{
                    position: "absolute",
                    left: "0",
                    width: "100%",
                    height: "87%",
                    zIndex: "0",
                    pointerEvents: "none",
                  }}
                />
                <div className="inner-image">
                  <img className="inner-image" src={Ellipse} alt="" />
                  {/* <div className="triangle"></div> */}
                  <div className="containerr">
                    <img src={ricart} className="image1" alt="ricart" />
                    <img src={rilogo} className="image2" alt="rilogo" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7 login-input-section">
              <div className="login-inputs">
                <div>
                  {" "}
                  {/* White background */}
                  <div className="outer-border">
                    <div className="inner-border">
                      <div className="outer-moving--icon">
                        <img src={logingif} alt="" style={{ height: "39px" }} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-with-icon">
                  <FaUser className="icon" />
                  <div className="vl"></div>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    placeholder="Username"
                    className="input-style"
                    onChange={(event) => setUsername(event.target.value)}
                    required
                  />
                </div>
                <br />
                <div className="input-with-icon">
                  <FaLock className="icon" />
                  <div className="vl"></div>
                  <div className="icon--div">
                    <input
                      type={type}
                      id="password"
                      placeholder="Password"
                      className="input-style"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      required
                    />
                    {icon ? (
                      <FaEyeSlash
                        className="eye-slash"
                        onClick={setIconClick}
                      />
                    ) : (
                      <FaEye className="eye-slash" onClick={setIconClick} />
                    )}

                    {/* {icon} */}
                  </div>
                </div>
                <div className="checkbox-forgot">
                  <label className="remember-me">
                    <input type="checkbox" />
                    <span className="remember-me-text">Remember Me</span>
                  </label>
                  <a href="#" className="forgot-password">
                    Forgot Password?
                  </a>
                </div>
                <button className="login--btn" onClick={handleLoginClick}>
                  Login
                </button>
              </div>
            </div>
          </div>
          <div className="left"></div>
          <div className="right"></div>
          <div className="">{/* Add footer content here */}</div>
        </>
      )}
    </>
  );
};

export default Login;
