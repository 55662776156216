import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { shipmentConfigSearchApi } from "../../services/apiService";

export const shipmentConfigListSearchAsync = createAsyncThunk(
  'shipmentsearch/shipmentssearchList',
  async (payload) => {
    try {
      const response = await shipmentConfigSearchApi(payload);
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  shipmentListSearch: [],
  error: null
};

const shipmentConfigSearchListSlice = createSlice({
  name: 'shipmentConfigListSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(shipmentConfigListSearchAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shipmentConfigListSearchAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.shipmentListSearch = action.payload;
        state.error = null;
      })
      .addCase(shipmentConfigListSearchAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default shipmentConfigSearchListSlice.reducer;
