import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {shipmentMethodListApi} from '../../services/apiService'

export const shipmentMethodListAsync = createAsyncThunk(
    'shipment/ShipmentMethod', // Removed the extra parentheses
    async (payload) => {
      const response = await shipmentMethodListApi(payload);
      console.log(response);
      return response;
    }
  );
  
  

const initialState = {
    loading:false,
    shipmentMethodList:[],
    error:null

}

const shipmentMethodListSlice = createSlice({
    name:'shipmentlist',
    initialState,
    reducers:{},
    extraReducers:(builder) => {
        builder
        .addCase(shipmentMethodListAsync.pending,(state)=>{
            state.loading = true;
            state.error = null;
        })
        .addCase(shipmentMethodListAsync.fulfilled,(state,action)=>{
            state.loading = false;
            state.shipmentMethodList = action.payload
            state.error = null;
        })
        .addCase(shipmentMethodListAsync.rejected,(state,action)=>{
            state.loading = false;
            state.error =action.error.message;
        })
    }


})

export default shipmentMethodListSlice.reducer
