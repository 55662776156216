import React, { useEffect, useState, useCallback } from "react";
import {
  FaPrint,
  FaShoppingCart,
  FaEye,
  FaSearch,
  FaTrash,
  FaCheck,
  FaEdit,
  FaPen,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import "./ShipmentMethods.css";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import Button from "../../components/UIComponents/Button/Button";
import ShipmentPopup from "../../components/UIComponents/ShipmentPopup/ShipmentPopup";
import ShipmentmethodPopup from "../../components/UIComponents/ShipmentmethodPopup/ShipmentmethodPopup";
import { shipmentMethodCreationAsync } from "../../app/store/createShipmentMethodSlice";
import { shipmentMethodListingAsync } from "../../app/store/shipmentmethodListingSlice";
import { shipmentMethodDeletingAsync } from "../../app/store/shipmentMethodDeleteSlice";
import { shipmenMethodUpdateApiAsync } from "../../app/store/shipmentMethodUpdateSlice";
const ShipmentMethods = () => {
  const [editedRowIndex, setEditedRowIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [editShipment, setEditShipment] = useState(false);
  const [selectedShipmentname, setSelectedShipmentName] = useState();
  const [editoption, setEditOption] = useState(true);
  const [formData, setFormData] = useState({
    shipmentname: "",
    minimum_eta: "",
    maximum_eta: "",
    units: "",
  });

  const [editInputValues, setEditInputValues] = useState({
    // name: "",
    // min_eta: "",
    // max_eta: "",
    // units: "",
  }); // New state variable to store edit input values

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const dispatch = useDispatch();
  const columns = useSelector(
    (state) => state.shipmentMethodListing.columnNames
  );
  const rows = useSelector((state) => state.shipmentMethodListing.rows);

  const inputHandleForm = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const debouncedDispatch = useCallback(debounce(dispatch, 300), [dispatch]);

  useEffect(() => {
    debouncedDispatch(
      shipmentMethodListingAsync({
        query: {
          page_no: 1,
          per_page: 50,
        },
      })
    );
  }, []);

  const createShipment = () => {
    dispatch(
      shipmentMethodCreationAsync({
        name: formData.shipmentname,
        min_eta: parseInt(formData.minimum_eta, 10),
        max_eta: parseInt(formData.maximum_eta, 10),
        units: formData.units,
      })
    ).then(() => {
      dispatch(
        shipmentMethodListingAsync({
          query: {
            page_no: 1,
            per_page: 50,
          },
        })
      );
    });
    setShowModal(false);
  };

  const shipmentUpdate = () => {};

  const editShipmentInput = (fieldName, value) => {
    console.log("Editing field:", fieldName);
    console.log("New value:", value);
    setEditInputValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const editShipmentClick = (rowIndex) => {
    setEditedRowIndex(rowIndex);
    setEditShipment(true);
  };
  const updateShipmentClick = (rowIndex, rowData) => {
    setEditedRowIndex(rowIndex);
    // setEditShipment(true);
    console.log("Clicked row data:", rowData);

    // Assuming editInputValues is your state containing edited values
    const editedRowValues = Object.values(editInputValues);

    console.log("editedRowValues length:", editedRowValues.length);
    console.log("editedRowValues:", editedRowValues);

    // Initialize payload object
    const payload = {
      name: rowData[1],
      min_eta: parseInt(editedRowValues[0], 10) || rowData[2],
      max_eta: parseInt(editedRowValues[1], 10) || rowData[3],
      units: editedRowValues[editedRowValues.length - 1] || rowData[4],
    };

    // Dispatch API call with payload
    dispatch(shipmenMethodUpdateApiAsync(payload));
    setEditShipment(false);
    dispatch(
      shipmentMethodListingAsync({
        query: {
          page_no: 1,
          per_page: 50,
        },
      })
    );
  };
  const editClick = () => {
    setEditOption(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <Inputsearch placeholder="Enter Shipment Name.." />
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-2">
          <Button
            onClick={handleShow}
            children="Add Shipment Method"
            className="shipment--method--bulkupload"
          />
        </div>
      </div>
      <div className="shipmentmethod--table-wrapper">
        <table className=" table-container shipment-methods--table">
          <thead>
            <tr>
              {columns.map(
                (columname, index) =>
                  columname !== "Id" && <th key={index}>{columname}</th>
              )}
              <th className="action--td-">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {/* {rowData.slice(1).map((cellData, cellIndex) => (
                  <td key={cellIndex}>
                    {editShipment && editedRowIndex === rowIndex ? (
                      <input
                        key={cellIndex}
                        value={editInputValues[cellData] || rowData.slice(1)[cellIndex]}
                        className="editShipment--input"
                        onChange={(e) => editShipmentInput(cellData,e.target.value)}
                      />
                    ) : (
                      cellData
                    )}
                  </td>
                ))} */}
                {rowData.slice(1).map((cellData, cellIndex) => (
                  <td key={cellIndex}>
                    {editShipment && editedRowIndex === rowIndex ? (
                      cellIndex === 0 ? (
                        <input
                          key={cellIndex}
                          value={cellData}
                          className="editShipment--input"
                          disabled // Disable the input field for the first cell
                          style={{ background: "transparent", border: "none" }}
                        />
                      ) : (
                        <input
                          key={cellIndex}
                          value={editInputValues[cellData] || cellData}
                          className="editShipment--input"
                          onChange={(e) =>
                            editShipmentInput(cellData, e.target.value)
                          }
                        />
                      )
                    ) : (
                      cellData
                    )}
                  </td>
                ))}

                <td className="fa-trsh">
                  {editoption ? (
                    <span className="edit-op">
                      <FaEdit onClick={() => editClick(rowIndex)} />
                    </span>
                  ) : (
                    <>
                      <span
                        className="shipment-method-update"
                        onClick={shipmentUpdate}
                      >
                        <FaPen
                          onClick={() => editShipmentClick(rowIndex, rowData)}
                        />
                        <FaCheck
                          onClick={() => updateShipmentClick(rowIndex, rowData)}
                        />
                      </span>
                      <span
                        onClick={() => {
                          setShow(true);
                          const shipmentName = rowData[1];
                          setSelectedShipmentName(shipmentName);
                          dispatch( shipmentMethodListingAsync({
                            query: {
                              page_no: 1,
                              per_page: 50,
                            },
                          }))
                        }}
                      >
                        <FaTrash />
                      </span>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ShipmentPopup
        inputHandleForm={inputHandleForm}
        handleClose={handleClose}
        handleShow={showModal}
        minimum_eta={formData.minimum_eta}
        maximum_eta={formData.maximum_eta}
        shipmentname={formData.shipmentname}
        units={formData.units}
        createShipment={createShipment}
      />
      <ShipmentmethodPopup
        show={show}
        handleClose={() => setShow(false)}
        selectedShipmentname={selectedShipmentname}
      />
    </div>
  );
};

export default ShipmentMethods;
