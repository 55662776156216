import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { pincodesListingApi } from "../../services/apiService";

export const pincodesListingAsync = createAsyncThunk(
  'pincodes/pincodes Listing',
  async (payload) => {
    try {
      const response = await pincodesListingApi(payload);
      console.log(response);
      return response;
    } catch (error) {
      // You can handle errors here or let them propagate
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  pincodesListingData: null,
  columnNames: [],
  rows: [],
  error: null
};

const pincodesListingSlice = createSlice({
  name: 'pincodesListing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pincodesListingAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(pincodesListingAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.pincodesListingData = action.payload;
        console.log(state); // Check the entire state object
console.log(state.pincodesListing); // Check the pincodesListing slice

        state.columnNames = action.payload.columns; // Assuming the first row of the payload contains the column names
        state.rows = action.payload.rows; // Extracting the row values
        state.error = null;
      })
      .addCase(pincodesListingAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default pincodesListingSlice.reducer;
