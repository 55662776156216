import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ShipmentPopup.css'
const ShipmmentTagsPopup = ({popupclose,shipmentTable}) => {
  return (
    <Modal show={true} onHide={popupclose}>
      <Modal.Header closeButton>
        
      </Modal.Header>

      <Modal.Body>
        <p className='confirmtxt'>Do you want to go ahead with this shipment configuration?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={popupclose} className='confirmbtn'>Yes</Button>
        {/* <Button variant="primary" onClick={popupclose}>Save changes</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default ShipmmentTagsPopup;
