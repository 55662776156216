import React from "react";
import { Tabs } from "antd";
import Orders from "../../../pages/Orders/Orders"; // Import the Orders component
import "./CustomTabs.css"; // Import the CSS file for styling
import ellipsisVertical from "../../../images/threedots.png"
const { TabPane } = Tabs;

const CustomTabs = () => {
  return (
    <>
    
           <img src={ellipsisVertical} alt="" className="elliseicon"/>
          <Tabs defaultActiveKey="1" className="custom-tabs">
            <TabPane tab="All Orders" key="1">
              <Orders />
            </TabPane>
            <TabPane tab="Cancel Orders" key="2">
            <Orders />
            </TabPane>
            <TabPane tab="Return Orders" key="3">
            <Orders />
            </TabPane>
          </Tabs>
       
    </>
  );
};

export default CustomTabs;
