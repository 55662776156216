import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { FaDownload, FaUpload } from "react-icons/fa";
import "./Popup.css";

const Popup = ({
  show,
  handleClose,
  modalTitle,
  id,
  handleFileChange,
  selectedFiles,
  handleAddBulkClick,
  downloadTemplate
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Modal onClose={handleClose} size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="popup--title">Bulk Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-5">
            <button className="uploadfiles--btn" onClick={() => setIsOpen(true)}>
              Upload Files
              <FaUpload className="fil--icon" />
            </button>
          </div>
          <div className="col-md-2">
           
          
          </div>
          <div className="col-md-5">
            <button className="downloadfiles--btn" onClick={downloadTemplate}>
              Download Template
              <FaDownload className="fil--icon" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          {isOpen && (
              <>
                <div className="d-flex align-items-center mt-4">
                  <input
                    type="file"
                    id={id}
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor={id} className=" mr-2 browsefiless-btn">
                    Browse Files
                  </label>
                  <input
                    type="text"
                    value={
                      selectedFiles && selectedFiles.length > 0
                        ? selectedFiles[0].name
                        : ""
                    }
                    readOnly
                    className="form-control mr-2 fileselect--input"
                    placeholder="Please select file.."
                  />
                </div>
                <div className="text-center">
                  <button
                    className=" mt-4 upload--btn"
                    onClick={handleAddBulkClick}
                    onClose={handleClose}
                    onHide={handleClose}
                  >
                    
                    Upload
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      
    </Modal>
  );
};

export default Popup;
