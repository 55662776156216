import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaEye, FaPlus, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { warehouseDetailsAsync } from "../../app/store/warehouseDetailsSlice";
import { createStoreAsync } from "../../app/store/createStoreFormSubmitSlice";
import { serviceabilityWarehouseListAsync } from "../../app/store/serviceabilityWarehouseListing";
import { fetchShipmentConfigDetails } from "../../app/store/shipmentConfigTemplateListSlice";
import { shipmentTagAsyncFunc } from "../../app/store/shipmentTagsSlice";
import BackIcon from "../../../src/images/backicon.png";
import ShipmmentTagsPopup from "../../components/UIComponents/ShipmentTagsPopup/ShipmentTagsPopup";
import CheckedImage from "../../../src/images/checkedImage.png"; // Import image for checked radio button
import UncheckedImage from "../../../src/images/uncheckedImage.png"; // Import image for unchecked radio button

import { shipmentConfigListSearchAsync } from "../../app/store/shipmentConfigTagSearchListSlice";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import "./NewFulfillmentCenter.css";
import { bulkPincodeAsync } from "../../app/store/pincodesBulkUploadSlice";
import { warehouseinputsearchSuggestionListAsync } from "../../app/store/serviceabilitywarehouseSearchInputSuggestionSlice";

import Select from "react-select";
import Button from "../../components/UIComponents/Button/Button";
import Popup from "../../components/UIComponents/Popup/Popup";
import { downloadServiceableTemplateApiAsync } from "../../app/store/downloadServicableTemplateSlice";

const NewfulfillmentCenter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popup, setPoup] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [searchedData, setSearchedData] = useState();
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [tagsSearchInput, setTagsSearchInput] = useState();
  const[getSearchData,setGetSearchedData] = useState('')
  const [searchVal, setSearchVal] = useState([]);
  const shipmentConfig = useSelector(
    (state) => state.shipmentConfig.shipmentList
  );
  console.log(shipmentConfig);


  const shipmentTags = useSelector((state) => state.shipmentTag.shipmentTag);

  const shipmentSearchTagsList = useSelector(
    (state) => state.shipmentConfigListSearch.shipmentListSearch
  );
  console.log(shipmentSearchTagsList);
  const handleRadioChange = (id) => {
    setSelectedId(id);
    setPoup(true);
    const config = shipmentConfig.find((config) => config.id === id);
    setSelectedConfig(config);
    console.log(config.name);
  };
  const backFunc = () => {
    navigate("/configurations");
  };
  useEffect(() => {
    if (selectedId !== null) {
      dispatch(
        shipmentTagAsyncFunc({ query: { shipment_config_id: selectedId } })
      );
    }
  }, [dispatch, selectedId]);

  useEffect(() => {
    dispatch(
      fetchShipmentConfigDetails({ query: { page_no: 1, per_page: 100 } })
    );
  }, [dispatch]);

  const { status, pincodedata } = useSelector((state) => state.bulkpincodes);

  const [editableRows, setEditableRows] = useState(() =>
    pincodedata.rows.map((row) => [...row])
  );
  const suggestionList = useSelector(
    (state) => state.warehouseinputsearch.suggestionList.warehouses
  );

  const getWarehouseOptions = () => {
    return suggestionList.map((value) => ({ value, label: value }));
  };
  const handleAddBulkClick = () => {
    if (selectedFiles && selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });
      dispatch(bulkPincodeAsync({ files: selectedFiles }));
    } else {
      console.log("Please select a file.");
      // Handle the case when no file is selected
    }
    setShow(false)
  };

  useEffect(() => {
    dispatch(
      fetchShipmentConfigDetails({
        query: {
          page_no: 1,
          per_page: 100,
        },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    dispatch(serviceabilityWarehouseListAsync());
    dispatch(
      warehouseinputsearchSuggestionListAsync({ query: { search: searchTerm } })
    );

    setEditableRows(pincodedata.rows.map((row) => [...row]));
  }, [pincodedata, searchTerm]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };

  const handleTagsInputChange = (e) => {
    const getInputValue=e.target.value
    setTagsSearchInput(e.target.value);

    setGetSearchedData(getInputValue);
    console.log(getSearchData)
    const searchFilter=shipmentSearchTagsList.filter((item)=>{
      if(item.name.includes(getSearchData)){
        return item
      }
    })
    setSearchVal(searchFilter)
   console.log(searchVal)
    dispatch(
      shipmentConfigListSearchAsync({
        query: {
          page_no: 1,
          per_page: 100,
          search: tagsSearchInput,
        },
      })
    );
  };
  const handleSearch = async () => {
    dispatch(
      shipmentConfigListSearchAsync({
        query: {
          page_no: 1,
          per_page: 100,
          search: tagsSearchInput,
        },
      })
    );
  };
  useEffect(() => {
    dispatch(
      shipmentConfigListSearchAsync({
        query: {
          page_no: 1,
          per_page: 100,
         
        },
      })
    );
  },[]);
  const handleInputChange = (value, rowIndex, cellIndex) => {
    const updatedRows = editableRows.map((row) => [...row]);
    updatedRows[rowIndex][cellIndex] = value;
    setEditableRows(updatedRows);
    setSearchedData(shipmentSearchTagsList);
    console.log(searchedData);
  };
  const renderCell = (cell) => {
    if (typeof cell === "object" && cell !== null) {
      return Object.keys(cell).map((key) => (
        <div key={key}>
          {key}: {cell[key]}
        </div>
      ));
    }
    return cell;
  };

  const handlerowClick = (id) => {
    navigate("/warehouse");
    localStorage.setItem("id", id);

    console.log("Clicked row id:", id);
    // Add any additional logic you want to perform when a row is clicked
  };

  //Handling form Data here for submitting form
  const [warehouseForm, setwarehouseForm] = useState({
    name: "",
    type: "",
    pincode: "",
    latlong: "",
    country: "",
    city: "",
    state: "",
    line1: "",
    line2: "",
    street: "",
    country: "",
    email: "",
    mobile: "",
  });
  const inputDataHandle = (e) => {
    const { name, value } = e.target;
    setwarehouseForm({ ...warehouseForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure pincodedata.rows is not empty and contains at least one array
    if (pincodedata.rows.length > 0) {
      // Convert pincode to string and format each row
      const modifiedRows = pincodedata.rows.map((row) => {
        // Convert pincode to string
        const pincodeString = String(row[0]);

        // Return the modified row with pincode as string
        return [pincodeString, ...row.slice(1)];
      });

      // Construct the response in the desired format
      const response = [pincodedata.columns, ...modifiedRows];

      // Now you can use the response in your payload
      dispatch(
        createStoreAsync({
          service: { pincodes: response },
          warehouse: {
            name: warehouseForm.name,
            email: warehouseForm.email,
            line_1: warehouseForm.line1,
            line_2: warehouseForm.line2,
            street: warehouseForm.street,
            type: warehouseForm.type,
            city: warehouseForm.city,
            state: warehouseForm.state,
            status_in_text: "Open",
            country: warehouseForm.country,
            phone_number: warehouseForm.mobile,
            lat_long: warehouseForm.latlong,
            channel_location_id: {},
            pincode: warehouseForm.pincode,
          },
          shipment_id: selectedId,
        })
      );
    }
  };
  const downloadTemplate = () => {
    dispatch(downloadServiceableTemplateApiAsync());
  };
  const removeConfig = () => {
    setSelectedConfig(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="warehouse-form">
        <div className=" first--row">
          <span className="back--para">
            <img
              className="back-icon"
              src={BackIcon}
              alt=""
              onClick={backFunc}
            />{" "}
            <p className="back-p">Back</p>
          </span>
          <p className="add-fulfillment-para">Add Fulfillment</p>
          <Link to={`/serviceabilitydetails`} className="custom-link" />
          <div className="row">
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label htmlFor="name" className="input--label">
                  Name
                </label>
                <br />
                <input
                  type="text"
                  value={warehouseForm.name}
                  name="name"
                  onChange={inputDataHandle}
                  className="input-field inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Type</label>
                <br />
                <select
                  className="form-select inp-siz"
                  name="type"
                  onChange={inputDataHandle}
                >
                  <option value="">Select Type</option>
                  <option value="Warehouse">Warehouse</option>

                  <option value="Storehouse">Storehouse</option>

                  <option value="Darkhouse">Darkhouse</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Status</label>
                <br />
                <select
                  className="form-select inp-siz"
                  name=" status_in_text"
                  onChange={inputDataHandle}
                >
                   <option value="">Select Status</option>
                  <option value="Open">Open</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Pincode</label>
                <br />
                <input
                  type="text"
                  value={warehouseForm.pincode}
                  name="pincode"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Lat-Long</label>
                <br />
                <input
                  type="text"
                  value={warehouseForm.latlong}
                  name="latlong"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">City</label>
                <input
                  type="text"
                  value={warehouseForm.city}
                  name="city"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">State</label>
                <input
                  type="text"
                  value={warehouseForm.state}
                  name="state"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Line 1</label>
                <input
                  type="text"
                  value={warehouseForm.line1}
                  name="line1"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>

            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Line 2</label>
                <input
                  type="text"
                  value={warehouseForm.line2}
                  name="line2"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Street</label>
                <input
                  type="text"
                  value={warehouseForm.street}
                  name="street"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Country</label>
                <input
                  type="text"
                  value={warehouseForm.country}
                  name="country"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Contact Email</label>
                <input
                  type="text"
                  value={warehouseForm.email}
                  name="email"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-3 inp-padding">
              <div className="input-container">
                <label className="input--label">Contact Number</label>
                <input
                  type="text"
                  value={warehouseForm.mobile}
                  name="mobile"
                  onChange={inputDataHandle}
                  className="inp-siz"
                />
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>

        <div className="" style={{ marginTop: "20px" }}>
          <div className="">
            <div className="row">
              <p className="add-fulfillment-para">Shipment Tags</p>
              <div className="col-md-4">
                {/* <Select
                  isMulti
                  // options={getWarehouseOptions()}
                  // onInputChange={(inputValue, { action }) => {
                  //   if (action === "input-change") {
                  //     setSearchTerm(inputValue);
                  //   }
                  // }}
                  // onChange={(selectedOptions) =>
                  //   handleInputChange(
                  //     selectedOptions.map((option) => option.value)
                  //   )
                  // }
                   onInputChange={handleonTagsChange} // Pass the handleInputChange function to handle input changes
      onChange={handleTagsSearch}
                /> */}

                <Inputsearch
                  type="text"
                  value={tagsSearchInput}
                  onChange={handleTagsInputChange}
                  className="tag--input"
                  placeholder="Enter Shipment Configurations or Tag names.."
                  onClick={handleSearch}
                  style={{ fontSize: "14px !important" }}
                />
              </div>
              <div className="col-md-8">
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
                <ul className="tags--ul">
                  {/* {shipmentTags.map((tag, index) => (
                    <li key={index} className="shipment--tags--li">
                      {tag}
                      <span className="close-icon" >
        &#10005; 
      </span>
                    </li>
                  ))} */}
                  {selectedConfig ? (
                    <>
                      <p className="shipment--tags--li">
                        {selectedConfig.name}
                      </p>{" "}
                      <span className="close-icon" onClick={removeConfig}>
                        &#10005;
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="col-md-12">
                <div>
                  {loading && <p>Loading...</p>}
                  {error && <p>Error: {error}</p>}
                  <p className="shipmentconfig--para">
                    Shipment Configurations
                  </p>
                  {popup && (
                    <ShipmmentTagsPopup popupclose={() => setPoup(false)} />
                  )}
                  <div className="shipmentconfig-div">
                    <div>
                      { getSearchData?(
                        <>
                          
                          <div className="tags-list">
                            
                             {
                             shipmentSearchTagsList.map((config)=>(
                          <div key={config.id} className="shipment-template">
                          <label className="radio-button-label">
                            <input
                              type="radio"
                              name="shipmentConfig"
                              value={config.id}
                              checked={config.id === selectedId}
                              onChange={() => handleRadioChange(config.id)}
                            />

                            <img
                              src={
                                config.id === selectedId
                                  ? CheckedImage
                                  : UncheckedImage
                              }
                              alt="radio"
                            />
                            {config.name}
                          </label>
                        </div>
                        ))
                      } 

                          </div>
                        </>
                      )
                        : shipmentConfig.map((config) => (
                          <div key={config.id} className="shipment-template">
                            <label className="radio-button-label">
                              <input
                                type="radio"
                                name="shipmentConfig"
                                value={config.id}
                                checked={config.id === selectedId}
                                onChange={() => handleRadioChange(config.id)}
                              />

                              <img
                                src={
                                  config.id === selectedId
                                    ? CheckedImage
                                    : UncheckedImage
                                }
                                alt="radio"
                              />
                              {config.name}
                            </label>
                          </div>
                        ))
                      }
                      
                      {popup 
                       &&
                        // getSearchData.map((config)=>(
                        //   <div key={config.id} className="shipment-template">
                        //   <label className="radio-button-label">
                        //     <input
                        //       type="radio"
                        //       name="shipmentConfig"
                        //       value={config.id}
                        //       checked={config.id === selectedId}
                        //       onChange={() => handleRadioChange(config.id)}
                        //     />

                        //     <img
                        //       src={
                        //         config.id === selectedId
                        //           ? CheckedImage
                        //           : UncheckedImage
                        //       }
                        //       alt="radio"
                        //     />
                        //     {config.name}
                        //   </label>
                        // </div>
                        // ))

                        // shipmentConfig.map((config) => (
                        //     <div key={config.id} className="shipment-template">
                        //       <label className="radio-button-label">
                        //         <input
                        //           type="radio"
                        //           name="shipmentConfig"
                        //           value={config.id}
                        //           checked={config.id === selectedId}
                        //           onChange={() => handleRadioChange(config.id)}
                        //         />

                        //         <img
                        //           src={
                        //             config.id === selectedId
                        //               ? CheckedImage
                        //               : UncheckedImage
                        //           }
                        //           alt="radio"
                        //         />
                        //         {config.name}
                        //       </label>
                        //     </div>
                        //   ))
                          
                        
                        
                        shipmentConfig.map((config) => (
                          <div key={config.id} className="shipment-template">
                            <label className="radio-button-label">
                              <input
                                type="radio"
                                name="shipmentConfig"
                                value={config.id}
                                checked={config.id === selectedId}
                                onChange={() => handleRadioChange(config.id)}
                              />

                              <img
                                src={
                                  config.id === selectedId
                                    ? CheckedImage
                                    : UncheckedImage
                                }
                                alt="radio"
                              />
                              {config.name}
                            </label>
                          </div>
                        ))
                        
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="" style={{ marginTop: "20px" }}>
          <div className="">
            <div className="row">
              <p className="add-fulfillment-para">Serviceable Pincodes</p>

              <div className="col-md-10"></div>
              <div className="col-md-2">
                <Button
                  onClick={() => setShow(true)}
                  children="Bulk Upload"
                  className="newfulfillment-bulk--upload"
                />
                <Popup
                  show={show}
                  handleClose={() => setShow(false)}
                  handleShow={() => setShow(true)}
                  modalTitle="Modal Demo"
                  onChange={handleFileChange}
                  type="file"
                  id="fileInput"
                  htmlFor="fileInput"
                  onClick={() => setIsOpen(true)}
                  handleFileChange={handleFileChange}
                  selectedFiles={selectedFiles}
                  handleAddBulkClick={handleAddBulkClick}
                  downloadTemplate={downloadTemplate}
                />
              </div>
            </div>
            {pincodedata && (
              <table
                className="serviceable--pincode--table"
                style={{
                  position: "sticky",
                  top: "0",
                  width: "100%",
                  marginBottom: "27px",
                }}
              >
                <thead>
                  <tr>
                    {pincodedata.columns.map((header, index) =>
                      header.toLowerCase().includes("warehouse") ? null : (
                        <th className="warehouselisting--td" key={index}>
                          {header}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {editableRows.map((row, rowIndex) => (
                    <tr className="warehouselisting--td" key={rowIndex}>
                      {row.map((cell, cellIndex) =>
                        pincodedata.columns[cellIndex]
                          .toLowerCase()
                          .includes("warehouse") ? null : (
                          <td key={cellIndex}>
                            {cellIndex ===
                            pincodedata.columns.indexOf("Warehouse") ? (
                              <Select
                                style={{ display: "none" }}
                                isMulti
                                options={getWarehouseOptions()}
                                onInputChange={(inputValue, { action }) => {
                                  if (action === "input-change") {
                                    setSearchTerm(inputValue);
                                  }
                                }}
                                onChange={(selectedOptions) =>
                                  handleInputChange(
                                    selectedOptions.map(
                                      (option) => option.value
                                    ),
                                    rowIndex,
                                    cellIndex
                                  )
                                }
                              />
                            ) : (
                              <input
                                type="text"
                                value={cell}
                                className="input-class"
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value,
                                    rowIndex,
                                    cellIndex
                                  )
                                }
                              />
                            )}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {selectedFiles && (
              <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2">
                  <input
                    type="submit"
                    value="Submit"
                    className="fulfillmnet--submitbtn"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default NewfulfillmentCenter;
