import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {addBulkInventoryFunc } from '../../services/apiService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const addbulkInventoryAsync = createAsyncThunk('pincode/addbulkpincode', async ({ file }) => {
  try {
    const response = await addBulkInventoryFunc (file);
    
   toast.success(` ${response.message}`); 
    console.log('API Response:', response.message);
    return response; // Assuming the API response already has the structure you want
  } catch (error) {
    console.log('Error in uploading pincode:', error.error);
    return { error: error.error };
  }
});

const addBulkInventorySlice = createSlice({
  name: 'addbulkinventory',
  initialState: {
   inventorylisting: null,
//    inventorydata: {
//       columns: [],
//       rows: [],
//     },
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addbulkInventoryAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addbulkInventoryAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.inventorylisting = action.payload;
        // state.inventorydata.columns = action.payload.columns || [];
        // state.inventorydata.rows = action.payload.rows || [];
        console.log('Entire Payload:', action.payload);
        console.log('Inventory from Payload:', state.pincodedata);
      })
      .addCase(addbulkInventoryAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default addBulkInventorySlice.reducer;
