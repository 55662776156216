import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { shipmentTagsList } from "../../services/apiService";

export const shipmentTagAsyncFunc = createAsyncThunk(
  "shipment/shipmentTag",
  async (payload) => {
    try {
      const response = await shipmentTagsList(payload);
      console.log(response)
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
    loading: false,
    shipmentTag: [], 
    error: null,
  };
const shipmentTagsListSlice = createSlice({
  name: "shipmentTag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(shipmentTagAsyncFunc.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shipmentTagAsyncFunc.fulfilled, (state, action) => {
        state.loading = false;
        state.shipmentTag = action.payload;
        state.error = null;
      })
      .addCase(shipmentTagAsyncFunc.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default shipmentTagsListSlice.reducer;
