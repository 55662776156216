import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./WarehouseCreation.css";
import { FaEye, FaPlus, FaSearch, FaTrash } from "react-icons/fa";
import { createWarehouseAsync } from "../../app/store/createWarehouseSlice";
const WarehouseCreation = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    email: "",
    pincode: "",
    status_in_text: "",
    lat_long: "",
    city: "",
    state: "",
    country: "",
    line1: "",
    line2: "",
    mobileNo: "",
  });
  const warehouseCreationInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [calculationParams, setCalculationParams] = useState({
    calculation: "",
  });

  const calculationOnChange = (e) => {
    setCalculationParams({
      ...calculationParams,
      [e.target.name]: e.target.value,
    });
  };
  const [warehouseConditionsData, setWarehouseConditionsData] = useState({
    min_order_value: "",
    max_order_value: "",
    max_order_queue: "",
    current_status: "",
    order_statuses: "",
  });
  const warehouseConditionInput = (e) => {
    setWarehouseConditionsData({
      ...warehouseConditionsData,
      [e.target.name]: e.target.value,
    });
  };
  /***For Configurable Tables Show  ***/
  const [showTables, setShowTables] = useState({
    orderPriceDiscounts: false,
    shipmentMethod: false,
    weightRange: false,
    dimensionRange: false,
  });

  const handleCheckboxChange = (table) => {
    setShowTables((prev) => ({ ...prev, [table]: !prev[table] }));
  };
  const [activeTab, setActiveTab] = useState(1);

  const [shipmentTable, setShipmentTable] = useState(false);
  const [orderTable, setOrderTable] = useState(false);
  const [orderWeight, setOrderWeight] = useState(false);
  const [orderDistance, setOrderDistance] = useState(false);
  const [orderPackage, setOrderPackage] = useState(false);
  const [weightInput, setweightInput] = useState();
  const [distanceInput, setdistanceInput] = useState();
  const [currencyInput, setCurrencyInput] = useState();
  const weightInputOnChange = (event) => {
    const selectedWeight = event.target.value;
    setweightInput(selectedWeight);
  };

  const distanceinputOnChange = (event) => {
    const selectedVal = event.target.value;
    setdistanceInput(selectedVal);
  };

  const currencyOnChange = (event) => {
    const selectedCurrency = event.target.value;
    setCurrencyInput(selectedCurrency);
  };

  const [rows1, setRows1] = useState([
    {
      shipmentMethod: "",
      shipmentCharges: "",
      primeCharges: "",
      minEta: "",
      maxEta: "",
    },
  ]);
  const [rows2, setRows2] = useState([
    {
      minRange: "",
      maxRange: "",
      field1: "",
      field2: "",
      minEta: "",
      maxEta: "",
    },
  ]);
  const [rows3, setRows3] = useState([
    {
      minRange: "",
      maxRange: "",
      field1: "",
      field2: "",
      minEta: "",
      maxEta: "",
      unit: "",
    },
  ]);
  const [rows4, setRows4] = useState([
    {
      minRange: "",
      maxRange: "",
      field1: "",
      field2: "",
      minEta: "",
      maxEta: "",
    },
  ]);
  const [rows5, setRows5] = useState([
    {
      packagingType: "",
      charges: "",
      primeCharges: "",
      minEta: "",
      maxEta: "",
    },
  ]);

  const toggleClick = () => {
    setShipmentTable((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      shipment: !prevState.shipment,
    }));
  };

  const toggleOrderTable = () => {
    setOrderTable((prevState) => !prevState);
  };

  const toggleOrderWeight = () => {
    setOrderWeight((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      weight: !prevState.weight,
    }));
  };
  const toggleOrderDistance = () => {
    setOrderDistance((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      distance: !prevState.distance,
    }));
  };
  const toggleOrderPackage = () => {
    setOrderPackage((prevState) => !prevState);
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      packaging: !prevState.packaging,
    }));
  };

  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  // Adding row functionality
  const addRow = () => {
    console.log("add row");
    setRows1([
      ...rows1,
      {
        shipmentMethod: "",
        shipmentCharges: "",
        primeCharges: "",
        minEta: "",
        maxEta: "",
      },
    ]);
  };

  const addRow2 = () => {
    setRows2([...rows2, { ...rows2[0] }]);
  };
  const addRow3 = () => {
    setRows3([...rows3, { ...rows3[0] }]);
  };
  const addRow4 = () => {
    setRows4([...rows4, { ...rows4[0] }]);
  };

  const addRow5 = () => {
    setRows5([...rows5, { ...rows5[0] }]);
  };

  const handleInputChange = (index, fieldName, value) => {
    const newrows1 = [...rows1];
    newrows1[index][fieldName] = value;
    setRows1(newrows1);
  };
  //Deleting row functionality
  const deleteRow = (index) => {
    const newrows1 = [...rows1];
    newrows1.splice(index, 1);
    setRows1(newrows1);
  };

  const handleInputChange2 = (index, fieldName, value) => {
    const newRows = [...rows2];
    newRows[index][fieldName] = value;
    setRows2(newRows);
  };

  const deleteRow2 = (index) => {
    const newRows = [...rows2];
    newRows.splice(index, 1);
    setRows2(newRows);
  };

  const handleInputChange3 = (index, fieldName, value) => {
    const newRows = [...rows3];
    newRows[index][fieldName] = value;
    setRows3(newRows);
  };

  const deleteRow3 = (index) => {
    const newRows = [...rows3];
    newRows.splice(index, 1);
    setRows3(newRows);
  };
  const handleInputChange4 = (index, fieldName, value) => {
    const newRows = [...rows4];
    newRows[index][fieldName] = value;
    setRows4(newRows);
  };

  const deleteRow4 = (index) => {
    const newRows = [...rows4];
    newRows.splice(index, 1);
    setRows4(newRows);
  };

  const handleInputChange5 = (index, fieldName, value) => {
    const newRows = [...rows5];
    newRows[index][fieldName] = value;
    setRows5(newRows);
  };

  const deleteRow5 = (index) => {
    const newRows = [...rows5];
    newRows.splice(index, 1);
    setRows5(newRows);
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    shipment: false,
    weight: false,
    distance: false,
    packaging: false,
  });

  /***FOr configurable Section State Management */
  const [configRow1, setconfigRow1] = useState([
    {
      minWeight: "",
      maxWeight: "",
      discount: "",
    },
  ]);

  const addRowConfig = (table) => {
    if (table === "table1") {
      setconfigRow1([...configRow1, { priceRange: "", discount: "" }]);
    }
  };
  const removeRowConfig = (index, table) => {
    if (table === "table1") {
      const updatedRows = [...configRow1];
      updatedRows.splice(index, 1);
      setconfigRow1(updatedRows);
    } else if (table === "table2") {
      // Implement similar logic for the second table (if needed)
      // const updatedRows = [...configRow2];
      // updatedRows.splice(index, 1);
      // setconfigRow2(updatedRows);
    }
    // Add more conditions for other tables if needed
  };
  const [configRow2, setConfigRow2] = useState([
    {
      shipmentMethod: "",
      rateMultiplier: "",
      minEta: "",
      maxEta: "",
    },
  ]);

  const addRowConfig2 = () => {
    setConfigRow2([
      ...configRow2,
      { shipmentMethod: "", rateMultiplier: "", minEta: "", maxEta: "" },
    ]);
  };

  const removeRowConfig2 = (index) => {
    const updatedRows = [...configRow2];
    updatedRows.splice(index, 1);
    setConfigRow2(updatedRows);
  };

  const handleRowChangeConfig2 = (index, field, e) => {
    const updatedRows = [...configRow2];
    updatedRows[index][field] = e.target.value;
    setConfigRow2(updatedRows);
  };
  /**** Config Weight range table  ****/
  const [configRow3, setConfigRow3] = useState([
    {
      minWeight: "",
      maxWeight: "",
      discount: "",
    },
  ]);

  const addRowConfig3 = () => {
    setConfigRow3([
      ...configRow3,
      { minWeight: "", maxWeight: "", discount: "" },
    ]);
  };

  const removeRowConfig3 = (index) => {
    const updatedRows = [...configRow3];
    updatedRows.splice(index, 1);
    setConfigRow3(updatedRows);
  };

  const handleRowChangeConfig3 = (index, field, e) => {
    const updatedRows = [...configRow3];
    updatedRows[index][field] = e.target.value;
    setConfigRow3(updatedRows);
  };

  /**** Config Dimension range table  ****/
  const [configRow4, setConfigRow4] = useState([
    {
      minVolume: "",
      maxVolume: "",
      cost: "",
    },
  ]);

  const addRowConfig4 = () => {
    setConfigRow4([...configRow4, { minVolume: "", maxVolume: "", cost: "" }]);
  };

  const removeRowConfig4 = (index) => {
    const updatedRows = [...configRow4];
    updatedRows.splice(index, 1);
    setConfigRow4(updatedRows);
  };

  const handleRowChangeConfig4 = (index, field, e) => {
    const updatedRows = [...configRow4];
    updatedRows[index][field] = e.target.value;
    setConfigRow4(updatedRows);
  };

  /**Config Distance Table */
  const [configRow5, setConfigRow5] = useState([
    { minEta: "", maxEta: "", shippingCost: "" },
  ]);

  const addRowConfig5 = () => {
    setConfigRow5((prev) => [
      ...prev,
      { minEta: "", maxEta: "", shippingCost: "" },
    ]);
  };

  const removeRowConfig5 = (index) => {
    setConfigRow5((prev) => prev.filter((_, i) => i !== index));
  };
  // For Distance Range Based Cost
  const handleRowChangeConfig5 = (index, key, event) => {
    const updatedRows = [...configRow5];
    updatedRows[index][key] = event.target.value;
    setConfigRow5(updatedRows);
  };
  /***Config Package Table */

  const [configRow6, setConfigRow6] = useState([
    { packagingType: "", packagingCost: "" },
  ]);

  const addRowConfig6 = () => {
    setConfigRow6((prev) => [
      ...prev,
      { packagingType: "", packagingCost: "" },
    ]);
  };

  const removeRowConfig6 = (index) => {
    setConfigRow6((prev) => prev.filter((_, i) => i !== index));
  };

  // For Packaging Type Based Cost
  const handleRowChangeConfig6 = (index, key, event) => {
    const updatedRows = [...configRow6];
    updatedRows[index][key] = event.target.value;
    setConfigRow6(updatedRows);
  };
  const [configFormData, setConfigFormData] = useState({
    currencyUnit: "",
    flatRate: "",
    primeMemberDiscount: "",
    // ... other form data fields for config ...
  });

  // ... other state variables for other form data ...

  const handleConfigInputChange = (field, value) => {
    setConfigFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [weightunitInput, setweightunitInput] = useState();

  const weightRangeInputOnChange = (event) => {
    setweightunitInput(event.target.value);
    console.log(event.target.value);
  };

  const [volumeunitInput, setvolumeunitInput] = useState();

  const volumeRangeInputOnChange = (event) => {
    setvolumeunitInput(event.target.value);
    console.log(event.target.value);
  };

  const [distanceunitInput, setdistanceunitInput] = useState();

  const distanceRangeInputOnChange = (event) => {
    setdistanceunitInput(event.target.value);
    console.log(event.target.value);
  };

  //Dispatching data here...

  const handleSubmit = () => {
    // Collect input values from state
    const commonWarehouseData = {
      service: {
        pincodes: [
          ["pincode", "city", "state", "country"],
          ["440036", "Bengaluru", "Karnataka", "India"],
          ["440034", "Bengaluru", "Karnataka", "India"],
          ["440035", "Bengaluru", "Karnataka", "India"],
        ],
      },
      warehouse: {
        name: formData.name,
        type: formData.type,
        email: formData.email,
        pincode: formData.pincode,
        lat_long: formData.lat_long,
        status_in_text: formData.status_in_text,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        line_1: formData.line1,
        line_2: formData.line2,
        phone_number: formData.mobileNo,
  
        warehouse_conditions: {
          min_order_value: parseInt(
            warehouseConditionsData.min_order_value,
            10
          ),
          max_order_value: parseInt(
            warehouseConditionsData.max_order_value,
            10
          ),
          max_order_queue: parseInt(
            warehouseConditionsData.max_order_queue,
            10
          ),
          current_status: warehouseConditionsData.current_status,
          order_statuses: [],
        },
      },
    };
  
    let warehouseData;
  
    if (activeTab === 1) {
      warehouseData = {
        ...commonWarehouseData,
        flat_shipment: {
          currency_type: currencyInput,
          shipment_method: rows1.map((row) => ({
            name: row.shipmentMethod,
            charge: parseInt(row.shipmentCharges, 10),
            prime_charge: parseInt(row.primeCharges, 10),
            eta_min: parseInt(row.minEta, 10),
            eta_max: parseInt(row.maxEta, 10),
          })),
             order_discount: rows2.map((row) => ({
          min_range: parseInt(row.minRange, 10),
          max_range: parseInt(row.maxRange, 10),
          charge: parseInt(row.field1, 10),
          prime_charge: parseInt(row.field2, 10),
          eta_min: parseInt(row.minEta, 10),
          eta_max: parseInt(row.maxEta, 10),
        })),
        weight_charge: rows3.map((row) => ({
          min_range: parseInt(row.minRange, 10),
          max_range: parseInt(row.maxRange, 10),
          charge: parseInt(row.field1, 10),
          unit: weightInput,
          prime_charge: parseInt(row.field2, 10),
          eta_min: parseInt(row.minEta, 10),
          eta_max: parseInt(row.maxEta, 10),
        })),
          distance_charge: rows4.map((row) => ({
          min_range: parseInt(row.minRange, 10),
          max_range: parseInt(row.maxRange, 10),
          unit: distanceInput, // assuming it's a constant value
          charge: parseInt(row.field1, 10),
          prime_charge: parseInt(row.field2, 10),
          eta_min: parseInt(row.minEta, 10),
          eta_max: parseInt(row.maxEta, 10),
        })),
        packaging_type: rows5.map((row) => ({
          package_type: row.packagingType,
          charge: parseInt(row.charges, 10),
          prime_charge: parseInt(row.primeCharges, 10),
          eta_min: parseInt(row.minEta, 10),
          eta_max: parseInt(row.maxEta, 10),
        })),
          calculation: {
          parameters: Object.keys(selectedCheckboxes)
            .filter((key) => selectedCheckboxes[key])
            .map((key) => key),
          calculate_based_on: calculationParams.calculation,
        },
        },
      };
    } else if (activeTab === 2) {
      warehouseData = {
        ...commonWarehouseData,
        config_shipment: {
          currency_type: configFormData.currencyUnit,
          flat_rate: parseInt(configFormData.flatRate, 10),
          prime_member_discount: parseInt(
            configFormData.primeMemberDiscount,
            10
          ),
          order_price_discounts: showTables.orderPriceDiscounts
            ? configRow1.map((row) => ({
                min_price: parseInt(row.minWeight, 10),
                max_price: parseInt(row.maxWeight, 10),
                discount: parseInt(row.discount, 10),
              }))
            : [],
            shipment_method_eta: showTables.shipmentMethod
            ? configRow2.map((row) => ({
                shipment_name: row.shipmentMethod,
                rate_multiplier: parseInt(row.rateMultiplier, 10),
                min_eta: parseInt(row.minEta, 10),
                max_eta: parseInt(row.maxEta, 10),
              }))
            : [],
          weight_based_cost: showTables.weightRange
            ? configRow3.map((row) => ({
                min_weight: parseInt(row.minWeight, 10),
                max_weight: parseInt(row.maxWeight, 10),
                cost: parseInt(row.discount, 10),
                weight_unit: weightunitInput, // Assuming you have weightUnit in your state
              }))
            : [],
          dimension_based_cost: showTables.dimensionRange
            ? configRow4.map((row) => ({
                min_volume: parseInt(row.minVolume, 10),
                max_volume: parseInt(row.maxVolume, 10),
                cost: parseInt(row.cost, 10),
                dimension_unit: volumeunitInput, // Assuming you have dimensionUnit in your state
              }))
            : [],
          distance_based_cost: configRow5.map((row) => ({
            min_distance: parseInt(row.minEta, 10),
            max_distance: parseInt(row.maxEta, 10),
            distance_unit: distanceunitInput,
            cost: parseInt(row.shippingCost, 10),
            // Assuming distanceUnit is a number
          })),
          packaging_type_cost: configRow6.reduce((accumulator, row) => {
            accumulator[row.packagingType] = parseInt(row.packagingCost, 10);
            return accumulator;
          }, {}),
        },
      };
    }
  
    console.log("Warehouse Data:", warehouseData);
    dispatch(createWarehouseAsync(warehouseData));
  };
  

  return (
    <div>
      <div className="card first--row">
        <h3>Warehouse Creation</h3>
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="type">Type</label> <br />
            <select
              name="type"
              value={formData.type}
              onChange={warehouseCreationInput}
              className="type--class"
            >
              <option value="Select Store">Select Store</option>
              <option value="Darkhouse">Darkhouse</option>
              <option value="Storehouse">Storehouse</option>
              <option value="Warehouse">Warehouse</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="pincode">Pincode</label>
            <input
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="location">Latitude/Longitude</label>
            <input
              type="text"
              name="lat_long"
              value={formData.lat_long}
              onChange={warehouseCreationInput}
              placeholder="Latitude/Longitude"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="city">City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="state">State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={warehouseCreationInput}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="line1">Line 1</label>
            <input
              type="text"
              name="line1"
              value={formData.line1}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="line2">Line 2</label>
            <input
              type="text"
              name="line2"
              value={formData.line2}
              onChange={warehouseCreationInput}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="type">Status</label> <br />
            <select
              name="status_in_text"
              value={formData.status_in_text}
              onChange={warehouseCreationInput}
              className="type--class"
            >
              <option value="Status">Status</option>
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="mobileNo">Mobile No</label>
            <input
              type="text"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={warehouseCreationInput}
            />
          </div>
        </div>
      </div>
      <div className="card first--row">
        <h3>Shipment ETA & Shipment Cost</h3>
        <div className="card-body">
          <div className="tabs">
            <div
              className={`tab ${activeTab === 1 ? "active" : ""}`}
              onClick={() => handleTabChange(1)}
            >
              Flat
            </div>
            <div
              className={`tab ${activeTab === 2 ? "active" : ""}`}
              onClick={() => handleTabChange(2)}
            >
              Configurable
            </div>
            <div
              className={`tab ${activeTab === 3 ? "active" : ""}`}
              onClick={() => handleTabChange(3)}
            >
              User defined
            </div>
            <div
              className={`tab ${activeTab === 4 ? "active" : ""}`}
              onClick={() => handleTabChange(4)}
            >
              3rd Party
            </div>
          </div>

          <div className="tab-content">
            {activeTab === 1 && (
              <div>
                {/* Shipment Method */}
                <div className="row">
                  <div className="col-md-3">
                    <select
                      name="currency_type"
                      value={currencyInput}
                      id=""
                      className="type--class"
                      onChange={currencyOnChange}
                    >
                      <option value="Currency">Currency</option>
                      <option value="INR">INR</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="one--div">
                      <input type="checkbox" onClick={toggleClick} />
                      <h6>Add Shipment Type</h6>
                    </div>
                  </div>
                </div>
                {shipmentTable && (
                  <>
                    <div>
                      <button className="add-row-btn" onClick={addRow}>
                        Add Row
                        <FaPlus />
                      </button>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Shipment Method</th>
                            <th>Shipment Charges</th>
                            <th>Prime Charges</th>
                            <th>ETA</th>
                            <th className="th--action">Action</th>
                          </tr>
                        </thead>
                        {rows1.map((row, index) => (
                          <tbody>
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  value={row.shipmentMethod}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "shipmentMethod",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.shipmentCharges}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "shipmentCharges",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.primeCharges}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "primeCharges",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <div className="inp-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Eta"
                                    value={row.minEta}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "minEta",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="prepend">-</p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Eta"
                                    value={row.maxEta}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "maxEta",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td className="th--action">
                                <button
                                  onClick={() => deleteRow(index)}
                                  className="delete-btn"
                                >
                                  <FaTrash />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </>
                )}

                {/* Shipment Method */}

                {/* Order Price */}
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="one--div">
                        <input type="checkbox" onClick={toggleOrderTable} />
                        <h6>Order Price</h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3"> */}

                  {orderTable && (
                    <>
                      <div>
                        <button className="add-row-btn" onClick={addRow2}>
                          Add Row
                          <FaPlus />
                        </button>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Range</th>
                              <th>Charges</th>
                              <th>Prime Charges</th>
                              <th>ETA</th>
                              <th className="th--action">Action</th>
                            </tr>
                          </thead>
                          {rows2.map((row2, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Min Range"
                                      value={row2.minRange}
                                      onChange={(e) =>
                                        handleInputChange2(
                                          index,
                                          "minRange",
                                          e.target.value,
                                          setRows2
                                        )
                                      }
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max Range"
                                      value={row2.maxRange}
                                      onChange={(e) =>
                                        handleInputChange2(
                                          index,
                                          "maxRange",
                                          e.target.value,
                                          setRows2
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row2.field1}
                                    onChange={(e) =>
                                      handleInputChange2(
                                        index,
                                        "field1",
                                        e.target.value,
                                        setRows2
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row2.field2}
                                    onChange={(e) =>
                                      handleInputChange2(
                                        index,
                                        "field2",
                                        e.target.value,
                                        setRows2
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Min Eta"
                                      value={row2.minEta}
                                      onChange={(e) =>
                                        handleInputChange2(
                                          index,
                                          "minEta",
                                          e.target.value,
                                          setRows2
                                        )
                                      }
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max Eta"
                                      value={row2.maxEta}
                                      onChange={(e) =>
                                        handleInputChange2(
                                          index,
                                          "maxEta",
                                          e.target.value,
                                          setRows2
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="th--action">
                                  <button
                                    onClick={() => deleteRow2(index, setRows2)}
                                  >
                                    <FaTrash />
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
                {/* Order Price */}

                {/* Weight */}
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="one--div">
                        <input type="checkbox" onClick={toggleOrderWeight} />
                        <h6>Order Weight</h6>
                      </div>
                    </div>
                  </div>
                  {orderWeight && (
                    <>
                      <div className="col-md-3">
                        <select
                          name="unit"
                          id=""
                          className="type--class"
                          value={weightInput}
                          onChange={weightInputOnChange}
                        >
                          <option value="KG">KG</option>
                          <option value="10Kg">10Kg</option>
                        </select>
                      </div>

                      <div>
                        <button className="add-row-btn" onClick={addRow3}>
                          Add Row
                          <FaPlus />
                        </button>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Range</th>
                              <th>Charges</th>
                              <th>Prime Charges</th>
                              <th>ETA</th>
                              <th className="th--action">Action</th>
                            </tr>
                          </thead>
                          {rows3.map((row3, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Min Range"
                                      value={row3.minRange}
                                      onChange={(e) =>
                                        handleInputChange3(
                                          index,
                                          "minRange",
                                          e.target.value,
                                          setRows3
                                        )
                                      }
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max Range"
                                      value={row3.maxRange}
                                      onChange={(e) =>
                                        handleInputChange3(
                                          index,
                                          "maxRange",
                                          e.target.value,
                                          setRows3
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row3.field1}
                                    onChange={(e) =>
                                      handleInputChange3(
                                        index,
                                        "field1",
                                        e.target.value,
                                        setRows3
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row3.field2}
                                    onChange={(e) =>
                                      handleInputChange3(
                                        index,
                                        "field2",
                                        e.target.value,
                                        setRows3
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Min Eta"
                                      value={row3.minEta}
                                      onChange={(e) =>
                                        handleInputChange3(
                                          index,
                                          "minEta",
                                          e.target.value,
                                          setRows3
                                        )
                                      }
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max Eta"
                                      value={row3.maxEta}
                                      onChange={(e) =>
                                        handleInputChange3(
                                          index,
                                          "maxEta",
                                          e.target.value,
                                          setRows3
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="th--action">
                                  <button
                                    onClick={() => deleteRow3(index, setRows3)}
                                  >
                                    <FaTrash />
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
                {/* Weight */}

                {/* Distance */}
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="one--div">
                        <input type="checkbox" onClick={toggleOrderDistance} />
                        <h6>Distance</h6>
                      </div>
                    </div>
                  </div>

                  {orderDistance && (
                    <>
                      <div className="col-md-3">
                        <select
                          name="unit"
                          id=""
                          value={distanceInput}
                          className="type--class"
                          onChange={distanceinputOnChange}
                        >
                          <option value="KM">KM</option>
                          <option value="10KM">10KM</option>
                        </select>
                      </div>

                      <div>
                        <button className="add-row-btn" onClick={addRow4}>
                          Add Row
                          <FaPlus style={{ fontSize: "10px" }} />
                        </button>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Range</th>
                              <th>Charges</th>
                              <th>Prime Charges</th>
                              <th>ETA</th>
                              <th className="th--action">Action</th>
                            </tr>
                          </thead>
                          {rows4.map((row4, index) => (
                            <tbody key={index}>
                              <tr>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Min Range"
                                      value={row4.minRange}
                                      onChange={(e) =>
                                        handleInputChange4(
                                          index,
                                          "minRange",
                                          e.target.value,
                                          setRows4
                                        )
                                      }
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max Range"
                                      value={row4.maxRange}
                                      onChange={(e) =>
                                        handleInputChange4(
                                          index,
                                          "maxRange",
                                          e.target.value,
                                          setRows4
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row4.field1}
                                    onChange={(e) =>
                                      handleInputChange4(
                                        index,
                                        "field1",
                                        e.target.value,
                                        setRows4
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row4.field2}
                                    onChange={(e) =>
                                      handleInputChange4(
                                        index,
                                        "field2",
                                        e.target.value,
                                        setRows4
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Min Eta"
                                      value={row4.minEta}
                                      onChange={(e) =>
                                        handleInputChange4(
                                          index,
                                          "minEta",
                                          e.target.value,
                                          setRows4
                                        )
                                      }
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Max Eta"
                                      value={row4.maxEta}
                                      onChange={(e) =>
                                        handleInputChange4(
                                          index,
                                          "maxEta",
                                          e.target.value,
                                          setRows4
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="th--action">
                                  <button
                                    onClick={() => deleteRow4(index, setRows3)}
                                  >
                                    <FaTrash />
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                </div>
                {/* Distance */}

                {/* Distance */}
                <div>
                  <div style={{ display: "inline-flex" }}>
                    <input type="checkbox" onClick={toggleOrderPackage} />
                    <h6 className="packaging--type">Packaging Type</h6>
                  </div>

                  {orderPackage && (
                    <div>
                      <button className="add-row-btn" onClick={addRow5}>
                        Add Row
                        <FaPlus />
                      </button>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Charges</th>
                            <th>Prime Charges</th>
                            <th>ETA</th>
                            <th className="th--action">Action</th>
                          </tr>
                        </thead>
                        {rows5.map((row, index) => (
                          <tbody>
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  value={row.packagingType}
                                  onChange={(e) =>
                                    handleInputChange5(
                                      index,
                                      "packagingType",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.charges}
                                  onChange={(e) =>
                                    handleInputChange5(
                                      index,
                                      "charges",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={row.primeCharges}
                                  onChange={(e) =>
                                    handleInputChange5(
                                      index,
                                      "primeCharges",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <div className="inp-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Eta"
                                    value={row.minEta}
                                    onChange={(e) =>
                                      handleInputChange5(
                                        index,
                                        "minEta",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <p className="prepend">-</p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Eta"
                                    value={row.maxEta}
                                    onChange={(e) =>
                                      handleInputChange5(
                                        index,
                                        "maxEta",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td className="th--action">
                                <button
                                  onClick={() => deleteRow5(index)}
                                  className="delete-btn"
                                >
                                  <FaTrash />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}
                </div>
                {/* Packaging Type */}
                {/* {shipments.map((shipment, index) => (
        <div key={index}>{shipment}</div>
      ))}
            {
               showShipment && addShipment
            } */}
              </div>
            )}
            {activeTab === 2 && (
              <div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="CurrencyUnit">Currency Unit:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="CurrencyUnit"
                      value={configFormData.currencyUnit}
                      onChange={(e) =>
                        handleConfigInputChange("currencyUnit", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="FlatRate">Flat Rate (minimum 1):</label>
                    <input
                      type="text"
                      className="form-control"
                      id="FlatRate"
                      value={configFormData.flatRate}
                      onChange={(e) =>
                        handleConfigInputChange("flatRate", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="PrimeMemberDiscount">
                      Prime Member Discount:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PrimeMemberDiscount"
                      value={configFormData.primeMemberDiscount}
                      onChange={(e) =>
                        handleConfigInputChange(
                          "primeMemberDiscount",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-10">
                        <div style={{ display: "flex" }}>
                          <input
                            type="checkbox"
                            checked={showTables.orderPriceDiscounts}
                            name=""
                            id=""
                            onChange={() =>
                              handleCheckboxChange("orderPriceDiscounts")
                            }
                          />
                          <h6 className="hedr--pos">Order Price Discounts</h6>
                        </div>
                      </div>
                    </div>

                    {showTables.orderPriceDiscounts && (
                      <div>
                        <div className="row">
                          <div className="col-md-10"></div>
                          <div className="col-md-2">
                            <button
                              className="add-row-btn"
                              onClick={() => addRowConfig("table1")}
                            >
                              Add Row
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Price Range</th>
                              <th>Discount(Percentage)</th>
                              <th className="th--action">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {configRow1.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <input
                                    type="text"
                                    value={row.value1}
                                    onChange={(e) => {
                                      const updatedRows = [...configRow1];
                                      updatedRows[index].value1 =
                                        e.target.value;
                                      setconfigRow1(updatedRows);
                                    }}
                                  /> */}
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      value={row.minWeight}
                                      onChange={(e) => {
                                        const updatedRows = [...configRow1];
                                        updatedRows[index].minWeight =
                                          e.target.value;
                                        setconfigRow1(updatedRows);
                                      }}
                                      placeholder="Min Price"
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      value={row.maxWeight}
                                      onChange={(e) => {
                                        const updatedRows = [...configRow1];
                                        updatedRows[index].maxWeight =
                                          e.target.value;
                                        setconfigRow1(updatedRows);
                                      }}
                                      placeholder="Max Price"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row.discount}
                                    onChange={(e) => {
                                      const updatedRows = [...configRow1];
                                      updatedRows[index].discount =
                                        e.target.value;
                                      setconfigRow1(updatedRows);
                                    }}
                                  />
                                </td>
                                <td className="delete-btn">
                                  <FaTrash
                                    onClick={() =>
                                      removeRowConfig(index, "table1")
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <div className="row">
                      <div className="col-md-9">
                        <h5>Shipment Method & ETA</h5>
                      </div>
                      <div className="col-md-3">
                        <button className="add-row-btn" onClick={addRow3}>
                          Add Row
                          <FaPlus />
                        </button>
                      </div>
                    </div>

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Shipment Method </th>
                          <th>Rate Multiplier</th>
                          <th>ETA</th>
                          <th className="th--action">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                          <div className="inp-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Eta"
                                    
                                   
                                  />
                                  <p className="prepend">-</p>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Eta"
                                   
                                   
                                  />
                                </div>
                          </td>
                           <td className="delete-btn"><FaTrash /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>

                {/***Shipment Method ETA Table*****/}
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-9">
                        <div style={{ display: "flex" }}>
                          <input
                            type="checkbox"
                            checked={showTables.shipmentMethod}
                            name=""
                            id=""
                            onChange={() =>
                              handleCheckboxChange("shipmentMethod")
                            }
                          />
                          <h6 className="hedr--pos">Shipment Method & ETA</h6>
                        </div>
                      </div>
                    </div>

                    {showTables.shipmentMethod && (
                      <div>
                        <div className="row">
                          <div className="col-md-10"></div>
                          <div className="col-md-2">
                            <button
                              className="add-row-btn"
                              onClick={addRowConfig2}
                            >
                              Add Row
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Shipment Method</th>
                              <th>Rate Multiplier</th>
                              <th>ETA</th>

                              <th className="th--action">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {configRow2.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="text"
                                    value={row.shipmentMethod}
                                    onChange={(e) =>
                                      handleRowChangeConfig2(
                                        index,
                                        "shipmentMethod",
                                        e
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row.rateMultiplier}
                                    onChange={(e) =>
                                      handleRowChangeConfig2(
                                        index,
                                        "rateMultiplier",
                                        e
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      value={row.minEta}
                                      onChange={(e) =>
                                        handleRowChangeConfig2(
                                          index,
                                          "minEta",
                                          e
                                        )
                                      }
                                      placeholder="Min Eta"
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      value={row.maxEta}
                                      onChange={(e) =>
                                        handleRowChangeConfig2(
                                          index,
                                          "maxEta",
                                          e
                                        )
                                      }
                                      placeholder="Max Eta"
                                    />
                                  </div>
                                </td>
                                <td
                                  className="delete-btn"
                                  onClick={() => removeRowConfig2(index)}
                                >
                                  <FaTrash />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                {/**Weight Range Table *****/}

                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-9">
                        <div style={{ display: "flex" }}>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() => handleCheckboxChange("weightRange")}
                          />
                          <h6 className="hedr--pos">Weight Range Based Cost</h6>
                        </div>
                      </div>
                    </div>

                    {showTables.weightRange && (
                      <div>
                        <div className="row">
                          <div className="col-md-10"></div>
                          <div className="col-md-2">
                            <button
                              className="add-row-btn"
                              onClick={addRowConfig3}
                            >
                              Add Row
                              <FaPlus />
                            </button>
                          </div>
                        </div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Weight Range</th>
                              <th>Cost</th>
                              <th className="th--action">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {configRow3.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      value={row.minWeight}
                                      onChange={(e) =>
                                        handleRowChangeConfig3(
                                          index,
                                          "minWeight",
                                          e
                                        )
                                      }
                                      placeholder="Min Weight"
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      value={row.maxWeight}
                                      onChange={(e) =>
                                        handleRowChangeConfig3(
                                          index,
                                          "maxWeight",
                                          e
                                        )
                                      }
                                      placeholder="Max Weight"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row.discount}
                                    onChange={(e) =>
                                      handleRowChangeConfig3(
                                        index,
                                        "discount",
                                        e
                                      )
                                    }
                                  />
                                </td>
                                <td
                                  className="delete-btn"
                                  onClick={() => removeRowConfig3(index)}
                                >
                                  <FaTrash />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-md-3" style={{ display: "flex" }}>
                            <label htmlFor="" className="white--space-label">
                              Weight Input :
                            </label>
                            <input
                              type="text"
                              name={weightunitInput}
                              value={weightunitInput || ""}
                              onChange={weightRangeInputOnChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/**Dimension Range Table ****/}
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-9">
                        <div style={{ display: "flex" }}>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            onChange={() =>
                              handleCheckboxChange("dimensionRange")
                            }
                          />
                          <h6 className="hedr--pos">
                            Dimension Range Based Cost
                          </h6>
                        </div>
                      </div>
                    </div>

                    {showTables.dimensionRange && (
                      <div>
                        <div className="row">
                          <div className="col-md-10"></div>
                          <div className="col-md-2">
                            <button
                              className="add-row-btn"
                              onClick={addRowConfig4}
                            >
                              Add Row
                              <FaPlus />
                            </button>
                          </div>
                        </div>

                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Volume Range</th>
                              <th>Cost</th>
                              <th className="th--action">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {configRow4.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="inp-group">
                                    <input
                                      type="text"
                                      value={row.minVolume}
                                      onChange={(e) =>
                                        handleRowChangeConfig4(
                                          index,
                                          "minVolume",
                                          e
                                        )
                                      }
                                      placeholder="Min Volume"
                                    />
                                    <p className="prepend">-</p>
                                    <input
                                      type="text"
                                      value={row.maxVolume}
                                      onChange={(e) =>
                                        handleRowChangeConfig4(
                                          index,
                                          "maxVolume",
                                          e
                                        )
                                      }
                                      placeholder="Max Volume"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={row.cost}
                                    onChange={(e) =>
                                      handleRowChangeConfig4(index, "cost", e)
                                    }
                                  />
                                </td>
                                <td
                                  className="delete-btn"
                                  onClick={() => removeRowConfig4(index)}
                                >
                                  <FaTrash />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-md-3" style={{ display: "flex" }}>
                            <label htmlFor="" className="white--space-label">
                              Volume Unit :{" "}
                            </label>
                            <input
                              type="text"
                              name={volumeunitInput}
                              value={volumeunitInput || ""}
                              onChange={volumeRangeInputOnChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/***Distance Range Table ****/}
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-9">
                        <h5>Distance Range Based Cost</h5>
                      </div>
                      <div className="col-md-3">
                        <button className="add-row-btn" onClick={addRowConfig5}>
                          Add Row
                          <FaPlus />
                        </button>
                      </div>
                    </div>

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Distance Range</th>
                          <th>Shipping Cost</th>
                          <th className="th--action">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {configRow5.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <div className="inp-group">
                                <input
                                  type="text"
                                  value={row.minEta}
                                  onChange={(e) =>
                                    handleRowChangeConfig5(index, "minEta", e)
                                  }
                                  placeholder="Min Eta"
                                />
                                <p className="prepend">-</p>
                                <input
                                  type="text"
                                  value={row.maxEta}
                                  onChange={(e) =>
                                    handleRowChangeConfig5(index, "maxEta", e)
                                  }
                                  placeholder="Max Eta"
                                />
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={row.shippingCost}
                                onChange={(e) =>
                                  handleRowChangeConfig5(
                                    index,
                                    "shippingCost",
                                    e
                                  )
                                }
                                placeholder="Shipping Cost"
                              />
                            </td>
                            <td
                              className="delete-btn"
                              onClick={() => removeRowConfig5(index)}
                            >
                              <FaTrash />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="col-md-3" style={{ display: "flex" }}>
                        <label htmlFor="" className="white--space-label">
                          Distance Unit :
                        </label>
                        <input
                          type="text"
                          name={distanceunitInput}
                          value={distanceunitInput}
                          onChange={distanceRangeInputOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/***Packaging Type Table ****/}
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <div className="col-md-9">
                        <h5>Packaging Type Based Cost</h5>
                      </div>
                      <div className="col-md-3">
                        <button className="add-row-btn" onClick={addRowConfig6}>
                          Add Row
                          <FaPlus />
                        </button>
                      </div>
                    </div>

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Packaging Type</th>
                          <th>Packaging Cost</th>
                          <th className="th--action">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {configRow6.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                value={row.packagingType}
                                onChange={(e) =>
                                  handleRowChangeConfig6(
                                    index,
                                    "packagingType",
                                    e
                                  )
                                }
                                placeholder="Packaging Type"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={row.packagingCost}
                                onChange={(e) =>
                                  handleRowChangeConfig6(
                                    index,
                                    "packagingCost",
                                    e
                                  )
                                }
                                placeholder="Packaging Cost"
                              />
                            </td>
                            <td
                              className="delete-btn"
                              onClick={() => removeRowConfig6(index)}
                            >
                              <FaTrash />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 3 && <div>Content for Tab 3</div>}
            {activeTab === 4 && <div>Content for Tab 4</div>}
          </div>
        </div>
      </div>

      <div className="card first--row">
        <div className="card-body">
          <h3>Warehouse Conditions</h3>
        </div>
        <div className="container mt-4 warehouse--conditions--div">
          <div className="row">
            <div className="col-md-3 mb-3">
              <label htmlFor="minOrderPrice">Minimum Order Price:</label>
              <input
                type="text"
                name="min_order_value"
                value={warehouseConditionsData.min_order_value}
                className="form-control"
                id="minOrderPrice"
                onChange={warehouseConditionInput}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="maxOrderPrice">Maximum Order Price:</label>
              <input
                type="text"
                name="max_order_value"
                value={warehouseConditionsData.max_order_value}
                className="form-control"
                id="maxOrderPrice"
                onChange={warehouseConditionInput}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="maxOrderQueue">Maximum Order Queue:</label>
              <input
                type="text"
                name="max_order_queue"
                value={warehouseConditionsData.max_order_queue}
                className="form-control"
                id="maxOrderQueue"
                onChange={warehouseConditionInput}
              />
            </div>
            {/* <div className="col-md-3 mb-3">
              <label htmlFor="orderStatus">Order Statuses:</label>
              <select className="form-select" name="current_status" value={warehouseConditionsData.current_status} id="orderStatus" onChange={warehouseConditionInput}>
                <option value="Active">Active</option>
                <option value="In active">In active</option>
              </select>
            </div> */}
            <div className="col-md-3 mb-3">
              <label htmlFor="currentStatus">Current Status:</label>
              <select
                className="form-select"
                name="current_status"
                value={warehouseConditionsData.current_status}
                id="currentStatus"
                onChange={warehouseConditionInput}
              >
                <option value="Status">Status</option>
                <option value="Active">Active</option>
                <option value="In active">In active</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="card first--row">
        <div className="card-body">
          <h3>Parameters</h3>
        </div>
        <div className="container mt-4 warehouse--conditions--div">
          <div className="row">
            <div className="col-md-3 mb-3">
              <label htmlFor="orderStatus">Parameters for Calculation :</label>
              <select
                name="calculation"
                className="form-select"
                id="orderStatus"
                value={calculationParams.calculation}
                onChange={calculationOnChange}
              >
                <option value="Select">Select</option>
                <option value="sum">sum</option>
                <option value="multiply">multiply</option>
                <option value="LeastEta">LeastEta</option>
                <option value="LeastCharge">LeastCharge</option>
                <option value="Average">Average</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseCreation;
