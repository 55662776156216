import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStoreCreationFunc } from "../../services/apiService";

export const storeCreationAsync = createAsyncThunk(
  "storeForm/getstoreForm",
  async () => {
    try {
      const response = await getStoreCreationFunc();
     
      return response;
    } catch (error) {
      console.log("Error fetching store form", error);
      throw error;
    }
  }
);

const getstoreFormSlice = createSlice({
  name: 'storeForm',
  initialState: {
    storeform: null,
    form: [], // Assuming this should be an array
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeCreationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(storeCreationAsync.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.storeform = action.payload; 
        state.form = action.payload; 
      })
      .addCase(storeCreationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default getstoreFormSlice.reducer;
