import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { shipmentConfigDetails } from '../../services/apiService';

export const fetchShipmentConfigDetails = createAsyncThunk(
  'shipmentConfig/fetchShipmentConfigDetails',
  async (payload) => {
    const response = await shipmentConfigDetails(payload);
    console.log(response)
    return response;
   

  }
);

const initialState = {
  loading: false,
  shipmentList: [],
  error: null
};

const shipmentConfigTemplateListSearchSlice = createSlice({
  name: 'shipmentConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShipmentConfigDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipmentConfigDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.shipmentList = action.payload;
        state.error = null;
      })
      .addCase(fetchShipmentConfigDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default shipmentConfigTemplateListSearchSlice.reducer;
