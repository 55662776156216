import React, { Suspense, lazy,useEffect } from "react";
import { Outlet } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/footer";
import Dashboard from "../../../src/pages/Dashboard/Dashboard";
import Orders from "../../../src/pages/Orders/Orders";
import OrderDetails from "../../../src/pages/OrderDetailss/OrderDetails";
import Catalogue from "../../../src/pages/Catalogue/Catalogue";
import Inventory from "../../../src/pages/Inventory/Inventory";
import StoreManagement from "../../pages/Storemanagement/StoreManagement";
import arrowImage from "../../images/Vector.png"

import "../../App.css";
import "../../pages/Dashboard/Dashboard.css";
import "./Layout.css"
import { useState } from "react";
import CreateStore from "../../pages/CreateStore/CreateStore";
import Serviceability from "../../pages/Serviceability/Serviceability";
import ServiceabilityDetails from "../../pages/ServiceabilityDetails/ServiceabilityDetails";
import WarehouseManagement from "../../pages/WarehouseManagement/WarehouseManagement";
import CreateWarehouse from "../../pages/CreateWarehouse/CreateWarehouse";
import Fulfillment from "../../pages/Fulfillment/Fulfillment";
import WarehouseCreation from "../../pages/WarehouseCreation/WarehouseCreation";
import NewfulfillmentCenter from '../../pages/NewfulfillmentCenter/NewfulfillmentCenter'
import ShipmentMethods from "../../pages/ShipmentMethods/ShipmentMethods";
import ShipmentCharges from "../../pages/ShipmentCharges/ShipmentCharges";
import CustomTabs from "../UIComponents/Tabs/CustomTabs";
import ConfigurationTab from "../UIComponents/ConfigurationTab/ConfigurationTab";
import Loader from "../../utils/Loader/Loader";
import ShipmentConfigDetails from "../../pages/ShipmentConfigDetails/ShipmentConfigDetails";


  const LoginPage = lazy(() => import("../../pages/Login/Login"));
  const DashboardPage =  lazy(() => import("../../pages/Dashboard/Dashboard"));

// ... (existing imports)


// const SuspenseRoute = () => {
//   return (
   
//   );
// };
// const SuspenseRoute = ({ fallback, children }) => {
//   return <Suspense fallback={fallback}>{children}</Suspense>;
// };
const Layout = () => {
  const [showLoader, setShowLoader] = useState(true);
 
  const[isReverted,setIsReverted] =useState(false)
  
  const [rotationAngle, setRotationAngle] = useState(0);
  const [isImageInverted, setIsImageInverted] = useState(false);
  
  useEffect(() => {
    // Hide loader after initial render
    setTimeout(function () {
      console.log("Delayed for 5 second."); 
      setShowLoader(false);
    }, 500);
   
  }, []);
  return (
    
    <div className="app">
      
      {window.location.pathname !== "/" && (
        <>

          <Header />

          <Sidebar  />
          <div className="content">
          <div className="layout"> 
          {showLoader ? (
          <Loader
            colors={["#000000", "#000000", "#000000", "#000000", "#000000"]}
            height={60}
            width={70}
          />
        ) :(
          <Suspense
       fallback={<div>Loading...</div>}
      >
     
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/orders" element={<CustomTabs />} />
              
              <Route path="/orderdetails" element={<OrderDetails />} />
              <Route  path="/stores" element={<StoreManagement />} />
              <Route path="/warehouse-details" element={<WarehouseManagement/>} />
              <Route path="/catalogue" element={<Catalogue />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/createstore" element={<CreateStore/>} />
              <Route path="/createwarehouse" element={<CreateWarehouse/>} />
              <Route path="/serviceability" element={<Serviceability/>} />
              <Route path="/serviceabilitydetails" element={<ServiceabilityDetails/>} />
              <Route path="/configurations" element={<ConfigurationTab />} />
              <Route path="/warehousecreation" element={<WarehouseCreation/>} />
              <Route path="/newfulfillmentcenter" element={<NewfulfillmentCenter />} />
              <Route path="/shipmentmethods" element={<ShipmentMethods />} />
              <Route path="/shippingcharges" element={<ShipmentCharges />} />
              <Route path="/shipmentconfig-details" element={<ShipmentConfigDetails/>} />
              <Route element={() => <div>404 Not Found</div>} />
            </Routes>
            </Suspense>
        )}
            </div>
          </div>
          <Footer />
        </>
      )}
      
    </div>
  );
};

export default Layout;
