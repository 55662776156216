// ordersSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOrders } from '../../services/apiService';

import {createOrderApi} from '../../services/apiService'

export const fetchOrdersAsync = createAsyncThunk('orders/fetchOrders', async () => {
  try {
    const response = await fetchOrders(); // Here Api is calling from apiService.js
   

    // Extract column names
    const columnNames = response.column_names || [];

    // Extract rows
    const rows = response.rows || [];

    const statusData = response.status_data || [];
    return { columnNames, rows,statusData };
    
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
});
 



const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    columnNames: [],
    rows: [],
    statusData:[],
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.columnNames = action.payload.columnNames;
        state.rows = action.payload.rows;
        state.statusData=action.payload.statusData
        
       
      })
      .addCase(fetchOrdersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
  },
});

export default orderSlice.reducer;
