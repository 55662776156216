import axios from "axios";
import fileSaver from 'file-saver';
const baseUrl = process.env.REACT_APP_API_URL;
// alert(baseUrl)
console.log(process.env.REACT_APP_API_URL)

// const environment = process.env.REACT_APP_ENVIRONMENT;


// ************Order Listing Service
const apiUrl = "https://tri-oms.theretailinsightsdemos.com/orders_placed/api/v1/list/orders";
// const apiUrl=`${baseUrl}list/orders`

export const fetchOrders = async () => {
  try {
    const requestBody = {
      query: {
      Orders: {
           source__name:"shopify"
          },
      page_no: 1,
      per_page: 50
  }
  }

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

//***Order Create Form Get APi */
const createOrderApi = "http://18.142.228.204/oms/api/v1/create/orders";
// https://3df5e1d0-b8ad-466b-b6f5-c912b7bba920.mock.pstmn.io/api/v1/create/orders
// http://192.168.0.66:7000/api/v1/create/orders

export const createOrders = async () => {
  try {
    const response = await fetch(`${createOrderApi}`, {
      method: "GET",
    });
    const order_data = await response.json();
    console.log(order_data.db_schema);
    return order_data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

//*** Order Create Form Submit Api ****/

const createOrderSubmitApi = `${baseUrl}create/orders`;

export const orderCreateForm = async () => {
  try {
    const response = await axios.post(createOrderSubmitApi);
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error in submitting Order Creation details", error);
    throw error;
  }
};

// const orderDetailApi = "https://2f49a0c4-eda7-4439-9a20-2e25602203ea.mock.pstmn.io/api/v1/order/details";
const orderDetailApi =` https://tri-oms.theretailinsightsdemos.com/orders_placed/api/v1/order/details`;
export const orderDetailsFunc = async (orderId) => {
  try {
    const response = await axios.get(`${orderDetailApi}?id=${orderId}`);
    return response.data;
  } catch (error) {
    console.error("Error in seeing order details page", error);
    throw error;
  }
};

const orderstatusApi = `${baseUrl}order/update`
export const oredrstatusFunc = async (status, orderId) => {
  console.log(orderId);
  try {
    const requestBody = {
      id: orderId,
      status: status,
    };
    const response = await axios.post(orderstatusApi, requestBody);
    return response.data; // Assuming the response contains data
  } catch (error) {
    // Handle errors
    console.error("Error updating order status:", error);
    throw error;
  }
};

// const itemlevelstatusApi = `${baseUrl}order/update`

const itemlevelstatusApi = 'https://tri-oms.theretailinsightsdemos.com/orders_placed/api/v1/order/update'
export const itemlevelstatusFunc = async (orderId, status, itemId) => {
  try {
    const requestBody = {
      id: orderId,
      status,
      item_id: itemId,
    };
    const response = await axios.post(itemlevelstatusApi, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error updating in line item status:", error);
    throw error;
  }
};

const storelistApi =`${baseUrl}storehouses/list`

export const storelistApiFunc = async () => {
  try {
    const requestBody = {
      query: {
        Warehouse: {},
        page_no: 1,
        per_page: 50,
      },
    };
    const response = await axios.post(storelistApi, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error updating in line item status:", error);
    throw error;
  }
};

/*****Serviceability List ******/

const serviceabilityListApi =`${baseUrl}serviceability/list`

export const serviceabilityListApiFunc = async () => {
  try {
    const response = await axios.get(serviceabilityListApi);

    return response.data;
  } catch (error) {
    console.error("Error while fetching Serviceability list", error);
  }
};

/****Serviceability Create Api ******/

const serviceabilityCreateApi =`${baseUrl}serviceability/create`

export const serviceabilityCreateApiFunc = async (requestData) => {
  try {
    const response = await axios.post(serviceabilityCreateApi, requestData);
    return response.data;
  } catch (error) {
    console.error("Error in creating Serviceability", error);
    throw error;
  }
};

/*******Warehouse Listing Api ********/

const warehouseListingApi =`${baseUrl}warehouse/list`

export const warehouseListFunc = async (warehousereqData) => {
  try {
    const response = await axios.post(warehouseListingApi, warehousereqData);
    return response.data;
  } catch (error) {
    console.log("Error in fetching warehouse listing");
  }
};

/**********Warehouse Creation get Form  ********/

const warehouseCreationForm =`${baseUrl}create/warehouse`

// https://tri-oms.theretailinsightsdemos.com/oms_orders/api/v1/storehouse/create
export const warehouseCreateFunc = async () => {
  try {
    const response = await axios.get(warehouseCreationForm);

    return response.data;
  } catch (error) {
    console.log("Error in fetching Warehouse Creation Form");
  }
};

/********Warehouse Creation Form Submit **********/

const warehouseCreationFormSubmit = `${baseUrl}create/storehouse`
  // "https://tri-oms.theretailinsightsdemos.com/oms_orders/api/v1/warehouse/create"; 
  export const warehouseCreationFunc = async (formValues) =>{
    try{
      const response = await axios.post(warehouseCreationFormSubmit,formValues);

      return response.data;
    }
    catch(error){
      console.log("Error in Creating Warehouse Creation Form");
    }
  }
/********** Get Store Form  ********/

const getCreateStoreForm = `${baseUrl}create/storehouse`

export const getStoreCreationFunc = async () =>{
  try{
    const response = await axios.get(getCreateStoreForm);
    
    return response.data
  }
  catch(error){
     console.log("Error while fetching Store Form")
  }
}


/******Store Creation Form Submit *********/
const createStoreFormSubmit = `${baseUrl}create/storehouse`

export const createStoreFormFunc = async (filteredformDetails) =>{
  try{
    const response = await axios.post(createStoreFormSubmit,filteredformDetails)
    return response.data;
  }
  catch(error){
    console.log("Error in Creating Store Creation Form");
  }
}


/********Bulk Upload Api *********/
// const pincodeBulkUpload = "https://tri-oms.theretailinsightsdemos.com/oms_orders/api/v1/pincodes/bulk/upload";
const pincodeBulkUpload=`${baseUrl}pincodes/json/list`
export const pincodebulkUploadFunc = async (files) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file); // Append each file to FormData with the key 'files'
    });
    // formData.append('page_no', page);
    // formData.append('items_per_page', itemsPerPage);

    const response = await axios.post(
      pincodeBulkUpload,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in bulk upload: ', error);
    throw error;
  }
};


// /*****Serviceability Bulk Pincodes ******/
// const serviceabilityPincodesBulkUpload = "https://6c2618ae-3e3e-424c-8b3c-a290db717de6.mock.pstmn.io/add/bulk/pincodes"

// export const serviceabilityPincodesBulkUploadFunc = async()=>{
//   try{
//     const formData = new FormData();
//     files.forEach((file) => {
//       formData.append('files', file); // Append each file to FormData with the key 'files'
//     });
//     const response = await axios.post(
//       serviceabilityPincodesBulkUpload,
//       formData,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       }
//     );
//     return response.data;
//   }
//   catch(error){
//     console.error('Error in bulk pincode:',error)
//   }
// }

//*******Warehouses Listing Api *****/

const serviceabilityWarehouseList = `${baseUrl}warehouse/list`

export const serviceabilityWarehouseListFunc = async (paginationparams) =>{
 try{
  const response = await axios.post(serviceabilityWarehouseList,paginationparams)
  return response.data
  
 }
 catch(error){
  console.log("Error in fetching the ")
 }
}


//******Warehouse input searcher Api ******/

const warehouseinputsearchSuggestionList =`${baseUrl}warehouse/input`

export const warehouseinputsearchSuggestionListFunc = async(searchBody) =>{
  try{
    const response = await axios.post(warehouseinputsearchSuggestionList,searchBody)
    return response.data
  }
  catch(error){
    console.log("Error in fetching the search list of warehouses")
  }
}

//***Warehouselisting input searcher Api ******/
// const warehouseinputsearchFilterList ="https://tri-oms.theretailinsightsdemos.com/oms_orders/api/v1/warehouse/input"

// export const warehouseinputsearchFilterFunc = async(bodyRequest) =>{
//   try{
//     const response = await axios.post(warehouseinputsearchFilterList,bodyRequest)
//     return response.data
//   }
//   catch(error){
//     console.log("Error in fetching the search list of warehouses")
//   }
// }


//*****Warehouse Creation Api ******/

const warehouseCreationApi=`${baseUrl}shipment/config/create`;


// export const createWarehouse = async(bodyRequest) =>{
//   try{
//       const response = await axios.post(warehouseCreationApi,bodyRequest)
//       return response
//   }
//   catch(error){
//     console.log("Error in Warehouse Creation",error.message)
//     return error.message
//   }

// }
export const createWarehouse = async (bodyRequest) => {
  try {
    const response = await axios.post(warehouseCreationApi, bodyRequest);
    return response;
  } catch (error) {
    console.error("Error in Warehouse Creation", error);
    throw error; 
  }
};



/***Warehouse Details Api *****/

const warehouseDetailsApi = `${baseUrl}warehouse/details`

export const warehouseDetails = async(bodyRequest) =>{
   try{
     const warehouseDetailsResponse = await axios.post(warehouseDetailsApi,bodyRequest)
     return warehouseDetailsResponse

   }
   catch(error){
    console.log("Error in Showing Warehouse Details Page",error)
   }
}

/***Shipment Config Template List ****/

const shipmentConfigTemplateList = `${baseUrl}shipment/config/list`

export const shipmentConfigDetails = async (payload) => {
 
  
  try {
    const response = await axios.post(shipmentConfigTemplateList,payload);
    return response.data;
  } catch (error) {
    console.error("Error while fetching Shipment Configurations:", error);
    throw error;
  }
};


/***Shipment Tags List ****/

const shipmentTags =  `${baseUrl}tag/list`

export const shipmentTagsList = async(payload) =>{
  try{
    const response = await axios.post(shipmentTags,payload);
    return response.data
  }
  catch(error){
    console.error("Error in fetching Shipment Tags List");
    throw error;
  }
}

/***Shipment Config -- Shipment Method List**** */

const shipmentMethodListUrl =  `${baseUrl}shipment/method/list`

export const shipmentMethodListApi = async(payload)=>{
  
  try{
    const response = await axios.post(shipmentMethodListUrl,payload)
    console.log(response)
    return response
  }
  catch(error){
    console.error("Error in fetching the Shipment Method List")
  }

}

/***Shipment Config  *****/

const shipmentConfigSearchUrl = `${baseUrl}shipment/config/list`

export const shipmentConfigSearchApi = async (payload) => {
  try {
    const response = await axios.post(shipmentConfigSearchUrl, payload);
    return response.data;
  } catch (error) {
    console.error("Error in searching shipment List:", error.message);
    throw error;
  }
};

/***Inventory List  *****/

const inventoryListUrl = `${baseUrl}inventory/list`

export const inventoryListUrlApi = async (payload) => {
  try {
    const response = await axios.post(inventoryListUrl, payload);
    return response.data;
  } catch (error) {
    console.error("Error in fetching  inventory List:", error.message);
    throw error;
  }
};



/*****Shipment Method List Slice *******/


const shipmentMethodListingUrl = `${baseUrl}shipment/method/list`

export const shipmentmethodListingApi = async (payload) =>{
  try{
    const response = await axios.post(shipmentMethodListingUrl,payload);
    console.log(response.data)
    return response.data
  }
  catch(error){
    console.error("Error in fetching the shipment list",error);
    throw error
  }


};

/*****Shipment Method Creation Api ****/

const shipmentMethodCreation = `${baseUrl}shipment/method/create`

export const shipmentMethodCreationApi = async(payload) =>{
  try{
    const response = await axios.post(shipmentMethodCreation,payload)
  console.log(response)
  return response.data
  }
  catch(error){
    console.log("Error in creating Shipment Method Creation")
    return error
  }
  
}

/*****Pincodes Listing Api *****/

const pincodesListingUrl = `${baseUrl}pincodes/list`

export const  pincodesListingApi = async(payload) =>{
  try{
    const response = await axios.post(pincodesListingUrl,payload)
  console.log(response)
  return response.data
  }
  catch(error){
    console.log("Error in fetching pincodes Listing")
    return error
  }
  
}


/********Bulk Upload Api *********/
// const pincodeBulkUpload = "https://tri-oms.theretailinsightsdemos.com/oms_orders/api/v1/pincodes/bulk/upload";
const addBulkPincodes=`${baseUrl}add/bulk/pincodes`
export const addBulkPincodesFunc = async (files) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file); // Append each file to FormData with the key 'files'
    });
    // formData.append('page_no', page);
    // formData.append('items_per_page', itemsPerPage);

    const response = await axios.post(
      addBulkPincodes,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in bulk upload: ', error);
    throw error;
  }
};




/*****Inventory Bulk Upload ******/
const addBulkInventory=`${baseUrl}inventory/create`

export const addBulkInventoryFunc = async (file) => {
  try {
    const formData = new FormData();

    file.forEach((file) => {
      formData.append('file', file); // Append each file to FormData with the key 'files'
    
    });
    // formData.append('page_no', page);
    // formData.append('items_per_page', itemsPerPage);

    const response = await axios.post(
      addBulkInventory,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in bulk upload: ', error);
    throw error;
  }
};

/******Export Pincodes for Bulk Upload *****/


const exportPincodesApiUrl = `${baseUrl}export/pincodes`;

export const exportPincodesApi = async (payload) => {
  try {
    const response = await axios.post(exportPincodesApiUrl, payload, { responseType: 'arraybuffer' });
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fileSaver.saveAs(blob, 'exported_pincodes.xlsx');
    return response.data;
  } catch (error) {
    console.error('Error exporting pincodes:', error);
    throw error;
  }
};

/*****Individual Pincode *****/
const addpincodeForm = `${baseUrl}add/pincode`

export const  addpincodeFormApi = async(payload) =>{
  try{
    const response = await axios.post(addpincodeForm,payload)
  console.log(response)
  return response.data
  }
  catch(error){
    console.log("Error in Adding  Pincode")
    return error
  }
  
}

/******Remove Pincode */

const removePincodefromBulk = `${baseUrl}remove/pincode`

export const  removePincodefromBulkApi = async(payload) =>{
  try{
    const response = await axios.post(removePincodefromBulk,payload)
  console.log(response)
  return response.data
  }
  catch(error){
    console.log("Error in Deleting  Pincode")
    return error
  }
  
}


const deleteBulkPincodesApiUrl = 'https://tri-oms.theretailinsightsdemos.com/oms_orders/api/v1/remove/bulk/pincodes';
;

export const deleteBulkPincodesApi = async (files) => {
  try {
    const formData = new FormData();
    formData.append('files', files);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob' // Set the response type to 'blob' to handle binary data
    };

    const response = await axios.post(deleteBulkPincodesApiUrl, formData, config);
    // Handle response as needed
    console.log('Bulk pincodes deleted:', response);
    return response;
  } catch (error) {
    console.error('Error deleting bulk pincodes:', error);
    throw error;
  }
};


/*****Individual Pincode *****/
const addpriorityurl= `${baseUrl}add/pincode`

export const  addpriorityApi = async(payload) =>{
  try{
    const response = await axios.post(addpriorityurl,payload)
  console.log(response)
  return response.data
  }
  catch(error){
    console.log("Error in Adding  Priority")
    return error
  }
  
}


/******Download Template Api******/




export const downloadTemplateApi = async () => {
  const downloadTemplateApiUrl = `${baseUrl}generate/template`;

  try {
    const response = await axios.post(downloadTemplateApiUrl, null, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `TemplateFormat.xlsx`);
    document.body.appendChild(link);
    link.click();

    return response.data;
  } catch (error) {
    console.error('Error downloading template:', error);
    throw error;
  }
};


/*******Add Fulfillment Center Excel Sheet Template Download *****/

export const downloadServiceableTemplateApi = async () => {
  const downloadServiceableTemplateApiUrl = `${baseUrl}template/create`;

  try {
    const response = await axios.post( downloadServiceableTemplateApiUrl, null, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `TemplateFormat.xlsx`);
    document.body.appendChild(link);
    link.click();

    return response.data;
  } catch (error) {
    console.error('Error downloading template:', error);
    throw error;
  }
};

/****  Notes Api in Order Details Page ****/
const notesApiUrl = 'https://tri-oms.theretailinsightsdemos.com/orders_placed/api/v1/notes/create';

export const orderNotesApi = async ({ order_id, note_type, note_id, note_comments }) => {
  try {
    const response = await axios.post(notesApiUrl, { order_id, note_type, note_id, note_comments });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error in Creating Notes", error);
    throw error;
  }
};

/*** Shipment Method Delete Api***/

const shipmentMethodDeleteUrl = `${baseUrl}shipment/method/delete`

export const shipmenMethodDeleteApi = async(name)=>{
  try{
    const response = await axios.post(shipmentMethodDeleteUrl,name)
    return response.data
  }
  catch(error){
    console.error("Error in Deleting Shipping Method",error)
  }
}

/******Shipment Update Api */

const shipmentMethodUpdateUrl = `${baseUrl}shipment/method/create`

export const shipmenMethodUpdateApi = async(name)=>{
  try{
    const response = await axios.put(shipmentMethodUpdateUrl,name)
    return response.data
  }
  catch(error){
    console.error("Error in Deleting Shipping Method",error)
  }
}

/******Serviceability Pincodes List Api ***** */
const serviceabilityPincodeListUrl = `${baseUrl}pincodes/list`

export const serviceabilityPincodeListApi = async(payload)=>{
  try{
    const response = await axios.post(serviceabilityPincodeListUrl,payload)
    return response.data
  }
  catch(error){
    console.error("Error in Fetching Serviceability Api's List",error)
  }
}

/******Serviceability Warehouses List (Multi select Dropdown)********/

const serviceabilityListWarehouses = `${baseUrl}list/pincode/warehouses`

export const serviceabilityListWarehousesApi = async(payload) =>{
  try{
    const response = await axios.post(serviceabilityListWarehouses,payload);
    console.log(response.data)
   
    return response.data
   
  }
  catch(error){
    console.error("Error in Fetching Serviceability Api's List",error)
  }
}

/******Warehouse Details Update Api *****/

const warehouseDetailsUpdateApiUrl =`${baseUrl}warehouse/update`
// export const warehouseDetailsUpdateApi = async(payload)=>{
//   try{
//    const response = await axios.put(warehouseDetailsUpdateApiUrl,payload)
   
//    return response.data
//   }
//   catch(error){
//     console.error("Error while Updating the Warehouse details",error)
//     throw error; 
//   }
// }
export const warehouseDetailsUpdateApi = async (payload) => {
  try {
    const response = await axios.put(warehouseDetailsUpdateApiUrl, payload);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      // If the error response contains a message, return that message
      return error.response.data;
    } else {
      console.error("Error while updating the warehouse details", error);
      throw error;
    }
  }
};


/******Shipment Configuration details APi *******/
const shipmentConfigDetailsApiUrl = `${baseUrl}shipment/config/details`
export const shipmentConfigDetailsApi = async(payload) =>{
  try{
     const response = await axios.post(shipmentConfigDetailsApiUrl,payload)
     return response.data
  }
  catch(error){
    console.log("Error in fetching Shipment Details")
  }
} 

/******Shipment Configuration Details Update Api ******/
const shipmentConfigDetailsUpdateApiUrl = `${baseUrl}shipment/config/update`
export const shipmentConfigDetailsUpdateApi = async(payload) =>{
  try{
     const response = await axios.post(shipmentConfigDetailsUpdateApiUrl,payload)
     return response.data
  }
  catch(error){
    
    console.error("Error in fetching Shipment Details", error);
    throw error;
  }
} 


/******Preprocessed Orders Listing Api ******/
const preProcessedOrdersUrl = `${baseUrl}list/orders`
export const  preProcessedOrdersApi = async(payload) =>{
  try{
     const response = await axios.post(preProcessedOrdersUrl,payload)
     
     return response.data.Orders

  }
  catch(error){
    
    console.error("Error in fetching Pre Processed Orders", error);
    throw error;
  }
} 