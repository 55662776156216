// store.js
import { configureStore } from "@reduxjs/toolkit";
import ordersReducer from "./ordersSlice"; 
import createOrderReducer from "./createOrderSlice";
import createOrderSubmitReducer from "./createOrderSubmitSlice";
import orderDetailsReducer from "./orderDetailsSlice";
import statusReducer from "./updateOrderSlice";
import itemlevelstatusupdateSlice from "./itemStatusupdateSlice";
import storeListReducer from "./storelistSlice";
import serviceabilitylistReducer from "./serviceabilityListSlice";
import createserviceabilityReducer from "./createserviceabilitySlice";
import warehouselistReducer from "./warehoustlistSlice";
import warehousecreationReducer from "./warehouseCreationFormSlice";
import warehouseFormReducer from "./warehouseCreationFormSlice"
import storehouseFormReducer from './storeCreationFormSlice'
import bulkpincodeReducer from './pincodesBulkUploadSlice'
import serviceabilityWarehouselistReducer from  "./serviceabilityWarehouseListing";
import warehouseinputsearchReducer from './serviceabilitywarehouseSearchInputSuggestionSlice'
import warehouseInputSearchFilterReducer from './warehouselistingFilterSlice'
import createWarehouseReducer from './createWarehouseSlice'
import warehousedetaislReducer from './warehouseDetailsSlice'
import shipmentConfigTemplateListReducer from './shipmentConfigTemplateListSlice';
import shipmentTagsListReducer from './shipmentTagsSlice'
import shipmentMethodListSliceReducer from './shipmentMethodSlice'
import shipmentConfigTemplateListSearchSliceReducer from "./shipmentConfigTagSearchListSlice";
import shipmentsMethodListingSliceReducer from './shipmentmethodListingSlice'
import createShipmentSliceReducer from './createShipmentMethodSlice'
import pincodesListingSliceReducer from './pincodesListingSlice'
import addbulkpincodesSliceReducer from './addbulkPincodesSlice'
import exportPincodesSliceReducer from './exportbulkpincodeSlice'
import addPincodeSliceReducer from "./addPincodeSlice";
import deletepincodeFormSliceReducer from './deletePincodefromBulkSlice'
import deleteBulkPincodesSliceReducer from './deletebulkUploadSlice';
import addPrioritySliceReducer from "./addPrioritySlice";
import downloadTemplateSliceReducer  from "./downloadTemplateSlice";
import downloadServicableTemplateSliceReducer from "./downloadServicableTemplateSlice";
import orderNotesSliceReducer from "./notesSlice"
import shipmentMethodDeleteSliceReducer from "./shipmentMethodDeleteSlice";
import shipmentMethodUpdateSliceReducer from './shipmentMethodUpdateSlice'
import serviceabilityPincodesListingSliceReducer from './serviceabilityPincodesListingSlice'
import serviceablityPincodesWarehouselistSliceReducer from './serviceabilityPincodeWarehouseListSlice'
import shipmentConfigSearchListSliceReducer from "./shipmentConfigTagSearchListSlice"
import shipmentConfigDetailsSliceReducer from "../shipmentConfigDetailsSlice";
import shipmentConfigUpdateDetailsSliceReducer from './ShipmentConfigDetailsUpdateSlice';
import inventoryListSliceReducer from './inventoryListSlice'
import addBulkInventorySliceReducer from './addbulkInventorySlice'
import preProcessedOrderSliceReducer from "./preProcessedOrderSlice";
const store = configureStore({
  reducer: {
    orders: ordersReducer, 
    createOrder: createOrderReducer,
    createOrderSubmit: createOrderSubmitReducer,
    orderdetails: orderDetailsReducer,
    orderstatusupdate: statusReducer,
    itemstatusupdate: itemlevelstatusupdateSlice,
    storelist: storeListReducer,
    serviceabilitylist: serviceabilitylistReducer,
    createserviceability: createserviceabilityReducer,
    warehouselist: warehouselistReducer,
    warehousedata: warehousecreationReducer,
    warehouseForm: warehouseFormReducer,
    storeForm:storehouseFormReducer,
    bulkpincodes:bulkpincodeReducer,
    serviceabilitywarehouselist: serviceabilityWarehouselistReducer,
    warehouseinputsearch: warehouseinputsearchReducer,
    warehouseInputSearchFilter: warehouseInputSearchFilterReducer,
    createwarehouse:createWarehouseReducer,
    warehousedetails: warehousedetaislReducer,
    shipmentConfig: shipmentConfigTemplateListReducer,
    shipmentTag:shipmentTagsListReducer,
    shipmentlist:shipmentMethodListSliceReducer,
    shipmentConfigListSearch:shipmentConfigTemplateListSearchSliceReducer,
    inventoryListing:inventoryListSliceReducer,
    shipmentMethodListing:shipmentsMethodListingSliceReducer,
    createshipment:createShipmentSliceReducer,
    pincodesListing:pincodesListingSliceReducer,
    addbulkpincodes:addbulkpincodesSliceReducer,
    exportPincodes:exportPincodesSliceReducer,
    addpincode:addPincodeSliceReducer,
    deletepincode:deletepincodeFormSliceReducer,
    deleteBulkPincodes: deleteBulkPincodesSliceReducer,
    addpriority:addPrioritySliceReducer,
    downloadTemplate:downloadTemplateSliceReducer,
    notes:orderNotesSliceReducer,
    downloadServicableTemplate:downloadServicableTemplateSliceReducer,
    shipmentMethodDelete:shipmentMethodDeleteSliceReducer,
    shipmentMethodUpdate:shipmentMethodUpdateSliceReducer,
    serviceabilityPincodesListing:serviceabilityPincodesListingSliceReducer,
    warehouseslistdropdown:serviceablityPincodesWarehouselistSliceReducer,
    shipmentConfigListSearch:shipmentConfigSearchListSliceReducer,
    shipmentconfigdetails:shipmentConfigDetailsSliceReducer,
    shipmentconfigdetailsupdate:shipmentConfigUpdateDetailsSliceReducer,
    addbulkinventory:addBulkInventorySliceReducer,
    preeProccessedOrders:preProcessedOrderSliceReducer
  },
});

export default store;
