// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { orderCreateForm } from "../../services/apiService";

// export const orderCreateFormAsync = createAsyncThunk('orders/createOrderForm', async (orderDetails) => {
//   try {
//     const response = await orderCreateForm(orderDetails);
//     console.log(response);
//     return response;
//   } catch (error) {
//     console.error('Error creating order:', error);
//     throw error;
//   }
// });

// const createOrderSubmitApiSlice = createSlice({
//   name: 'createOrderSubmit',
//   initialState: {
//     state: 'idle',
//     error: null,
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(orderCreateFormAsync.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.data = action.payload;
//         console.log(state.data)
//       })
//       .addCase(orderCreateFormAsync.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.error.message;
//       });
//   },
// });

// export default createOrderSubmitApiSlice.reducer;




import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { orderCreateForm } from "../../services/apiService";

const extractHeaders = (headers) => {
  return {
    contentLength: headers['content-length'],
    contentType: headers['content-type'],
    // Add other header properties you need
  };
};

export const orderCreateFormAsync = createAsyncThunk('orders/createOrderForm', async (orderDetails) => {
  try {
    const response = await orderCreateForm(orderDetails);
    console.log(response);
    
    // Extract necessary header information
    const extractedHeaders = extractHeaders(response.headers);
    
    // Return a serialized response object with extracted headers
    return {
      data: response.data,
      headers: extractedHeaders,
    };
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
});

const createOrderSubmitApiSlice = createSlice({
  name: 'createOrderSubmit',
  initialState: {
    status: 'idle',
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderCreateFormAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        console.log(state.data);
      })
      .addCase(orderCreateFormAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default createOrderSubmitApiSlice.reducer;
