import React from 'react';
import './Footer.css'
const Footer = () => {
  return (
    <footer className="fixed-botttom">
      <p>Powered By The Retail Insights &copy; 2024 OMS Dashboard</p>
    </footer>
  );
};

export default Footer;
