import React, { useEffect, useState } from "react";
import subtractminione from "../../images/subtractminione.png";
import Subtractthree from "../../images/Subtractthree.png";
import "./LoaderImage.css";

const LoaderImage = () => {
  const [showSubtractMinione, setShowSubtractMinione] = useState(true);
  const [showLoadingText, setShowLoadingText] = useState(false);
  const [showThirdImage, setShowThirdImage] = useState(false);
  const [thirdImageHeight, setThirdImageHeight] = useState(0); // Initialize to 0
  const [loadingTextBottom, setLoadingTextBottom] = useState(-100);

  useEffect(() => {
    const timer = setTimeout(() => {
        setShowSubtractMinione(true)
      setShowLoadingText(true);
      setLoadingTextBottom(50);
      setShowThirdImage(true)
    }, 400);

    const transitionTimer = setTimeout(() => {
      setShowLoadingText(true);
      setShowSubtractMinione(false)
      setShowThirdImage(true); // Set showThirdImage to true after loading text
      setThirdImageHeight(450); // Set the height to the actual height of Subtractthree image
    }, 2700); // Adjust delay to match the sequence

    return () => {
      clearTimeout(timer);
      clearTimeout(transitionTimer);
    };
  }, []);

  console.log("showSubtractMinione:", showSubtractMinione);
  console.log("showLoadingText:", showLoadingText);
  console.log("showThirdImage:", showThirdImage);

  return (
    <div className="loader-container">
      {showSubtractMinione && (
        <img className="loader-image" src={subtractminione} alt="" />
      )}
      {showThirdImage && (
        <img
          className="loader-image"
          src={Subtractthree}
          alt=""
          style={{
            height: `${thirdImageHeight}px`,
            transition: "height 1s",
          }}
        />
      )}
      {showLoadingText && (
        <div
          className="loading-text"
          style={{
            bottom: `${loadingTextBottom}px`,
            transition: "bottom 0s ease-out",
          }}
        >
          LOADING...
        </div>
      )}
    </div>
  );
};

export default LoaderImage;
