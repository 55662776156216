import React, { useEffect, useState } from "react";
import BackIcon from "../../../src/images/backicon.png";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { shipmentConfigDetailsAsync } from "../../app/shipmentConfigDetailsSlice";
import { shipmentConfigDetailsUpdateAsync } from "../../app/store/ShipmentConfigDetailsUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
import "./ShipmentConfigDetails.css";

const ShipmentConfigDetails = () => {
  const [inputValues, setInputValues] = useState({
    shipment_template: {},
    config_shipment: {
      order_discount: [],
      weight_charge: [],
      distance_charge: [],
      packaging_type: [],
      dimension_based_cost: [],
    },
  });
  const [inputEnable, setInputEnable] = useState(false);
  const [makeInputFieldEnable, setMakeInputFieldEnable] = useState(false); // Make it false by default
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backFunc = () => {
    navigate("/configurations");
  };

  const shipmentConfigDetails = useSelector(
    (state) => state.shipmentconfigdetails.shipmentconfigdetailsdata
  );
  console.log(shipmentConfigDetails);

  useEffect(() => {
    if (shipmentConfigDetails) {
      setInputValues({
        shipment_template: { ...shipmentConfigDetails.shipment_template },
        config_shipment: { ...shipmentConfigDetails.config_shipment },
      });
    }
  }, [shipmentConfigDetails]);

  const handleInputChange = (section, index, field, value) => {
    const updatedValues = { ...inputValues };
    let parsedValue = value;

    // Parse relevant fields as integers
    if (
      ["flat_price", "shipment_method_charge", "min_range", "max_range", "charge","min_length","min_breadth","min_height","max_length","max_height","max_breadth","cost","discount","prime_discount","charge","prime_charge"].includes(field)
    ) {
      parsedValue = parseInt(value, 10);
      if (isNaN(parsedValue)) {
        parsedValue = 0;
      }
    }

    if (section !== "shipment_template") {
      updatedValues.config_shipment[section] = updatedValues.config_shipment[section].map((item, i) => {
        if (i !== index) return item;
        return { ...item, [field]: parsedValue };
      });
    } else {
      updatedValues.shipment_template[field] = parsedValue;
    }

    setInputValues(updatedValues);
  };

  const editShipmentDetails = () => {
    setInputEnable(true);
    setMakeInputFieldEnable(true); // Enable editing when the Edit button is clicked
  };

  const updateShipmentDetails = () => {
    dispatch(shipmentConfigDetailsUpdateAsync(inputValues));
    console.log("Updated Values:", inputValues);
    setInputEnable(false);
    setMakeInputFieldEnable(false);
  };

  const shipmentID = localStorage.getItem("ShipmentMethodID");
  console.log(shipmentID);

  useEffect(() => {
    dispatch(
      shipmentConfigDetailsAsync({
        query: {
          page_no: 1,
          per_page: 100,
          id: shipmentID,
        },
      })
    );
  }, [dispatch, shipmentID]);

  const renderConfigSection = (key, data) => {
    if (!data || !data.length) return null;

    return (
      <div key={key} className="config-section col-md-6">
        <p className="add-fulfillment-para">{key.replace(/_/g, " ")}</p>
        {data.map((item, index) => (
          <div key={index} className="config-item row">
            {Object.entries(item).map(([field, value]) => (
              <div className="col" key={field}>
                <div className="config-field">
                  <label className="shipmentconfig--headers">
                    {field.replace(/_/g, " ")}
                  </label>
                  <input
                    type="text"
                    value={
                      inputValues.config_shipment[key] &&
                      inputValues.config_shipment[key][index]
                        ? inputValues.config_shipment[key][index][field]
                        : ""
                    }
                    readOnly={!makeInputFieldEnable}
                    className="shipment--config--input"
                    onChange={(e) =>
                      handleInputChange(key, index, field, e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="warehouse--managemnt first--row">
        <span className="back--para">
          <img className="back-icon" src={BackIcon} alt="" onClick={backFunc} />
          <p className="back-p">Back</p>
        </span>
        <p className="add-fulfillment-para">Shipment Configuration Details</p>
        <div className="details-container row">
          <div className="col-md-10"></div>
          <div className="col-md-1">
            {inputEnable && (
              <button
                className="update--warehouse--details"
                onClick={updateShipmentDetails}
              >
                Update
              </button>
            )}
          </div>
          <div className="col-md-1">
            <button
              className="edit--warehouse--details"
              onClick={editShipmentDetails}
            >
              Edit <FaEdit />
            </button>
          </div>
          
        </div>

        <div className="shipment--config--details">
          {shipmentConfigDetails && (
            <>
              <div className="row">
                {shipmentConfigDetails.shipment_template &&
                  Object.entries(shipmentConfigDetails.shipment_template).map(
                    ([key, value]) => (
                      <div className="col-md-3" key={key}>
                        <label htmlFor="" className="shipmentconfig--headers">
                          {key.replace(/_/g, " ")}
                        </label>
                        <input
                          type="text"
                          value={inputValues.shipment_template[key] || ""}
                          readOnly={!makeInputFieldEnable}
                          className="shipment--config--input"
                          onChange={(e) =>
                            handleInputChange(
                              "shipment_template",
                              null,
                              key,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    )
                  )}
              </div>

              <div className="row">
                {shipmentConfigDetails.config_shipment &&
                  Object.entries(shipmentConfigDetails.config_shipment).map(
                    ([key, data]) => renderConfigSection(key, data)
                  )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShipmentConfigDetails;
