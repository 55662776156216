import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serviceabilityCreateApiFunc } from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const serviceabilityCreateAsync = createAsyncThunk(
  "serviceability/createServiceability",
  async ({ serviceability_name, pincodes }) => {
    try {
      const response = await serviceabilityCreateApiFunc({
        serviceability_name: serviceability_name,
        pincodes: pincodes,
      });
      console.log(response);
      toast.success(`Status: ${response.status}`); 
      return response;
    } catch (error) {
      console.error("Error in creating Serviceability", error);
      throw error;
    }
  }
);

const createServiceabilitySlice = createSlice({
  name: "createserviceability",
  initialState: {
    creatserviceabilitystate: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(serviceabilityCreateAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(serviceabilityCreateAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.creatserviceabilitystate = action.payload;
        console.log(state.creatserviceabilitystate);
      })
      .addCase(serviceabilityCreateAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default createServiceabilitySlice.reducer;
