import React, { useEffect, useCallback, useState } from "react";
import { warehouseCreationAsync } from "../../app/store/warehouseCreationFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { createWarehouseFormAsync } from "../../app/store/cerateWareouseFormSubmitSlice";
import "./CreateWarehouse.css";
import { bulkPincodeAsync } from "../../app/store/pincodesBulkUploadSlice";

const pincodes = {
  column_names: ["Pincode", "City", "State", "Country"],
  rows: [
    [
      { type: "input", value: "" },
      { type: "input", value: "" },
      { type: "input", value: "" },
      { type: "input", value: "" },
    ],
  ],
};
const CreateWarehouse = () => {
  const [showinput, setshowInput] = useState(false);
  const [files, setFiles] = useState([]);
  
  const bulkImport = () => {
    setshowInput((showinput) => !showinput);
  };
  const [pincodesData, setPincodesData] = useState(pincodes.rows);
  const [row, setRows] = useState(false);

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({});

  const dispatchWarehouseCreation = useCallback(() => {
    dispatch(warehouseCreationAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatchWarehouseCreation();
    // dispatch(createWarehouseFormAsync());
  }, [dispatchWarehouseCreation]);

  const warehouse = useSelector((state) => state.warehousedata.warehouseForm);
  console.log(warehouse)
  const pincodess = useSelector((state) => state.bulkpincodes.pincodedata);
  console.log(pincodess);
  // Transforming pincode data to the desired format
const transformedPincodes = pincodess.map((data) => {
  return [
    String(data[0]), // Assuming the pincode is a number, converting it to a string
    String(data[1]), // City
    String(data[2]), // State
    String(data[3]), // Country
  ];
});

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleFileUpload = () => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file); // Assuming 'files' is the correct field name
    });
    // const pincodesDat = useSelector((state) => state.bulkpincodes.data);
    dispatch(
      bulkPincodeAsync({ files: formData, page_no: 1, items_per_page: 50 })
    );
    // console.log(pcodes)
  };

  // const handleInputChange = (evt) => {
  //   const { name, value } = evt.target;
  //   var updatedFormValues = { ...formValues };

  //   if (name === "channel_location_id") {
  //     updatedFormValues = {
  //       ...formValues,
  //       channel_location_id: {
  //         Shopify: value,
  //       },
  //     };
  //   } else {
  //     updatedFormValues = {

  //       "service": {
  //         "pincodes": [
  //           ["pincode", "city", "state", "country"],
  //           ["4342322", "Bangalore", "Karnataka", "Bharath"]
  //           // Add more pincodes if needed
  //         ]
  //       },
  //       "storehouse": formValues
  //     };
  //   }

  //   setFormValues(updatedFormValues);
  // };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));

    // if (name === "channel_location_id") {
    //   updatedFormValues = {
    //     ...formValues,
    //     channel_location_id: {
    //       Shopify: value,
    //     },
    //   };
    // } else {
    //   updatedFormValues = {
    //     service: {
    //       pincodes: [
    //         ["pincode", "city", "state", "country"],
    //         ["4342322", "Bangalore", "Karnataka", "Bharath"],
    //         // Add more pincodes if needed
    //       ],
    //     },
    //     storehouse: formValues,
    //   };
    // }

    // setFormValues(updatedFormValues);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("Form Values:", formValues);
    
  //   const payloadData = {
  //     service: {
  //       pincodes: [
  //         ["pincode", "city", "state", "country"],
  //         ...transformedPincodes,
  //       ],
  //     },
  //     storehouse: {
  //       // Map form values to keys based on warehouse.Warehouse structure
  //       ...Object.keys(warehouse.Warehouse).reduce((acc, key) => {
  //         const field = warehouse.Warehouse[key];
  //         let fieldValue = (formValues[field.key] || '').toString(); // Using empty string as default value
    
  //         if (field.key === 'channel_location_id') {
  //           fieldValue = fieldValue.toString(); 
  //           // Set the property directly as an object, not stringified
  //           acc[field.key] = { Shopify: fieldValue };
  //         } else {
  //           acc[field.key] = fieldValue;
  //         }
    
  //         return acc;
  //       }, {}),
  //     },
  //   };
    
    
  //   // Filter out empty or undefined values before dispatching
  //   // const filteredFormValues = Object.fromEntries(
  //   //   Object.entries(formValues).filter(
  //   //     ([key, value]) => value !== "" && value !== undefined
  //   //   )
  //   // );

  //   // Dispatch the filteredFormValues to the action creator
  //   dispatch(createWarehouseFormAsync(payloadData));
  // };
  const handleSubmit = (event) => {
    event.preventDefault();
  
    const isExcelDataPresent = pincodess.length > 0; // Check if data is coming from Excel
    const payloadPincodes = isExcelDataPresent
      ? pincodess.map((row) => row.map((cell) => cell.toString())) // Ensure all data is strings
      : [
          // Default input values if no Excel data is present
          [formValues.pincode || "", formValues.city || "", formValues.state || "", formValues.country || ""],
        ];
  
    const payloadData = {
      service: {
        pincodes: [
          ["pincode", "city", "state", "country"], // Header
          ...payloadPincodes,
        ],
      },
      storehouse: {
        // Map form values to keys based on warehouse.Warehouse structure
        ...Object.keys(warehouse.Warehouse).reduce((acc, key) => {
          const field = warehouse.Warehouse[key];
          let fieldValue = (formValues[field.key] || "").toString(); // Using empty string as default value
  
          if (field.key === "channel_location_id") {
            fieldValue = fieldValue.toString();
            // Set the property directly as an object, not stringified
            acc[field.key] = { Shopify: fieldValue };
          } else {
            acc[field.key] = fieldValue;
          }
  
          return acc;
        }, {}),
      },
    };
  
    dispatch(createWarehouseFormAsync(payloadData));
  };
  
  const handleCountryChange = (evt) => {
    const { name, value } = evt.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("Form Values:", formValues);

  //   const filteredFormValues = Object.fromEntries(
  //     Object.entries(formValues).filter(
  //       ([key, value]) => value !== "" && value !== undefined
  //     )
  //   );

  //   dispatch(createWarehouseFormAsync(filteredFormValues));
  // };
  const handleSave = () => {
    // const pincodeData = pincodesData.map((row) => row.map((cell) => cell.value || '--'));
    // console.log("Input Values:", pincodeData);
    // const payload = {
    //   serviceability_name: serviceabilityName || '--',
    //   pincodes: [[
    //     "pincode",
    //     "city",
    //     "state",
    //     "country"
    // ],pincodeData[0] ],
    // };
  };
  if (!warehouse) {
    return <div>Loading...</div>;
  }
  const addRow = () => {
    console.log("Clicking on Add row..");
    setRows(true);
  };
  return (
    <div>
      <div className="card first--row">
        <h3>Create Warehouse</h3>
      </div>
      <div className="card first--row second-card">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {warehouse &&
              warehouse.Warehouse &&
              Object.keys(warehouse.Warehouse).map((key) => {
                const field = warehouse.Warehouse[key];
                {
                  console.log(field.key);
                }
                return (
                  <div className="col-md-3" key={key}>
                    <label htmlFor={field.key}>{key}</label>
                    {field.type === "text" && (
                      <input
                        type="text"
                        className="form-control"
                        name={field.key}
                        value={formValues[field.key] || ''}
                        onChange={handleInputChange}
                      />
                    )}
                    {field.type === "email" && (
                      <input
                        type="email"
                        className="form-control"
                        name={field.key}
                        value={formValues[field.key] || ''}
                        onChange={handleInputChange}
                      />
                    )}
                    {field.type === "dropdown" && field.options && (
                      <select
                        className="form-select"
                        id={field.key}
                        name={field.key}
                        value={formValues[field.key] || ''}
                        onChange={handleCountryChange}
                      >
                        <option value="">Select {key}</option>
                        {field.options.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="" style={{ padding: "10px 10px 30px 10px" }}>
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-1 ">
                <button
                  className="btn btn-outline-info"
                  type=""
                  onClick={bulkImport}
                >
                  Bulk
                </button>

                {showinput && (
                  <div>
                    <input type="file" multiple onChange={handleFileChange} />
                    <button onClick={handleFileUpload}>Upload</button>
                  </div>
                )}
              </div>
              <div className="col-md-2 ">
                <button className="btn btn-outline-info" onClick={addRow}>
                  Add Row
                </button>
              </div>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  {pincodes.column_names.map((columnName, index) => (
                    <th key={index}>{columnName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
  {pincodess.length > 0 ? (
    // Render input fields from pincodess data
    pincodess.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cellData, cellIndex) => (
          <td key={cellIndex}>
            <input
              className="form-control"
              type="text"
              value={cellData}
              onChange={(e) => handleInputChange(e, rowIndex, cellIndex)}
            />
          </td>
        ))}
      </tr>
    ))
  ) : (
    // Render default input fields when pincodess is empty
    <tr>
      <td>
        <input
          className="form-control"
          type="text"
          placeholder="Pincode"
          onChange={(e) => handleInputChange(e, 0, 0)} // You can set default onChange behavior
        />
      </td>
      <td>
        <input
          className="form-control"
          type="text"
          placeholder="City"
          onChange={(e) => handleInputChange(e, 0, 1)} // You can set default onChange behavior
        />
      </td>
      <td>
        <input
          className="form-control"
          type="text"
          placeholder="State"
          onChange={(e) => handleInputChange(e, 0, 2)} // You can set default onChange behavior
        />
      </td>
      <td>
        <input
          className="form-control"
          type="text"
          placeholder="Country"
          onChange={(e) => handleInputChange(e, 0, 3)} // You can set default onChange behavior
        />
      </td>
      {/* Add more default input fields as needed */}
    </tr>
  )}
</tbody>

            </table>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <button
                type="submit"
                className="btn btn-outline-info"
                style={{ width: "80px" }}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateWarehouse;
