import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { shipmentmethodListingApi } from "../../services/apiService";

export const shipmentMethodListingAsync = createAsyncThunk(
  'shipmentMethodListing/shipmentListing',
  async (payload) => {
    try {
      const response = await shipmentmethodListingApi(payload);
      console.log(response);
      return response;
    } catch (error) {
      // You can handle errors here or let them propagate
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  shipmentMethodsListingData: null,
  columnNames: [],
  rows: [],
  error: null
};

const shipmentsMethodListingSlice = createSlice({
  name: 'shipmentMethodListing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(shipmentMethodListingAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shipmentMethodListingAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.shipmentMethodsListingData = action.payload;
        state.columnNames = action.payload.columns; // Assuming the first row of the payload contains the column names
        state.rows = action.payload.rows; // Extracting the row values
        state.error = null;
      })
      .addCase(shipmentMethodListingAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default shipmentsMethodListingSlice.reducer;
