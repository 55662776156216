import {React, useEffect } from "react";
import "./StoreManagement.css";
import { Link } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { storelistAsync } from "../../app/store/storelistSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa";

const StoreManagement = () => {
  const dispatch = useDispatch();
  useEffect(() => {

    dispatch( storelistAsync());
  
  }, [dispatch]);
  const columnNames = useSelector(state=>state.storelist.columnNames);
  console.log(columnNames);
  const rows = useSelector(state=>state.storelist.rows)
  console.log(rows)
  

  // const storesList = {
  //   column_names: [
  //     "name",
  //     "phone_number",
  //     "city",
  //     "state",
  //     "country",
  //     "status_in_text",
  //     "status",
  //     "location_id",
  //     "channel_location_id",
  //   ],
  //   rows: [
  //     [
  //       "bngl_store",
  //       "null",
  //       "Bangalore",
  //       "karnataka",
  //       "null",
  //       "InActive",
  //       false,
  //       "BNG24354",
  //      "T5677"
  //     ],
  //   ],
  // };
 
  return (
    <div>
      <div className="card first--row">
        <div className="row">
            <div className="col-md-2">
                <h3>Stores</h3>
            </div>
            <div className="col-md-8"></div>
            <div className="col-md-2">
    <Link to="/createstore" className="create-store--btn">
        Create Store <FaPlus />
    </Link>
</div>
        </div>
       

       
      </div>

      <div className="table  table-hover" style={{marginTop:'20px'}}>
        <thead>
          <tr>
            { columnNames.map((column, index) => (
              <th key={index}>
                {column.replace(/(_)./g, (s) => s.slice(-1).toUpperCase())}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
         
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cellData, cellIndex) => (
                <td key={cellIndex}>{cellData instanceof Object ? JSON.stringify(cellData):cellData}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </div>
    </div>
  );
};

export default StoreManagement;
