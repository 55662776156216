import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {warehouseCreationFunc } from "../../services/apiService";

export const createWarehouseFormAsync = createAsyncThunk(
  "warehouseform/createwarehouse",
  async (formValues) => {
    try {
      const response = await warehouseCreationFunc(formValues);
      console.log(response);
      alert(response.success)
      return response.success;
      
    } catch (error) {
      console.error("Error in creating order details:", error);
      throw error;
    }
  }
);

const warehouseFormSlice = createSlice({
  name: 'warehouseform',
  initialState: {
    loading: false,
    success: null,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createWarehouseFormAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createWarehouseFormAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
        state.error = null;
      })
      .addCase(createWarehouseFormAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = null;
        state.error = action.error.message;
    
      });
  },
});

export default warehouseFormSlice.reducer;
