import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { warehouseListFunc } from "../../services/apiService";

export const warehouselistAsync = createAsyncThunk(
  "warehouse/warhouselistapi",
  async () => {
    try {
      const warehousereqData = {
        query: {
          page_no: 1,
          per_page: 100,
        },
      };
      const response = await warehouseListFunc(warehousereqData);

      console.log(response);
      return response;
    } catch (error) {
      console.log("Error while fetching:", error);
      throw error;
    }
  }
);

const warehouselistSlice = createSlice({
  name: "warehouselist",
  initialState: {
    warehouselsiting: null,
    columnNames: [],
    rows:[],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(warehouselistAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(warehouselistAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.warehouselsiting = action.payload;
        state.columnNames = action.payload.columns;
        state.rows = action.payload.rows;
      })
      .addCase(warehouselistAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default warehouselistSlice.reducer;
