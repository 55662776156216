import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { exportPincodesApi } from '../../services/apiService';

export const exportPincodesApiAsync = createAsyncThunk('pincode/bulkpincode', async (payload) => {
  try {
    const response = await exportPincodesApi(payload);
    console.log('API Response:', response);

    if (!response || response instanceof Error) {
      throw new Error('Invalid response received');
    }

    return response;
  } catch (error) {
    console.error('Error in exporting pincodes:', error);
    throw error;
  }
});

const exportPincodesApiSlice = createSlice({
  name: 'exportPincodes',
  initialState: {
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exportPincodesApiAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(exportPincodesApiAsync.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(exportPincodesApiAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default exportPincodesApiSlice.reducer;
