import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import {createWarehouse} from '../../services/apiService'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const createWarehouseAsync = createAsyncThunk('warehouse/createwarehouse', async (warehouseData) => {
    try {
        const response = await createWarehouse(warehouseData);
        toast.success(`${response.data.success}`);
        return response;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Handle 400 error
          const errorMessage = error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Bad request. Please check your input.';
          toast.error(errorMessage);
        } else {
          // Handle other errors
          toast.error('An error occurred while creating the Shipment Configuration.');
        }
        throw error; // Re-throw the error to propagate it to the component
      }
});

const creatingwarehouseSlice = createSlice({
    name :'createwarehouse',
    initialState:{
        loading:false,
        success:null,
        error:null

    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(createWarehouseAsync.pending,(state) =>{
            state.loading = true;
        })
        .addCase(createWarehouseAsync.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload;
            state.error = null;
          })
          .addCase(createWarehouseAsync.rejected, (state, action) => {
            state.loading = false;
            state.success = null;
            state.error = action.error.message;
        
          });
    }
})
export default creatingwarehouseSlice.reducer

