import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { storelistApiFunc } from "../../services/apiService";

export const storelistAsync = createAsyncThunk(
  "storelist/storelistapi",
  async () => {
    try {
      const response = await storelistApiFunc(); // Modify this based on your API
      console.log(response)
      return response;
     
    } catch (error) {
      console.error("Error fetching order details:", error);
      throw error;
    }
  }
);
const storeListSlice = createSlice({
  name: "storelist",

  initialState: {
   storelisting: null,
    columnNames: [],
    rows: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storelistAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(storelistAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.storelisting = action.payload;
        state.columnNames = action.payload.columns
        state.rows=action.payload.rows
        // console.log(columnNames)
        console.log(action.payload.column_names)
        console.log(state.columnNames)
        // state.rows = action.payload.rows;
      })
      .addCase(storelistAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export default storeListSlice.reducer;
