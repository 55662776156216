import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { warehouseCreateFunc } from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const warehouseCreationAsync = createAsyncThunk(
  "warehouse/createwarehous",
  async () => {
    try {
      const response = await warehouseCreateFunc();
    // alert(response.data.success)
      // toast.success(` ${response.success}`); 
      return response;
    } catch (error) {
      console.log("Error while fetching:", error);
      throw error;
    }
  }
);

const warehouseCreationSlice = createSlice({
  name: "warehousecreation",
  initialState: {
    warehouseForm: null,
    warehousedata: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(warehouseCreationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(warehouseCreationAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.warehouseForm = action.payload; 
        state.warehousedata = action.payload; 
      })
      .addCase(warehouseCreationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default warehouseCreationSlice.reducer; 
