import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { warehouseDetailsUpdateApi } from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const warehouseDetailsUpdateAsync = createAsyncThunk(
  'update/warehouseDetailsUpdate',
  async (payload) => {
    try {
      const response = await warehouseDetailsUpdateApi(payload);
      console.log(response);
      
      toast.success(response.message); 
      return response;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle 400 error
        const errorMessage = error.response.data.message || 'Bad request. Please check your input.';
        toast.error(errorMessage);
        throw new Error(errorMessage);
      } else {
        // Handle other errors
        console.error('An error occurred while updating Warehouse Details:', error);
        toast.error('An error occurred while updating Warehouse Details.');
        throw error; // Re-throw the error to propagate it to the component
      }
    }
  }
);

const initialState = {
  loading: false,
  detailsUpdate: null,
};

const warehouseDetailsUpdateSlice = createSlice({
  name: 'warehouseDetailsUpdate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(warehouseDetailsUpdateAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(warehouseDetailsUpdateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.detailsUpdate = action.payload; // Updated this line
      })
      .addCase(warehouseDetailsUpdateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default warehouseDetailsUpdateSlice.reducer;
