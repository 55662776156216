import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchShipmentConfigDetails } from "../../app/store/shipmentConfigTemplateListSlice";
import { useDispatch, useSelector } from "react-redux";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import detailImage from "../../images/File Preview.png";
import Button from "../../components/UIComponents/Button/Button";
import "./ShipmentConfigurationList.css";


const ShipmentConfigurationList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [rowIndex, setRowIndex] = useState("");
  const shipmentConfig = useSelector(
    (state) => state.shipmentConfig.shipmentList
  );
  
  useEffect(() => {
    dispatch(
      fetchShipmentConfigDetails({ query: { page_no: 1, per_page: 150 } })
    );
  }, [dispatch]);

  const addShipmentConfig = () =>{
    navigate('/shippingcharges')
  }
  const goToshipmentConfigDetails = (rowIndex) =>{
    navigate('/shipmentconfig-details')

    localStorage.setItem("ShipmentMethodID", rowIndex);


    

    // setRowIndex(index)
  }

  
  return (
    <div>
        <div className="row">
            <div className="col-md-4">
            <Inputsearch placeholder="Search by Shipping Configuration Name.." />
            </div>
           <div className="col-md-4">

           </div>
           <div className="col-md-2">
              {/* <input
                type="submit"
                value="Search"
                className="submit-search--serviceability shadow-none"
                style={{ width: "59%", float: "right" ,marginTop:'-3px',height:'48px',width:'82%'}}
              /> */}
            </div>
            <div className="col-md-2">
          <Button
            children="Add Shipment Configuration"
            className="add-shipment-configbtn"
            onClick={addShipmentConfig}
          />
        </div>
           </div>
      <div className="row">
        <div className="col-md-10"></div>
        
      </div>

<div className="tabl-scrol">
<table className="table-container shipmentconfiguration--list">
        <thead>
        <tr>
        <th>Shipment Configuration ID</th>
          <th>Shipment Configuration Name</th>
          <th className="action--w">Action</th>
        </tr>
         
        </thead>
        <tbody>
         
            {shipmentConfig.map((value,rowIndex) => (
              <tr key={rowIndex}>
                <td className="shipment-template">{value.id}</td>
                <td className="shipment-template">{value.name}</td>
                <td className="shipping-configuartion-detailIcon" onClick={() => goToshipmentConfigDetails(value.id)}><img src={detailImage} alt="" /></td>
              </tr>
            ))}
         {console.log(rowIndex)}
        </tbody>
      </table>
</div>
    
    </div>
  );
};

export default ShipmentConfigurationList;
