import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addpriorityApi} from "../../services/apiService";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const addpriorityApiAsync = createAsyncThunk(
  'Priority/Addpriority',
  async (payload) => {
    try {
      const response = await addpriorityApi(payload);
      console.log(response);
      toast.success(` ${response.message}`); 
      return response;
    } catch (error) {
      // You can handle errors here or let them propagate
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  addpriority: null,
 
  error: null
};

const addprioritySlice = createSlice({
  name: 'addpriority',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addpriorityApiAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addpriorityApiAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.addpriority = action.payload;
       
        state.error = null;
      })
      .addCase(addpriorityApiAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default addprioritySlice.reducer;
