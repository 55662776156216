import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {createStoreFormFunc} from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const createStoreAsync = createAsyncThunk ("store/createStore",
 async(filteredformDetails)=>{
    try{
        const response = await createStoreFormFunc(filteredformDetails);
        console.log(response);
        toast.success(` ${response.message}`); 
        return response.success
    }
    catch(error){
        console.error("Error in Submitting the Store Details")
        throw error
    }
 }
)

const createStoreSlice = createSlice({
    name:'storeform',
    initialState:{
        loading:false,
        success:null,
        error:null

    },
    reducers:{},
    extraReducers:(builder)=>{
        builder 
        .addCase(createStoreAsync.pending,(state)=>{
            state.loading="true"
        })
        .addCase(createStoreAsync.fulfilled,(state,action)=>{
            state.loading="false"
            state.success = action.payload;
            state.error = null;
        })
        .addCase(createStoreAsync.rejected,(state,action)=>{
            state.loading = "false"
            state.success= null
            state.error=action.error.message
        })
    }
})

export default createStoreSlice.reducer