import React, { useState } from "react";
import "./ServiceabilityDetails.css";
import { useDispatch } from "react-redux";
import { serviceabilityCreateAsync } from "../../app/store/createserviceabilitySlice";

const ServiceabilityDetails = () => {
  const dispatch = useDispatch();
  const wareHouses = {
    column_names: ["Warehouse ID", "Warehouse Name"],
    rows: [
      ["IA7899", "Bangalore North"],
      ["IA63738", "Bangalore South"],
    ],
  };
  const stores = {
    column_names: ["Store ID", "Store Name"],
    rows: [
      ["WSUU1", "Banaswadi Store"],
      ["DJDK1", "Whitefield Store"],
    ],
  };
  const pincodes = {
    column_names: ["Pincode", "City", "State", "Country"],
    rows: [
      [
        { type: "input", value: "" },
        { type: "input", value: "" },
        { type: "input", value: "" },
        { type: "input", value: "" },
      ],
    ],
  };

  const [pincodesData, setPincodesData] = useState(pincodes.rows);
  const [serviceabilityName, setServiceabilityName] = useState("");

  const handleInputChange = (e, rowIndex, cellIndex) => {
    const { value } = e.target;
    const updatedPincodes = [...pincodesData];
    updatedPincodes[rowIndex][cellIndex] = { type: "input", value };
    setPincodesData(updatedPincodes);
  };
  const handleSave = () => {
    const pincodeData = pincodesData.map((row) => row.map((cell) => cell.value || '--'));
    console.log("Input Values:", pincodeData);
  
    const payload = {
      serviceability_name: serviceabilityName || '--',
      pincodes: [[
        "pincode",
        "city",
        "state",
        "country"
    ],pincodeData[0] ],
    };
  

  
    dispatch(serviceabilityCreateAsync(payload));
  };
  
  return (
    <>
      {/* Your other components */}
      <div className="card first--row">
        <h3>Serviceability Details</h3>
      </div>
      <div className="card first--row">
        <div className="row">
          {/* Serviceability Name */}
          <div className="col-md-2">
            <p>Serviceability Name</p>
          </div>
          <div className="col-md-1">
            <p>:</p>
          </div>
          <div className="col-md-9">
            <input
              name=""
              id="serviceabilityName"
              className="form-control inp-fld"
              type="text"
              value={serviceabilityName}
              onChange={(e) => setServiceabilityName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card first--row">
            <p>Warehouses</p>
            <table className="table table-hover">
              <thead>
                {wareHouses.column_names.map((columnName, index) => (
                  <th key={index}>{columnName}</th>
                ))}
              </thead>
              <tbody>
                {wareHouses.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card first--row">
            <p>Stores</p>
            <table className="table table-hover">
              <thead>
                {stores.column_names.map((store, index) => (
                  <th key={index}>{store}</th>
                ))}
              </thead>
              <tbody>
                {stores.rows.map((row, storerowIndex) => (
                  <tr key={storerowIndex}>
                    {row.map((cell, storecellIndex) => (
                      <td key={storecellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Pincodes Table */}
      <div
        className="card first--row"
        style={{ padding: "10px 10px 30px 10px" }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              {pincodes.column_names.map((columnName, index) => (
                <th key={index}>{columnName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pincodesData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((inputField, cellIndex) => (
                  <td key={cellIndex}>
                    <input
                      className="form-control"
                      type={inputField.type}
                      value={inputField.value}
                      onChange={(e) =>
                        handleInputChange(e, rowIndex, cellIndex)
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <button
            className="btn btn-outline-info"
            style={{ width: "80px" }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ServiceabilityDetails;
