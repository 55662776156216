import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serviceabilityPincodeListApi } from "../../services/apiService";

export const serviceabilityPincodesListingAsync = createAsyncThunk(
  'pincodes/serviceable pincodes Listing',
  async (payload) => {
    try {
      const response = await serviceabilityPincodeListApi(payload);
      console.log(response);
      return response;
    } catch (error) {
      // You can handle errors here or let them propagate
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  pincodesListingData: null,
  columnNames: [],
  rows: [],
  error: null
};

const serviceabilityPincodesListingSlice = createSlice({
  name: 'serviceabilityPincodesListing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(serviceabilityPincodesListingAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(serviceabilityPincodesListingAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.pincodesListingData = action.payload;
        console.log(state); // Check the entire state object

        state.columnNames = action.payload.columns; // Assuming the first row of the payload contains the column names
      
        state.rows = action.payload.rows; // Extracting the row values
        state.error = null;
      })
      .addCase(serviceabilityPincodesListingAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default serviceabilityPincodesListingSlice.reducer;
