import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { serviceabilityListApiFunc } from "../../services/apiService";

export const serviceabilityListAsync = createAsyncThunk ('serviceabilityList/fetchServiceabilityList',async () =>{
    try{
        const response = await serviceabilityListApiFunc(); // Modify this based on your API
    console.log(response)
    // alert(response)
    return response
    }
    catch (error) {
        console.error('Error fetching order details:', error);
        throw error;
      }
})
const serviceablitylistSlice = createSlice({
    name: 'serviceabilitylist',
    initialState: {
     serviceability: null,
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(serviceabilityListAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(serviceabilityListAsync.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.serviceability = action.payload;
        })
        .addCase(serviceabilityListAsync.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });

  export default serviceablitylistSlice.reducer

