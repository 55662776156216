// Loader.js
import React, { Suspense } from "react";
import { Triangle } from "react-loader-spinner";
import "./Loader.css";

const Loader = ({ visible, colors, height, width }) => {
  return (
    
    <div className="loader-container">
      {visible && (
        <div className="overlay"></div>
      )}
      <Triangle
        visible={visible}
        height={height}
        width={width}
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        className="spinner"
        colors={colors}
      />
    </div>
   
  );
};


export default Loader;
