import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { shipmenMethodDeleteApi} from "../../services/apiService";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const shipmentMethodDeletingAsync = createAsyncThunk(
  'delete/shipmentDeleting',
  async (payload) => {
    try {
      const response = await shipmenMethodDeleteApi(payload);
      console.log(response);
      toast.success(` ${response.message}`); 
     
      return response;
    } catch (error) {
      toast.error(` ${error.message}`)
      // You can handle errors here or let them propagate
      throw error;
      
    }
  }
);

const initialState = {
  loading: false,
  shipmentMethodDeleting: null,
 
};

const shipmentMethodDeleteSlice = createSlice({
  name: 'shipmentMethodDelete',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(shipmentMethodDeletingAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shipmentMethodDeletingAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.shipmentMethodsListingData = action.payload;
       
      })
      .addCase(shipmentMethodDeletingAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default  shipmentMethodDeleteSlice.reducer;
