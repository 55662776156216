import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serviceabilityListWarehousesApi } from "../../services/apiService";

export const serviceabilityListPincodeWarehouseAsync = createAsyncThunk(
  'kl/l',
  async (payload) => {
    try {
      const response = await serviceabilityListWarehousesApi(payload);
      console.log('API Response:', response); // Log the entire API response
      if (response && response.warehouses) {
        return response.warehouses; // Assuming your API response is an object with a 'warehouses' property containing an array
      } else {
        throw new Error('Invalid API response structure'); // Throw an error if the response structure is not as expected
      }
    } catch (error) {
      console.error('Error fetching warehouse list:', error);
      throw error;
    }
  }
);

const serviceabilityPincodesWarehouselistSlice = createSlice({
  name: 'warehouseslistdropdown',
  initialState: {
    listpincodeswarehouses: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(serviceabilityListPincodeWarehouseAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(serviceabilityListPincodeWarehouseAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listpincodeswarehouses = action.payload;
        console.log('Fulfilled with ri:', action.payload);
      })
      .addCase(serviceabilityListPincodeWarehouseAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default serviceabilityPincodesWarehouselistSlice.reducer;
