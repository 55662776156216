import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./orderDetails.css";
import {
  orderDetailAsync,
  selectOrderDetails,
  selectStatus,
  selectlineItemStatus,
} from "../../app/store/orderDetailsSlice";
import { itemlevelStatusAsync } from "../../app/store/itemStatusupdateSlice";
import { useDispatch, useSelector } from "react-redux";

import Modal from "@mui/material/Modal";
import BackIcon from "../../images/backicon.png";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Notes from "../../components/Notes/Notes";
import Auditlogs from "../../../src/images/Auditlogs.jpg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderDetails = useSelector(selectOrderDetails);
  const status = useSelector(selectStatus);
  const lineitemStatus = useSelector(selectlineItemStatus);
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");

  // State to store the selected status for the dropdown
  const [selectedStatus, setSelectedStatus] = useState("");
  // State to store the selected Item Id
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedStatuss, setSelectedStatuss] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [orderStatuspopup, setorderStatuspopup] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const backFunc = () => {
    navigate("/orders");
  };

  const handleStatusChange = (e, rowIndex) => {
    setSelectedStatus(e.target.value);
    setOpenModal(true); // Display the confirmation modal
  };
  const handleCloseModal = async (confirm) => {
    setOpenModal(false); // Close the modal
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOkClick = async () => {
    // Close the popup
    setShowPopup(true);

    // Trigger the API call for item level status
    await dispatch(
      itemlevelStatusAsync({
        orderId,
        status: selectedStatus,
        itemId: selectedItemId,
      })
    );

    // Trigger the API call for order detail
    await dispatch(orderDetailAsync(orderId));

    // Close the popup
    setShowPopup(false);
  };

  const handleOkClickStatus = async () => {
    // Close the popup
    setShowPopup(true);

    // Trigger the API call for item level status
    await dispatch(
      itemlevelStatusAsync({
        orderId,
        status: selectedStatuss,
      })
    );

    // Trigger the API call for order detail
    await dispatch(orderDetailAsync(orderId));

    // Close the order status popup
    orderStatusClose();

    // Update the first dropdown's value
    setSelectedStatus(selectedStatuss);
  };

  const orderStatusClick = () => {
    setorderStatuspopup(true);
  };
  const orderStatusClose = () => {
    setorderStatuspopup(false);
  };

  useEffect(() => {
    console.log(orderDetails);
    if (orderId) {
      dispatch(orderDetailAsync(orderId));
      dispatch(itemlevelStatusAsync(orderId));
    }
  }, [dispatch, orderId]);
  const handleItemStatusChange = (item, selectedStatus) => {
    setSelectedStatus(selectedStatus); // Update the selected status
    setSelectedItemId(item["Item Id"]); // Update the selected Item Id
    // Dispatch the action to update the item status
    dispatch(
      itemlevelStatusAsync({
        id: orderId,
        status: selectedStatus,
        item_id: item["Item Id"],
      })
    );
  };
  // const orderData = orderDetails.order_data[0];
  // console.log(orderData)

  // Define a function to render the "order_data" card
  // const orderIdForNotes = orderDetails.order_data[0]
  const renderOrderDataCard = () => {
    if (status === "succeeded" && orderDetails) {
      const orderData = orderDetails.order_data[0];
      
      return (
        <div className="orderdetails--bg">
          <div className="row">
            <div className="col-md-3">
              <span>
                <p className="order--id">
                  <img
                    className="back-icon"
                    src={BackIcon}
                    alt=""
                    onClick={backFunc}
                  />{" "}
                  Order ID :{" "}
                  <span className="order--id">{orderData["Order ID"]}
                
                  </span>
                </p>
              </span>
              
            </div>
            
            <div className="col-md-3"></div>
            <div className="col-md-2">
              <button className="process-btn">Hold/Activate</button>
            </div>
            <div className="col-md-2">
              <button className="process-btn">Initiate Replacment</button>
            </div>
            <div className="col-md-1">
              <button className="process-btn">Refund</button>
            </div>
            <div className="col-md-1">
              <button className="process-btn">Cancel</button>
            </div>
          </div>

          <div className="card-body order--details">
            <ul className="ul--details">
              {Object.entries(orderData).map(([key, value]) => (
                <li key={key}>
                  <span className="order-key">{key}</span>{" "}
                  <span className="ke--value">{value || "--"}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  };

  // Define a function to render the "Line Items" table
  const renderLineItemsTable = () => {
    if (status === "succeeded" && orderDetails && orderDetails["Line Items"]) {
      const lineItems = orderDetails["Line Items"];
      if (lineItems.length > 0) {
        const headers = Object.keys(lineItems[0]);
        const itemStatusArray = Object.keys(lineItems[0]["Item Status"]);
        console.log(itemStatusArray);

        return (
          <div className="card orderdetails--bg" style={{ marginTop: "-25px" }}>
            <div className="card-body" style={{padding:'0px'}}>
              <table className="table table-striped order-details--table" style={{tableLayout:'auto'}}>
                <thead>
                  <tr>
                    <th></th>
                    {headers.map((header, index) => {
                      if (header !== "ID") {
                        return (
                          <>
                            <th key={index}>{header}</th>
                          </>
                        );
                      }
                    })}
                  </tr>
                </thead>

                <tbody>
                  {lineItems.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>
                        <input type="checkbox" className="check-line" />
                      </td>

                      {headers.map((header, headerIndex) => {
                        if (header !== "ID" && header !== "Item Status") {
                          return (
                            <td key={headerIndex}>{item[header] || "--"}</td>
                          );
                        }
                        return null;
                      })}

                      <td>
                        <select
                          name={`itemStatus-${item["Item Id"]}`}
                          id={`itemStatus-${item["Item Id"]}`}
                          value={selectedStatus || item["Status"]}
                          onChange={(e) => {
                            handleStatusChange(e);

                            setSelectedItemId(item["Item Id"]);
                            setShowPopup(true);
                            console.log(setSelectedStatus(e.target.value));
                            // // Show the popup when an option is selected
                            // setSelectedStatus(e.target.value);
                            // setSelectedItemId(item["Item Id"]);
                            // // Show the popup when an option is selected
                            // setShowPopup(true);
                          }}
                        >
                          {item["Item Status"].map((status, index) => (
                            <option key={index} value={status}>
                              {status}
                            </option>
                          ))}
                        </select>
                      </td>

                      {/* {showPopup && (
<div className="popup">
<p>Are you sure you want to update the item status?</p>
<button onClick={()=>{handleOkClick();}} >OK</button>
<button onClick={() => setShowPopup(false)}>Cancel</button>
</div>
)}  */}

                      {showPopup && (
                        <BootstrapDialog
                          onClose={handleCloseModal}
                          open={openModal}
                          aria-labelledby="customized-dialog-title"
                        >
                          <DialogTitle
                            sx={{ m: 0, p: 2 }}
                            id="customized-dialog-title"
                          >
                            <h5>Order Status </h5>
                          </DialogTitle>
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette[500],
                            }}
                          ></IconButton>
                          <DialogContent dividers>
                            <p>
                              Do you want to change order from{" "}
                              <b>{item["Status"]} </b>
                              to <b>{selectedStatus}</b> status ?
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              autoFocus
                              onClick={() => {
                                handleOkClick();
                                handleCloseModal(true);
                                // window.location.reload();
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              autoFocus
                              onClick={() => {
                                handleCloseModal(true);
                              }}
                            >
                              No
                            </Button>
                          </DialogActions>
                        </BootstrapDialog>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    }
  };

  // Define a function to render the "Billing Address" and "Shipping Address" cards
  const renderBillingAndShippingAddress = () => {
    if (status === "succeeded" && orderDetails) {
      const billingAddress = orderDetails["Billing Address"][0];
      const shippingAddress = orderDetails["Shipping Address"][0];
      const pricedetails = orderDetails["Price Details"][0]
      return (
        <>
          <div
            className="row  address--row"
            style={{ position: "relative", top: "23px" }}
          >
            <div className="col-md-4 first-address-card">
              <div className="card">
                <div className="card-header headr">Billing Address</div>
                <div className="card-body">
                  <ul className="ul--list">
                    {Object.entries(billingAddress).map(([key, value]) => (
                      <li
                        key={key}
                        style={{ padding: "0px" }}
                        className="address-list"
                      >
                        <span className="address-key">{key}:</span> {value || "--"}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header headr">Shipping Address</div>
                <div className="card-body">
                  <ul className="ul--list">
                    {Object.entries(shippingAddress).map(([key, value]) => (
                      <li
                        key={key}
                        style={{ padding: "0px" }}
                        className="address-list"
                      >
                        <span className="address-key">{key}:</span> {value || "--"}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header headr">Price Details</div>
                <div className="card-body">
                  <ul className="ul--list">
                    {Object.entries(pricedetails).map(([key, value]) => (
                    
                      <li
                        key={key}
                        style={{ padding: "0px" }}
                        className="address-list"
                      >
                        <span className="address-key">{key}:</span> { value || "--"}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  //   if (status === "succeeded" && orderDetails) {
  //     const orderStatusList = orderDetails["Order Status"];

  //     const handleOrderStatusChange = (selectedStatus) => {
  //       dispatch(itemlevelStatusAsync({ orderId, status: selectedStatus }));
  //     };

  //     return (
  //       <div className="card">
  //         <div className="card-header">Order Status</div>
  //         <div className="card-body">
  //           <ul>
  //             {Object.keys(orderDetails).map((key, index) => {
  //               if (key === "OrderFulfillment") {
  //                 return (
  //                   <li key={index}>
  //                     <span className="status-key">
  //                       {key}: {orderDetails[key] || "--"}
  //                     </span>
  //                   </li>
  //                 );
  //               }
  //               return null;
  //             })}
  //             <li>
  //               <span className="status-key">Order Status:</span>
  //               {orderStatusList ? (
  //                 <select
  //                   onChange={(e) => {
  //                     handleOrderStatusChange(e.target.value);
  //                     orderStatusClick()
  //                    console.log(orderDetails["OrderFulfillment"])
  //                   }}
  //                   value={orderDetails["OrderFulfillment"]}
  //                 >
  //                   {orderStatusList.map((status, index) => (
  //                     <option key={index} value={status}>
  //                       {status}
  //                     </option>
  //                   ))}
  //                 </select>
  //               ) : (
  //                 "No status available"
  //               )}
  //             </li>
  //             {/* {showPopup && (
  //               <div className="popup">
  //                 <p>Are you sure you want to update the Order status?</p>
  //                 <button
  //                   onClick={() => {
  //                     orderStatusClick();
  //                     window.location.reload();
  //                   }}
  //                 >
  //                   OK
  //                 </button>
  //                 <button onClick={orderStatusClose}>Cancel</button>
  //               </div>
  //             )} */}
  //              <BootstrapDialog
  //                         onClose={orderStatusClose}
  //                         open={orderStatuspopup}
  //                         aria-labelledby="customized-dialog-title"
  //                       >
  //                         <DialogTitle
  //                           sx={{ m: 0, p: 2 }}
  //                           id="customized-dialog-title"
  //                         >
  //                           <h5>Order Status </h5>
  //                         </DialogTitle>
  //                         <IconButton
  //                           aria-label="close"
  //                           onClick={handleClose}
  //                           sx={{
  //                             position: "absolute",
  //                             right: 8,
  //                             top: 8,
  //                             color: (theme) => theme.palette[500],
  //                           }}
  //                         ></IconButton>
  //                         <DialogContent dividers>
  //                           <p>
  //                             Do you want to change order from <b>   {status}</b>
  //                             to <b>{selectedStatus}</b> status ?
  //                           </p>
  //                         </DialogContent>
  //                         <DialogActions>
  //                           <Button
  //                             autoFocus
  //                             onClick={() => {
  //                               handleOkClickStatus();
  //                               orderStatusClose()
  //                               window.location.reload();
  //                             }}
  //                           >
  //                             Yes
  //                           </Button>
  //                           <Button
  //                             autoFocus
  //                             onClick={() => {
  //                               orderStatusClose()

  //                             }}
  //                           >
  //                             No
  //                           </Button>
  //                         </DialogActions>
  //                       </BootstrapDialog>
  //           </ul>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null; // You can return null or a loading indicator if data is not available yet.
  //   }
  // };

  const renderOrderStatusCard = () => {
    if (status === "succeeded" && orderDetails) {
      const orderStatusList = orderDetails["Order Status"];

      const handleOrderStatusChange = (selectedStatus) => {
        dispatch(itemlevelStatusAsync({ orderId, status: selectedStatus }));
      };

      const handleStatusChangee = (e) => {
        const selectedStatuss = e.target.value;
        setSelectedStatuss(selectedStatuss);
        handleOrderStatusChange(selectedStatuss);
        orderStatusClick(); // Implement orderStatusClick logic
        console.log(orderDetails["OrderFulfillment"]);
      };

      return (
        <div className="card orderdetails--bg">
          <div className="card-header">Order Status</div>
          <div className="card-body">
            <ul>
              {Object.keys(orderDetails).map((key, index) => {
                if (key === "OrderFulfillment") {
                  return (
                    <li key={index}>
                      <span className="status-key">
                        {key}: {orderDetails[key] || "--"}
                      </span>
                    </li>
                  );
                }
                return null;
              })}
              <li>
                <span className="status-key">Order Status:</span>
                {orderStatusList ? (
                  <select
                    onChange={handleStatusChangee}
                    value={
                      orderDetails["OrderFulfillment"] ||
                      setSelectedStatuss(selectedStatuss)
                    }
                  >
                    {orderStatusList.map((status, index) => (
                      <option key={index} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                ) : (
                  "No status available"
                )}
              </li>
            </ul>
          </div>
          {/* BootstrapDialog component */}
          <BootstrapDialog
            onClose={orderStatusClose}
            open={orderStatuspopup}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <h5>Order Status</h5>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette[500],
              }}
            ></IconButton>
            <DialogContent dividers>
              <p>
                Do you want to change the order from{" "}
                <b>{orderDetails["OrderFulfillment"]}</b> to{" "}
                <b>{selectedStatuss}</b> status?
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  handleOkClickStatus();
                  orderStatusClose();
                }}
              >
                Yes
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  orderStatusClose();
                }}
              >
                No
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      );
    } else {
      return null; // You can return null or a loading indicator if data is not available yet.
    }
  };
  // const orderIdForNotes=orderDetails.order_data[0]["Order ID"];
  const orderIdForNotes = orderDetails && orderDetails.order_data && orderDetails.order_data[0] && orderDetails.order_data[0]["Order ID"];


  console.log(orderIdForNotes)
  const notesList = orderDetails && orderDetails.others && orderDetails.others[0] && orderDetails.others[0]["Notes"];





  return (
    <div className="order-details row">
      {/* <div className="orderdetails--bg"> */}
      <div className="col-md-12">
        {renderOrderDataCard()}
        {renderLineItemsTable()}

        {renderBillingAndShippingAddress()}

        {/* {renderOrderStatusCard()} */}
        {/* </div> */}
        <div className="row audit--notes--row">
          <div className="col-md-4">
            <div className="card">
              <div className="auditlog-card card-body">
                <img src={Auditlogs} alt="" className="auditlogs" />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <Notes  orderIdForNotes={orderIdForNotes} notesList={notesList} notesRefresh={orderDetailAsync}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
