import React from 'react'
import inputImage from '../../../images/inputsearch.png'
import { FaSearch } from 'react-icons/fa'
import "./Inputsearch.css"
const Inputsearch = ({ value, label, name, placeholder, type, onChange , className, onClick}) => {
  return (
    <div>
       <div className="search-container">
            <input
              type="text"
            
              value={value}
              name={name}
              className="form-control fulfillment-search-input input--search shadow-none"
              placeholder={placeholder}
              onChange={onChange}
            />
            <span className="fulfillment-search--icon">
              <FaSearch onClick={onClick}/>
            </span>
          </div>
      
    </div>
  )
}

export default Inputsearch
