import React, { useEffect } from "react";
import "./Fulfillment.css";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { warehouselistAsync } from "../../app/store/warehoustlistSlice";
import { FaEye } from "react-icons/fa";
import Inputsearch from "../../components/UIComponents/Inputsearch/Inputsearch";
import  Button  from "../../components/UIComponents/Button/Button";
import detailImage from "../../images/File Preview.png"
const Fulfillment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(warehouselistAsync());
  }, [dispatch]);

  const { columnNames, rows } = useSelector((state) => state.warehouselist);

  const renderCell = (cell) => {
    if (typeof cell === "object" && cell !== null) {
      return Object.keys(cell).map((key) => (
        <div key={key}>
          {key}: {cell[key]}
        </div>
      ));
    }
    return cell;
  };

  const handleRowClick = (id) => {
    navigate("/warehouse-details");
    localStorage.setItem("id", id);
    console.log("Clicked row id:", id);
  };

  return (
    <div className="fulfillment-container">
     
      <div className="row">
        <div className="col-md-4">
        <Inputsearch placeholder="Search by Warehouse ID, Name.."/>
         
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-3"></div>
        <div className="col-md-2">
          <Link
            className="btn btn-secondary btn-block fulfillment--btn "
            to="/newfulfillmentcenter"
          >
            Add Fulfillment
          </Link>
        </div>
        <div className="col-md-1">
        <Button className="fulfillment--bulkupload">
       Bulk Upload
      </Button>
        </div>
      </div>
      <div className="table-container">
  <div className="table-wrapper ">
    <table className="borderless-table">
      <thead>
        <tr>
          {columnNames.map((columnName, index) => (
            <th key={index} style={{ width:index === 0 ? '25%' : 'auto' }}>{columnName}</th>
          ))}
          <th className="action--td">Action</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="warehouselisting--td" >
                {renderCell(cell)}
              </td>
            ))}
            <td className="warehouselisting--td">
             
              <img src={detailImage} alt="" onClick={() => handleRowClick(row[0])}/>
            </td>
          </tr>
        ))}
       
      </tbody>
    </table>
  </div>
</div>
    </div>
  );
};

export default Fulfillment;
