import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { warehouseDetails } from "../../services/apiService";
 
// Async thunk to fetch warehouse details
export const warehouseDetailsAsync = createAsyncThunk(
  'warehousedetails/warehouseDetails',
  async (bodyRequest) => {
    try {
      const response = await warehouseDetails(bodyRequest);
      return response.data; // Assuming that the warehouse object is directly under the 'warehouse' key in the response
    } catch (error) {
      console.log("Error in fetching Warehouse Details", error);
      throw error;
    }
  }
);

// Initial state for warehouse details slice
const initialState = {
  warehousedetailsdata: {
    warehouse: [],
    pincodes:[]
  },
  loading: false,
  error: null
};

// Warehouse details slice
const warehousedetailsSlice = createSlice({
  name: 'warehousedetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(warehouseDetailsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(warehouseDetailsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.warehousedetailsdata.warehouse = action.payload.warehouse;
        state.warehousedetailsdata.pincodes = action.payload.service.pincodes;
       
      })
      .addCase(warehouseDetailsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default warehousedetailsSlice.reducer;
