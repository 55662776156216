import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import RicartLogo from "../../images/ricart.png"
import NavbarLogo from '../../images/navlogo.png'
import {FaSignOutAlt} from "react-icons/fa";
import arrowImage from "../../images/Vector.png"

import "bootstrap/dist/css/bootstrap.min.css";
import './Header.css'
import Sidebar from "../Sidebar/Sidebar";

const Header = ({ isCollapsed}) => {
  const[revert,setRevert] = useState(false)
 
  const navigate = useNavigate();
  const handleLogoutClick = () => {
    navigate("/");
  };

  return (
    <Navbar  variant="dark" className="fixed-top">
      <Navbar.Brand href="#home">
        <img src={RicartLogo} alt=""  className="brand--logo"/>
        <img src={NavbarLogo} alt="" className="brand--image" />
      </Navbar.Brand>
      {/* <button onClick={handleToggleSidebar} className="toggle-btn">
        <FaBars />
      </button> */}
      <p className="header--title">ORDER MANAGEMENT SYSTEM</p>
      <Nav className="mr-right">
       
        <Nav.Link href="" onClick={handleLogoutClick} className="logout--p">Logout <FaSignOutAlt /></Nav.Link>
        
        {/* <Nav.Link href="#signup">Sign Up</Nav.Link> */}
       

       
      </Nav>
     {/* {isCollapsed &&(<div className="toggle-header-menu-inverted ">
      <img src={arrowImage} style={{rotate:'180deg'}} onClick={clickFun}/>
     </div>)} */}
     {/* {
      isReverted && (
        <Sidebar />
      )
     } */}
    </Navbar>
  );
};
export default Header;
