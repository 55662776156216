import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {itemlevelstatusFunc} from '../../services/apiService';

export const itemlevelStatusAsync = createAsyncThunk('item/itemStatusupdate', async ({ orderId, status, itemId }) => {
    try {
      const response = await itemlevelstatusFunc(orderId, status, itemId); // Pass the orderId, status, and itemId to the service function
      console.log(response);
      return response;
    } catch (error) {
      console.error('Error in updating line item status:', error);
      throw error;
    }
  });
  
const itemlevelstatusupdateSlice = createSlice({
    name:'itemstatusupdate',
    initialState: {
        itemstatusupdate: null,
        status: 'idle',
        error: null
    },
    reducers :{},
    extraReducers : (builder)=>{
        builder 
        .addCase(itemlevelStatusAsync.pending, (state) => {
            state.status = 'loading';
          })
        .addCase(itemlevelStatusAsync.fulfilled,(state,action)=>{
            state.status="succeeded";
            state.itemstatusupdate = action.payload;

        })
        .addCase(itemlevelStatusAsync.rejected,(state,action)=>{
            state.status="failed";
            state.error = action.error.message;

        })
    }
})

export default  itemlevelstatusupdateSlice.reducer