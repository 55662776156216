import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { removePincodefromBulkApi} from "../../services/apiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const deletepincodeFormApiAsync = createAsyncThunk(
  'deletePincode/DeletePincodeform',
  async (payload) => {
    try {
      const response = await removePincodefromBulkApi(payload);
      console.log(response);
      toast.success(` ${response.message}`);
      return response;
    } catch (error) {
      // You can handle errors here or let them propagate
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  deletepincode: null,
 
  error: null
};

const deletepincodeFormSlice = createSlice({
  name: 'deletepincode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deletepincodeFormApiAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletepincodeFormApiAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.deletepincode = action.payload;
       
        state.error = null;
      })
      .addCase(deletepincodeFormApiAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default deletepincodeFormSlice.reducer;
